import Http from "./Http";

class FilesService extends Http {
    static $displayName = "FilesService";

    async uploadImage(file) {
        const formData = this.convertToFormData(file);
        return await this.post("/upload/image", formData);
    }

    convertToFormData (file) {
        const formData = new FormData();
        formData.append("file", file);

        return formData;
    }

}

export default FilesService;