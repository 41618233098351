import {useCallback, useState, useEffect} from 'react';
import { useLocationQuery, stringifyParams } from './useQueryString';
import {useHistory, useLocation} from 'react-router-dom';
import { useLoading } from './useLoading';
import {addQueryParams} from "../helpers/addQueryParams";

/**
 * 
 * @param {string} searchQueryParam
 * @param {string} sortTypeQueryParam 
 * @param {string} sortByQueryParam 
 * @param {Function} getNewDataService 
 * @param {number} limit 
 * @param {number} offset
 *
 * @param {Object} sortTransformerMap 
 * @param {Object} sortTypeTransdormMap 
 * 
 * @returns {[boolean, {totalCount: number, nextOffset: number}, data: Array, handleTableChange: Function ]}
 */

export const useTable = ({
    searchQueryParam = "query",
    sortTypeQueryParam = "sortType",
    sortByQueryParam = "sortBy",
    getNewDataService = () => { },
    limit = 10,
    offset = 0,
    sortTransformerMap = null, //for [.net] backend
    sortTypeTransdormMap = null, //for custom sort types asc -> desc -> default -> etc.
    defaultSort = null,
    setData,
}) => {
    const history = useHistory();
    const {params} = useLocationQuery();
    const location = useLocation();
    const [loading, { disable, enable }] = useLoading();
    const [pagination, updatePagination] = useState({ totalCount: 0, nextOffset: limit })
    const [data, updateData] = useState([]);

    const handleTableChange = useCallback(
        (type, { page, searchText, sortField, sortOrder }) => {
            const search = searchText?.toString().trimLeft().trimRight() || undefined
            const isChangeSort = sortField && sortOrder && (params[sortByQueryParam] !== sortField || params[sortTypeQueryParam] !== sortOrder)
            const isChangeSearch = `${params[searchQueryParam]}` !== `${search}`

            const offset = (isChangeSearch || isChangeSort)
                ? 0
                : (limit * (page - 1)) || 0

            const newParams = {
                ...params,
                offset: offset,
                [sortByQueryParam]: sortField || params[sortByQueryParam],
                [sortTypeQueryParam]: sortOrder || params[sortTypeQueryParam],
                [searchQueryParam]: isChangeSearch ? search : params[searchQueryParam],
            }

            if (sortTransformerMap) {
                newParams[sortByQueryParam] = sortTransformerMap[sortField];
            }

            if (sortTypeTransdormMap) {
                newParams[sortTypeQueryParam] = sortTypeTransdormMap[sortOrder];
            }

            history.push({
                search: stringifyParams(newParams),
            })
        }, [params]);

    const downloadNewData = useCallback(
        async () => {
            if (defaultSort && !location.search && !!Object.keys(defaultSort).length) {
                history.push(addQueryParams(location.pathname, defaultSort))
                return
            }
            try {
                enable();
                await new Promise((resolve) => setTimeout(resolve, 300));
                const { data, pagination } = await getNewDataService({
                    ...params,
                    limit,
                    offset
                });
                setData && setData(data);
                updateData(data);
                updatePagination(pagination)
            } finally {
                disable(false);
            }
        },
        [params],
    )

    useEffect(async () => {
        await downloadNewData();
    }, [downloadNewData]);

    return [loading, pagination, data, handleTableChange, downloadNewData];
}