import yup from "../../../../validation/yup";
import {USER_EMAIL, USER_NAME} from "../../../../validation/user";
import {PERMISSIONS} from "../../../../base/constants/admins";

const DEFAULT_PERMISSIONS = Object.values(PERMISSIONS).map(item => item.value.toString());

export const validationSchema = yup.object().shape({
    email: USER_EMAIL.required(),
    firstName: USER_NAME.required(),
    lastName: USER_NAME.required(),
    permissions: yup.array()
});

export const initialValues = {
    email: "",
    firstName: "",
    lastName: "",
    permissions: DEFAULT_PERMISSIONS,
};
