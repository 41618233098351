import React, {useEffect, useState} from "react";
import Table from "../../../../base/components/Table";
import UsersService from "../../../../services/UsersService";
import { useService } from "../../../../base/hooks/useService";
import { useLocationQuery } from "../../../../base/hooks/useQueryString";
import { useTable } from '../../../../base/hooks/useTable';
import tableStructure from "./tableStructure";
import UsersTableHeader from './TableHeader';
import { Container } from "reactstrap";
import {SEARCH_QUERY, SORT_KEY, SORT_TYPE} from "../../../../base/constants/query";
import PageMeta from "../../../../base/components/MetaTags";
import webSocketService from "../../../../services/WebSocketService";
import {SOCKET_USERS} from "../../constants/socket";

const USERS_LIMIT = 15;

export const UsersList = ({defaultParams}) => {
  /**
   * @type {UsersService}
   */
  const usersService = useService(UsersService);
  const [update, setUpdate] = useState(false);
  const { params } = useLocationQuery();

  const [loading, pagination, data, handleTableChange, downloadNewData] = useTable({
    searchQueryParam: SEARCH_QUERY,
    sortTypeQueryParam: SORT_TYPE,
    sortByQueryParam: SORT_KEY,
    getNewDataService: usersService.getUsers.bind(usersService),
    limit: USERS_LIMIT,
    defaultSort: defaultParams,
    offset: params.offset || 0
  })

  const textPlaceholder = !params[SEARCH_QUERY] ? 'There are no users' : 'No search results found';

    const updateData = async () => {
        setUpdate(true)
        await downloadNewData()
        setUpdate(false)
    }

    webSocketService.onMessage({
        [SOCKET_USERS.USER_EDIT_PROFILE]: updateData,
        [SOCKET_USERS.CHANGE_USER_STATUS]: updateData
    })

  return (
    <section className="page-content">
      <PageMeta title={'Users'} />
      <div className="content-header">
        <h2 className="content-header__title">Users</h2>
      </div>
      <Container fluid className="p-0" >
        <Table
          data={data}
          dataStructure={tableStructure}
          loading={!update && loading}
          HeaderComponent={UsersTableHeader}
          limit={USERS_LIMIT}
          offset={params.offset || 0}
          totalCount={pagination.totalCount}
          handleTableChange={handleTableChange}
          searchField={SEARCH_QUERY}
          textPlaceholder={textPlaceholder}
        />
      </Container>
    </section>
  );
};
