import {USERS_GROUP_LINKS} from "../../config";
import JoinedTeams from "./components/JoinedTeams";
import JoinedLeagues from "./components/JoinedLeagues";
import React from "react";

export const breadcrumbSchema = [
    {
        title: 'Users',
        path: USERS_GROUP_LINKS.BASE
    },
    {
        title: 'User info',
    }
]

export const ACTION_USER_TYPE = {
    DELETE_USER: 'deleteUser',
    BLOCK_USER: 'blockUser',
    UNBLOCK_USER: 'unblockUser',
    DELETE_OWNER: 'deleteOwner'
};

export const popupSchema = {
    [ACTION_USER_TYPE.DELETE_USER]: {
        isDanger: true,
        title: 'Delete user',
        body: 'Are you sure you want to delete this user?',
        labelCancel: 'No, Cancel',
        labelDone: 'Yes, Delete',
    },
    [ACTION_USER_TYPE.BLOCK_USER]: {
        isDanger: false,
        title: 'Block user',
        body: 'Are you sure you want to block this user?',
        labelCancel: 'No, Cancel',
        labelDone: 'Yes, Block',
    },
    [ACTION_USER_TYPE.UNBLOCK_USER]: {
        isDanger: false,
        title: 'Unblock user',
        body: 'Are you sure you want to unblock this user?',
        labelCancel: 'No, Cancel',
        labelDone: 'Yes, Unblock',
    },
    [ACTION_USER_TYPE.DELETE_OWNER]: {
        isDanger: false,
        title: 'Delete owner',
        body: 'You are trying to delete a Team Owner. Please, assign a new one.',
        labelCancel: 'No, Cancel',
        labelDone: 'Yes, Assign',
    },
}

const getTeams = (id, status) => ({
    link: '/teams',
    label: 'List of joined teams',
    render: () => <JoinedTeams id={id} status={status} />
})

const getLeagues = (id, status) => ({
    link: '/leagues',
    label: 'List of joined leagues',
    render: () => <JoinedLeagues id={id} status={status} />
})
export const listSchema = (id, status) => {
    return [getTeams(id, status), getLeagues(id, status)]
}