
const LINK_AUTH = "/auth";


export const AUTH_GROUP_LINKS = {
	BASE: LINK_AUTH,
	LINK_LOGIN: `${LINK_AUTH}/login`,
	LINK_LOG_OUT: `${LINK_AUTH}/logout`,
	LINK_FORGOT_PASSWORD: `${LINK_AUTH}/forgot-password`,
	LINK_RESET_PASSWORD: `${LINK_AUTH}/reset-password`,
	CREATE_PASSWORD: `${LINK_AUTH}/create-password`,
	RESULT_SEND: `${LINK_AUTH}/status`,
	EXTERNAL: {
		TERMS_OF_USE: `/privacy`,
		AFTER_LOGIN: "/app"
	}
};