import React, {useEffect, useState} from "react";
import Table from "../../../../../../base/components/Table";
import {useService} from "../../../../../../base/hooks/useService";
import {useLocationQuery} from "../../../../../../base/hooks/useQueryString";
import {useTable} from '../../../../../../base/hooks/useTable';
import tableStructure from "./tableStructure";
import UsersTableHeader from './TableHeader';
import {Container} from "reactstrap";
import {SEARCH_QUERY, SORT_KEY, SORT_TYPE} from "../../../../../../base/constants/query";
import TeamsService from "../../../../../../services/TeamsService";
import webSocketService from "../../../../../../services/WebSocketService";
import {SOCKET_EVENTS} from "../../../../../events/constants/socket";
import {SOCKET_TEAMS} from "../../../../constants/socket";

const TEAMS_LIMIT = 15;

export const AcceptedTeamsList = ({acceptedListParams}) => {
    /**
     * @type {TeamsService}
     */
    const teamsService = useService(TeamsService);
    const [update, setUpdate] = useState(false);
    const {params} = useLocationQuery();

    const [loading, pagination, data, handleTableChange, downloadNewData] = useTable({
        searchQueryParam: SEARCH_QUERY,
        sortTypeQueryParam: SORT_TYPE,
        sortByQueryParam: SORT_KEY,
        getNewDataService: teamsService.getAcceptedTeams.bind(teamsService),
        limit: TEAMS_LIMIT,
        defaultSort: acceptedListParams,
        offset: params.offset || 0
    });

    const textPlaceholder = !params[SEARCH_QUERY] ? 'There are no accepted teams' : 'No search results found';

    const updateData = async () => {
        setUpdate(true)
        await downloadNewData()
        setUpdate(false)
    }

    webSocketService.onMessage({
        [SOCKET_TEAMS.TEAM_ACCEPTED]: updateData,
        [SOCKET_TEAMS.TEAM_OWNER_CHANGED]: updateData,
        [SOCKET_TEAMS.OWNER_DELETE_TEAM]: updateData,
    })

    return (
        <Container fluid className="p-0">
            <Table
                data={data}
                dataStructure={tableStructure}
                loading={!update && loading}
                HeaderComponent={UsersTableHeader}
                limit={TEAMS_LIMIT}
                offset={params.offset || 0}
                totalCount={pagination.totalCount}
                handleTableChange={handleTableChange}
                searchField={SEARCH_QUERY}
                textPlaceholder={textPlaceholder}
            />
        </Container>
    );
};
