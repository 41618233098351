import React, {useEffect} from "react";
import {
    Carousel as ReactCarousel,
    CarouselIndicators,
    CarouselItem,
    CarouselControl
} from "reactstrap"

const Carousel = ({items, containerClassName, itemClassName, defaultIndex}) => {
    // State for Active index
    const [activeIndex, setActiveIndex] = React.useState(0);

    // State for Animation
    const [animating, setAnimating] = React.useState(false);

    // Items array length
    const itemLength = items.length - 1

    // Previous button for Carousel
    const previousButton = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0
            ? itemLength
            : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    // Next button for Carousel
    const nextButton = () => {
        if (animating) return;
        const nextIndex = activeIndex === itemLength ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    }

    useEffect(() => {
        setActiveIndex(defaultIndex)
    }, [defaultIndex])

    // Carousel Item Data
    const carouselItemData = items.map((item) => {
        return (
            <CarouselItem
                key={item.id}
                className={itemClassName}
                onExited={() => setAnimating(false)}
                onExiting={() => setAnimating(true)}
            >
                <img src={item.originalPath}/>
            </CarouselItem>
        );
    });

    return (
        <ReactCarousel
            previous={previousButton}
            next={nextButton}
            activeIndex={activeIndex}
            className={containerClassName}
            interval={false}
            slide={false}

        >
            <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex}/>
            {carouselItemData}
            <CarouselControl
                directionText="Prev"
                direction="prev"
                onClickHandler={previousButton}
            />
            <CarouselControl
                directionText="Next"
                direction="next"
                onClickHandler={nextButton}
            />
        </ReactCarousel>
    );
};

Carousel.defaultProps = {
    className: "",
};

export default Carousel;
