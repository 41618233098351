import {EVENTS_GROUP_LINKS} from "../../config";

export const breadcrumbSchema = [
    {
        title: 'Events',
        path: EVENTS_GROUP_LINKS.BASE
    },
    {
        title: 'Create new event',
    }
]
