import React, {useState} from "react";
import Table from "../../../../../../base/components/Table";
import { useService } from "../../../../../../base/hooks/useService";
import { useLocationQuery } from "../../../../../../base/hooks/useQueryString";
import { useTable } from '../../../../../../base/hooks/useTable';
import tableStructure from "./tableStructure";
import TableHeader from './TableHeader';
import { Container } from "reactstrap";
import {SEARCH_QUERY, SORT_KEY, SORT_TYPE} from "../../../../../../base/constants/query";
import ReportsService from "../../../../../../services/ReportsService";
import webSocketService from "../../../../../../services/WebSocketService";
import {SOCKET_REPORTS} from "../../../../constants/socket";

const EVENTS_LIMIT = 15;

export const ReportedUsersList = ({userReportsParams}) => {
  /**
   * @type {ReportsService}
   */
  const reportsService = useService(ReportsService);
  const [update, setUpdate] = useState(false);
  const { params } = useLocationQuery();

  const [loading, pagination, data, handleTableChange, downloadNewData] = useTable({
    searchQueryParam: SEARCH_QUERY,
    sortTypeQueryParam: SORT_TYPE,
    sortByQueryParam: SORT_KEY,
    getNewDataService: reportsService.getUsersReports.bind(reportsService),
    limit: EVENTS_LIMIT,
    defaultSort: userReportsParams,
    offset: params.offset || 0
  })

  const textPlaceholder = !params[SEARCH_QUERY] ? 'There are no reports' : 'No search results found';

  const updateData = async () => {
    setUpdate(true)
    await downloadNewData()
    setUpdate(false)
  }

  webSocketService.onMessage({
    [SOCKET_REPORTS.ADMIN_DELETE_USER]: updateData,
    [SOCKET_REPORTS.NEW_USER_REPORT]: updateData,
    [SOCKET_REPORTS.ADMIN_VIEWED_REPORTS]: updateData,
  })

  return (
      <Container fluid className="p-0">
        <Table
          data={data}
          dataStructure={tableStructure}
          loading={!update && loading}
          HeaderComponent={TableHeader}
          limit={EVENTS_LIMIT}
          offset={params.offset || 0}
          totalCount={pagination.totalCount}
          handleTableChange={handleTableChange}
          searchField={SEARCH_QUERY}
          textPlaceholder={textPlaceholder}
          locationIndex={'userId'}
        />
      </Container>
  );
};
