import { REPORTS_GROUP_LINKS } from "../../config";

//pop-up config

export const ACTION_TYPE = {
    DELETE: 'delete',
    BLOCK: 'block',
    DELETE_OWNER: 'delete_owner'
};

export const popupSchema = {
    [ACTION_TYPE.DELETE]: {
        isDanger: true,
        title: 'Delete user',
        body: 'Are you sure you want to delete this user?',
        labelCancel: 'No, Cancel',
        labelDone: 'Yes, Delete',
    },
    [ACTION_TYPE.BLOCK]: {
        isDanger: true,
        title: 'Block user',
        body: 'Are you sure you want to block this user?',
        labelCancel: 'No, Cancel',
        labelDone: 'Yes, block',
    },
    [ACTION_TYPE.DELETE_OWNER]: {
        isDanger: true,
        title: 'Delete owner',
        body: 'You are trying to delete a Team Owner. Please, assign a new one.',
        labelCancel: 'No, Cancel',
        labelDone: 'Yes, assign',
        isOpen: true,
    },
}

//  breadcrumb config

export const breadcrumbSchema = [
    {
        title: 'Reports',
        path: REPORTS_GROUP_LINKS.BASE
    },
    {
        title: 'Reports on a user ',
    }
]
