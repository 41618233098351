import React, {useEffect, useState} from 'react';
import { useField } from 'formik';
import CustomInput from './Input';
import PropTypes from 'prop-types'

const FormikImageInput = ({
    placeholder,
    name,
    type,
    maxLength,
    readOnly,
    label,
    containerClassName,
    imageUrl,
    ...props
}) => {
    const [field, meta, helper] = useField({ name, type, placeholder });
    const isError = meta.error && meta.touched;

    const [fileName, setFileName] = useState('');
    const [currentImgUrl, setImgUrl] = useState(null);

    const handleChange = (event) => {
        setFileName(event.target.value)
        helper.setValue(event.target.files[0])

        const hasImg = Boolean(event.target.files.length);
        const [file] = event.target.files ?? [];

        if (!file) {
            return;
        }

        setImgUrl(hasImg ? URL.createObjectURL(file) : imageUrl);
    };

    field.value = fileName;

    useEffect(() => {
        setImgUrl(imageUrl);
    }, [imageUrl]);

    return (
        <section className={containerClassName}>
            <label className={isError ? "text-danger" : ""}>{label}</label>
            <div className="image-input">
                <CustomInput
                    {...field}
                    type='file'
                    className={`image-input__input ${isError ? "is-invalid" : ""}`}
                    readOnly={readOnly}
                    placeholder={placeholder}
                    onChange={handleChange}
                    {...props}
                />
                {currentImgUrl && <img className="image-input__image" src={currentImgUrl}/>}
            </div>
            {isError && <span className="invalid-feedback d-block">{meta.error}</span>}
        </section>
    )
};

FormikImageInput.propTypes = {
    placeholder: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    maxLength: PropTypes.number,
    readOnly: PropTypes.bool,
    label: PropTypes.string,
    containerClassName: PropTypes.string,
}

export default FormikImageInput;