import React from "react";
import PropTypes from 'prop-types';

export default function Checkbox({ label, value, onChange, id, name, checked }) {
    return (
        <div className="form-check">
            <input
                type="checkbox"
                className="form-check-input"
                id={id}
                onChange={onChange}
                checked={checked}
                name={name}
                value={value}
            />
            <label className="form-check-label" htmlFor={id}>
                {label}
            </label>
        </div>
    );
}

Checkbox.propTypes = {
    text: PropTypes.string,
    value: PropTypes.bool,
    onChange: PropTypes.func,
    id: PropTypes.string,
}