import yup from './yup';

import {EMAIL_PATTERN, PASSWORD_PATTERN} from '../base/constants/patterns';
import {MIN_PASSWORD_LENGTH, MAX_PASSWORD_LENGTH, MIN_NAME_LENGTH, MAX_NAME_LENGTH} from './lengthConstants';

export const USER_EMAIL = yup
    .string()
    .email()
    .matches(EMAIL_PATTERN, 'Email is invalid');

export const USER_PASSWORD = yup
    .string()
    .min(MIN_PASSWORD_LENGTH, `Password must be from ${MIN_PASSWORD_LENGTH} to ${MAX_PASSWORD_LENGTH} symbols`)
    .max(MAX_PASSWORD_LENGTH, `Password must be from ${MIN_PASSWORD_LENGTH} to ${MAX_PASSWORD_LENGTH} symbols`)
    .matches(PASSWORD_PATTERN, 'Password should contain at least one letter and one digit')
    .trim();

export const USER_NAME = yup
    .string()
    .min(MIN_NAME_LENGTH, `Must be from ${MIN_NAME_LENGTH} to ${MAX_NAME_LENGTH} symbols`)
    .max(MAX_NAME_LENGTH, `Must be from ${MIN_NAME_LENGTH} to ${MAX_NAME_LENGTH} symbols`)
    .trim()

