import React from "react";
import PropTypes from "prop-types";
import {Link, useLocation} from "react-router-dom";

const LinkBack = ({defaultLink, children}) => {
  const location = useLocation();

  const linkBack = location?.state?.from || defaultLink;

  return <Link to={linkBack}>
    <h3 className="content-header__title link-back">{children || 'BACK'}</h3>
  </Link>;
};

LinkBack.propTypes = {
  defaultLink: PropTypes.string,
};

export default LinkBack;
