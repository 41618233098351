import React, {useCallback} from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import {DropdownItem, Label} from "reactstrap";

import {STRINGS} from "../../constants/Strings";

const {loadingStr, inputPlaceholder} = STRINGS;

const LocationInput = ({
    disabled,
    onSelect,
    onChange,
    error,
    onBlur,
    value,
    onFocus
}) => {

    const handleChange = useCallback((location) => {
        onChange(location);
    }, [onChange]);

    return (
        <div className="mb-4 location-input inline-input position-relative">
            <PlacesAutocomplete
                value={value}
                onChange={handleChange}
                onSelect={onSelect}
            >
                {({getInputProps, suggestions, loading, getSuggestionItemProps}) => {
                    const isError = !!error;
                    return (
                        <>
                            <div className="w-100">
                                <input
                                    {...getInputProps({
                                        placeholder: inputPlaceholder,
                                        className: `form-control ${isError ? "is-invalid" : ""}`,
                                    })}
                                    onBlur={onBlur}
                                    disabled={disabled}
                                    onFocus={onFocus}
                                />
                                <div className="location-dropdown">
                                    {loading && <div>{loadingStr}</div>}
                                    {suggestions.map((suggestion, index) => (
                                        <div
                                            className='dropdown-item cursor-pointer'
                                            {...getSuggestionItemProps(suggestion)}
                                            key={index}>
                                            {suggestion.description}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {isError && <span className="invalid-feedback start-0 bottom-0 d-block">{error}</span>}
                        </>
                    )
                }}
            </PlacesAutocomplete>
        </div>
    );
};

export default LocationInput;
