const BASE = '/app/events';
const EVENT_DETAILS = BASE.concat('/:id');

export const EVENT_DETAILS_LINKS = {
	WAITING: '/waiting',
	JOINED: '/joined'
};

export const EVENT_LIST_LINKS = {
	EVENTS: '',
	TEMPLATES: '/templates'
};

export const EVENTS_GROUP_LINKS = {
	BASE,
	EVENT_DETAILS,
	EVENT_DETAILS_JOINED_TEAMS: EVENT_DETAILS.concat(EVENT_DETAILS_LINKS.JOINED),
	EVENT_DETAILS_WAITING_TEAMS: EVENT_DETAILS.concat(EVENT_DETAILS_LINKS.WAITING),
	EVENT_TEMPLATES: BASE.concat(EVENT_LIST_LINKS.TEMPLATES),
	EVENT_TEMPLATE_DETAILS: BASE.concat(EVENT_LIST_LINKS.TEMPLATES, '/:id'),
	LINK_EVENT_CREATE: BASE.concat('/create'),
};


export const timeZone = [
	{
		label: "Hawaii Time"
	},
	{
		label: "Alaska Time"
	},
	{
		label: "Pacific Time"
	},
	{
		label: "Mountain Time"
	},
	{
		label: "Central Time"
	},
	{
		label: "Eastern Time"
	}
]

export const format = [
	{
		label: "Virtual",
	},
	{
		label: "Live"
	}
]

export const platforms = [
	{
		label: "PC"
	},
	{
		label: "XBox"
	},
	{
		label: "PS"
	},
]