import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Card} from "reactstrap";
import Button from "../Button";
import {ModalHeader} from "../../../features/CustomModal";
import {useModal} from "../../hooks/useModal";
import {Formik} from "formik";
import FormikInput from "../FormikInput";
import {validationSchema} from "./validation/NestedNotificationPopup";

const NestedNotificationPopup = ({
                                     title,
                                     nestedTitle,
                                     body,
                                     nestedBody,
                                     labelCancel = 'No, cancel',
                                     nestedLabelCancel = 'No, cancel',
                                     labelDone,
                                     nestedLabelDone,
                                     action,
                                     children,
                                     isOpen = false,
                                     inputLabel,
                                     inputPlaceholder,
                                     maxLength,
                                     params
                                 }) => {
    const {Modal, open, getModalProps, close, toggle} = useModal();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const initialValues = {
        inputText: ''
    }

    const clickComplete = () => {
        setIsModalOpen(true);
    }

    const onModalClose = () => {
        setIsModalOpen(false);
        close();
    }

    const onModalSubmit = (inputText) => {
        action({...params, inputText: inputText.inputText});
        setIsModalOpen(false);
        close();
    }

    useEffect(() => {
        if (isOpen) {
            open()
        } else {
            setIsModalOpen(false);
            close()
        }
    }, [isOpen])

    return <>
        <div onClick={open}>
            {children}
        </div>
        <Modal {...getModalProps} toggle={toggle}>
            <ModalHeader onClose={close}>
                {title}
            </ModalHeader>
            <div className="m-0 p-0">
                <Card className="mb-0">
                    <p className="p-3 mb-0">{body}</p>
                    <menu className="modal-footer p-3 mb-0 mt-0">
                        <Button color='primary' outline onClick={close}>{labelCancel}</Button>
                        <Button color='danger' onClick={clickComplete}>{labelDone}</Button>
                    </menu>
                </Card>
            </div>
            <Modal {...getModalProps} toggle={toggle} isOpen={isModalOpen}>
                <ModalHeader onClose={onModalClose}>
                    {nestedTitle}
                </ModalHeader>
                <div className="m-0 p-0">
                    <Card className="mb-0">
                        <p className="p-3 mb-0">{nestedBody}</p>
                        <div className="px-3">
                            <Formik initialValues={initialValues} onSubmit={(values) => onModalSubmit(values)}
                                    validationSchema={validationSchema}>
                                {({errors, handleSubmit, values}) => (
                                    <form className="form-horizontal" onSubmit={handleSubmit}>
                                        <FormikInput
                                            labelClassname="fw-bold"
                                            id="inputText"
                                            name="inputText"
                                            label={inputLabel}
                                            type="text"
                                            placeholder={inputPlaceholder}
                                            maxLength={maxLength}
                                        />
                                        <menu className="modal-footer mr-2 px-0">
                                            <Button color='primary' outline
                                                    onClick={onModalClose}>{nestedLabelCancel}</Button>
                                            <Button color='primary'
                                                    type='submit'
                                                    disabled={values.inputText.length === 0}
                                            >
                                                {nestedLabelDone}
                                            </Button>
                                        </menu>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </Card>
                </div>
            </Modal>
        </Modal>
    </>
};

NestedNotificationPopup.propTypes = {
    title: PropTypes.string,
    body: PropTypes.string,
    labelCancel: PropTypes.string,
    labelDone: PropTypes.string,
    action: PropTypes.func
};

export default NestedNotificationPopup;
