import React from "react";
import PropTypes from "prop-types";
import {Card, CardBody, Media} from "reactstrap";
import Button from "../../../../base/components/Button";
import InfoItem from "./components/InfoItem";
import NestedNotificationPopup from "../../../../base/components/Popups/NestedNotificationPopup";
import {REASON_FIELD_MAX_LENGTH} from "../../../leagues/pages/LeaguesLists/components/WaitingLeaguesList/config";

const MainInfo = ({description, owner, status, type, name, avatar, menuSchema, deleteTeam, isDeleted}) => {
    return (
        <Card>
            <CardBody className="user-info">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h4 className="card-title mb-4">Team details</h4>
                    <div className="d-flex">
                        {deleteTeam && !isDeleted &&
                            <NestedNotificationPopup
                                action={deleteTeam}
                                close={close}
                                title="Delete"
                                nestedTitle="Delete"
                                body="Are you sure you want to delete this league?"
                                nestedBody="Please provide reason for deleting"
                                labelCancel="No, Cancel"
                                nestedLabelCancel="Close"
                                labelDone="Yes, Delete"
                                nestedLabelDone="Confirm"
                                inputLabel="Reason"
                                inputPlaceholder="Enter reason"
                                isDanger={true}
                                isNotification={true}
                                maxLength={REASON_FIELD_MAX_LENGTH}
                            >
                                <Button
                                    className="edit-user-btn ms-3"
                                    color='danger'
                                >
                                    Delete
                                </Button>
                            </NestedNotificationPopup>
                        }
                        {Object.values(menuSchema).map((item) => {
                                return item.popupSchema
                                    ?
                                    <NestedNotificationPopup
                                        action={item.action}
                                        close={close}
                                        title="Decline"
                                        nestedTitle="Decline"
                                        body="Are you sure you want to decline this request?"
                                        nestedBody="Please provide reason for declining"
                                        labelCancel="No, Cancel"
                                        nestedLabelCancel="Close"
                                        labelDone="Yes, Decline"
                                        nestedLabelDone="Confirm"
                                        inputLabel="Reason"
                                        inputPlaceholder="Enter reason"
                                        isDanger={true}
                                        isNotification={true}
                                        maxLength={REASON_FIELD_MAX_LENGTH}
                                    >
                                        <Button
                                            key={item.label}
                                            className="edit-user-btn ms-3"
                                            outline={item.outline}
                                            color={item.color}>
                                            {item.label}
                                        </Button>
                                    </NestedNotificationPopup>
                                    : <Button
                                        key={item.label}
                                        className="edit-user-btn ms-3"
                                        outline={item.outline}
                                        color={item.color}
                                        onClick={item.action}>
                                        {item.label}
                                    </Button>
                            }
                        )}
                    </div>
                </div>
                <Media>
                    {avatar && <div>
                        <img
                            className="avatar"
                            src={avatar}
                        />
                    </div>}
                    <Media body className="align-self-start">
                        <div className="edit-wrapper">
                            <div className="user-info__info">
                                {status && <InfoItem title="Status" value={status} className={status}/>}
                                <InfoItem title="Team name" value={name}/>
                                <InfoItem title="Team type" value={type.name}/>
                                <InfoItem title="Team owner" value={owner && `${owner.firstName} ${owner.lastName}`}/>
                                <InfoItem title="Description" value={description}/>
                            </div>
                        </div>
                    </Media>
                </Media>
            </CardBody>
        </Card>
    );
};

MainInfo.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.any,
    owner: PropTypes.any,
    avatar: PropTypes.string,
    status: PropTypes.string,
    description: PropTypes.string,
    menuSchema: PropTypes.objectOf(PropTypes.object),
};

MainInfo.defaultProps = {
    menuSchema: {},
};

export default MainInfo;
