import React, {useEffect, useState} from 'react'
import PropTypes from "prop-types";
import {useLocationQuery} from "../../hooks/useQueryString";
import {SEARCH_QUERY} from "../../constants/query";
import {decodeQuery} from "../../helpers/decodeQuery";

const ENTER_KEY_CODE = 13;

export default function SearchBar({onSearch}) {
    const [value, setValue] = useState('');

    const {params: query} = useLocationQuery()

    const searchQuery = decodeQuery(query[SEARCH_QUERY]);

    const handlePressEnter = ({target: {value}, keyCode}) => {
        if (keyCode === ENTER_KEY_CODE) {
            onSearch(value || undefined)
        }
    }

    const handleBlur = ({target: {value}}) => {
        if(searchQuery !== value) {
            onSearch(value || undefined)
        }
    }

    const handleChange = ({target: {value}}) => {
        setValue(value)
    }

    useEffect(() => {
        setValue(searchQuery)
    }, [searchQuery])

    return <input
        className="search-input form-control search-input mb-0"
        placeholder="Search..."
        onKeyDown={handlePressEnter}
        onBlur={handleBlur}
        value={value}
        onChange={handleChange}
    />
}

SearchBar.propTypes = {
    onSearch: PropTypes.func.isRequired,
    searchText: PropTypes.string.isRequired,
}