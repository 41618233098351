import React, {useCallback, useEffect, useState} from "react";
import {useService} from "../../../../base/hooks/useService";
import {useLoading} from "../../../../base/hooks/useLoading";
import Breadcrumb from "../../../../base/components/Breadcrumb";
import {ACTION_WAITING_TEAM, breadcrumbSchema, menuSchema} from "./config";
import {useHistory, useParams} from "react-router-dom";
import {useRequestErrorMessage} from "../../../../base/hooks/useRequestErrorMessage";
import PageMeta from "../../../../base/components/MetaTags";
import TeamsService from "../../../../services/TeamsService";
import {TEAMS_GROUP_LINKS} from "../../config";
import MainInfo from "../../components/MainInfo";
import ToasterService from "../../../../services/ToastService";
import LinkBack from "../../../../base/components/LinkBack";
import webSocketService from "../../../../services/WebSocketService";
import {SOCKET_TEAMS} from "../../constants/socket";
import defaultAvatar from "../../../../assets/images/default-avatar.jpg";

const TeamWaitingInfo = () => {
    /**
     * @type {TeamsService}
     */
    const teamsService = useService(TeamsService);
    /**
     * @type {ToasterService}
     */
    const toasterService = useService(ToasterService);
    const [team, setTeam] = useState(null);
    const [, {registerPromise}] = useLoading();
    const [, {onCatchError}] = useRequestErrorMessage([]);
    const history = useHistory();
    const {id} = useParams();

    const loadTeam = () => {
        registerPromise(teamsService.getWaitingTeamById(id))
            .then(({data}) => {
                setTeam(data)
            })
            .catch(onCatchError);
    };

    const declineTeam = useCallback((data) => {
        const body = {
            reasonText: data.inputText
        }
        registerPromise(teamsService.declineTeam(id, body))
            .then(({data}) => {
                toasterService.success(data.message)
                history.push(TEAMS_GROUP_LINKS.WAITING_TEAMS)
            })
            .catch(onCatchError);
    }, []);

    const acceptTeam = () => {
        registerPromise(teamsService.acceptTeam(id))
            .then(({data}) => {
                toasterService.success(`Team ${data.name} accepted`)
                history.push(TEAMS_GROUP_LINKS.WAITING_TEAMS)
            })
    }

    useEffect(() => {
        loadTeam();
        menuSchema[ACTION_WAITING_TEAM.DECLINE].action = declineTeam
        menuSchema[ACTION_WAITING_TEAM.ACCEPT].action = acceptTeam
    }, [registerPromise, teamsService]);

    const update = (updatedTeam) => {
        if (updatedTeam.id === Number(id)) {
            setTeam(updatedTeam)
        }
    }

    webSocketService.onMessage({
        [SOCKET_TEAMS.OWNER_EDIT_TEAM]: update,
        [SOCKET_TEAMS.OWNER_DELETE_TEAM]: update,
    })

    return (
        <div className="page-content">
            <PageMeta title={'Team details'}/>
            {team && (
                <>
                    <div className="content-header">
                        <LinkBack defaultLink={TEAMS_GROUP_LINKS.WAITING_TEAMS}>Teams</LinkBack>
                        <Breadcrumb schema={breadcrumbSchema}/>
                    </div>

                    <MainInfo
                        name={team?.name}
                        owner={team?.owner}
                        description={team?.description}
                        avatar={team.logo?.originalPath || defaultAvatar}
                        type={team?.type}
                        menuSchema={menuSchema}
                        className="me-0"
                    />
                </>
            )}
        </div>
    );
};

export default TeamWaitingInfo;
