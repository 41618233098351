import React from "react";
import SignOutButton from "./components/SignOutButton";
import {ReactComponent as ConfigIcon} from "../../../assets/images/profile-dropdown/config.svg";
import {SETTINGS_GROUP_LINKS} from "../../../groups/settings/config";

export const profileDropdownSchema = [
    {
        render: <div><ConfigIcon /> <span className="px-1">Settings</span></div>,
        isLink: true,
        toggle: false,
        to: SETTINGS_GROUP_LINKS.BASE
    },
    {
        toggle: false,
        render: <SignOutButton />,
    }
];