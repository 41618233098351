import {TIMEZONE} from "../constants/timezone";

export const getDateByTimezone = (date, timezone) => {
    const newDate = new Date(date);
    const localTime = new Date().getTimezoneOffset() / 60;
    Object.values(TIMEZONE).map((item) => {
        if(item.is === timezone) {
            newDate.setHours(new Date(date).getHours() - item.time - localTime);
        }
    })
    return newDate;
}
