import React, {useCallback, useEffect, useState} from "react";
import { Map as MapReact, Marker } from "google-maps-react";

import { useGoogleMap } from "../../hooks";

import { DEFAULT_LOCATION, GOOGLE_MAP_ZOOM } from "../../constants";
import { STRINGS } from "../../constants/Strings";

const { markerTitle } = STRINGS;

const useLocalGoogleMap = ({ google, setLocation }) => {
  const { setAddressByLocation } = useGoogleMap({ google });

  const onMarkerClick = useCallback(
    (props, marker, event) => {
      const location = { lat: event.latLng.lat(), lng: event.latLng.lng() };
      setAddressByLocation(location, setLocation);
    },
    [setAddressByLocation, setLocation]
  );

  return {
    onMarkerClick,
  };
};

const Map = ({
    coordinates,
    setLocation,
    centerValue,
    setCenterValue,
    google,
    classNameContainer,
    disabled,
}) => {
  const { onMarkerClick } = useLocalGoogleMap({ google, setLocation: (location) => setLocation({...location}) });

  useEffect(() => {
    setCenterValue(coordinates?.lng ? coordinates : DEFAULT_LOCATION);
    //eslint-disable-next-line
  }, [setCenterValue, centerValue]);

  return (
    <div
      id="gmaps-markers"
      className={`gmaps ${classNameContainer}`}
      style={{ position: "relative"}}
    >
      <MapReact
        google={google}
        disableDoubleClickZoom={true}
        zoom={GOOGLE_MAP_ZOOM}
        initialCenter={centerValue}
        center={centerValue}
        onClick={!disabled && onMarkerClick}
        className='w-100 h-100'
      >
          {coordinates?.lng && <Marker
              name={markerTitle}
              position={coordinates}
          />}
      </MapReact>
    </div>
  );
};

export default Map;
