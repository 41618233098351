import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useField } from 'formik';
import { FormGroup, Input } from 'reactstrap';

const FormikSelect = ({ label, containerClassName, options, optionPlaceholder = "Select type",...props }) => {
  const [field, { error, touched }] = useField(props);
  const isError = error && touched

  if(field.value === null) {
      field.value = optionPlaceholder;
  }

    return (
    <FormGroup className={containerClassName}>
      {label && (
        <label htmlFor={props.name}
        className={classNames({'text-danger': (error && touched)})}
      >
        {label}</label>
      )}
      <Input
          className={isError ? "is-invalid" : "form-select"}
          type="select"
          defaultValue={undefined}
          {...field}
          {...props}
      >
        <option disabled selected={true}> {optionPlaceholder} </option>
        {options.map((item) => (
            <option
              key={item.value}
              value={item.value}
            >
              {item.label}
            </option>))
        }

      </Input>
        {isError && <span className="invalid-feedback">{error}</span>}
    </FormGroup>
  )
};

FormikSelect.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  containerClassName: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.element),
}

export default FormikSelect;