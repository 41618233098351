import VerticalLayout from "../../base/components/VerticalLayout";
import {Redirect, Route, Switch, useHistory} from "react-router-dom";
import Users from "../users";
import React, {useCallback, useEffect, useMemo, useState} from "react";

import ErrorBoundary from "../../base/components/ErrorBoundary";
import {USERS_GROUP_LINKS} from "../users/config";

import {ReactComponent as UsersIcon} from "../../assets/images/side-bar/users.svg";
import {ReactComponent as AdminsIcon} from "../../assets/images/side-bar/admins.svg";
import {ReactComponent as TeamsIcon} from "../../assets/images/side-bar/teams.svg";
import {ReactComponent as EventsIcon} from "../../assets/images/side-bar/events.svg";
import {ReactComponent as ReportsIcon} from "../../assets/images/side-bar/reports.svg";
import {ReactComponent as LeaguesIcon} from "../../assets/images/side-bar/leagues.svg";
import {useService} from "../../base/hooks/useService";
import StorageService from "../../services/StorageService";
import {KEY_USER} from "../../base/constants/storage";
import {ADMINS_GROUP_LINKS} from "../admin/config";
import Admins from "../admin";
import {TEAMS_GROUP_LINKS} from "../teams/config";
import TeamsRoutes from "../teams";
import {ADMIN_ROLE} from "../../base/constants/users";
import {getAdminPermissions} from "../../base/helpers/getAdminPermissions";
import {PERMISSIONS} from "../../base/constants/admins";
import {SETTINGS_GROUP_LINKS} from "../settings/config";
import SettingsRoutes from "../settings";
import {getSortKeysSchema} from "./helpers/getSortKeysSchema";
import {EVENTS_GROUP_LINKS} from "../events/config";
import EventsRoutes from "../events";
import {REPORTS_GROUP_LINKS} from "../reports/config";
import Reports from "../reports";
import webSocketService from "../../services/WebSocketService";
import {SOCKET_APP} from "./constants/socket";
import {APP_GROUP_LINKS} from "./config";
import {sendRefreshToken} from "../../services/refreshSession";
import SessionStorage from "../../services/SessionStorage";
import {LEAGUES_GROUP_LINKS} from "../leagues/config";
import Leagues from "../leagues";

const AppRoutes = () => {
    const schema = useMemo(() => ({
            [PERMISSIONS.USER_MANAGEMENT.value]: {
                label: "Users",
                linkTo: USERS_GROUP_LINKS.BASE,
                icon: UsersIcon
            },
            '1.5': {
                label: "Admins",
                linkTo: ADMINS_GROUP_LINKS.BASE,
                icon: AdminsIcon
            },
            [PERMISSIONS.TEAMS_MANAGEMENT.value]: {
                label: "Teams",
                linkTo: TEAMS_GROUP_LINKS.BASE,
                icon: TeamsIcon
            },
            [PERMISSIONS.EVENTS_MANAGEMENT.value]: {
                label: "Events",
                linkTo: EVENTS_GROUP_LINKS.BASE,
                icon: EventsIcon
            },
            [PERMISSIONS.REPORTS_MANAGEMENT.value]: {
                label: "Reports",
                linkTo: REPORTS_GROUP_LINKS.BASE,
                icon: ReportsIcon
            },
            [PERMISSIONS.LEAGUES_MANAGEMENT.value]: {
                label: "Leagues",
                linkTo: LEAGUES_GROUP_LINKS.BASE,
                icon:LeaguesIcon
            }
        })
        , []);
    /**
     * @type {StorageService}
     */
    const storage = useService(StorageService);
    /**
     * @type {SessionStorage}
     */
    const storageSession = useService(SessionStorage);

    const [user, setUser] = useState(storage.get(KEY_USER))
    const { refreshToken } = useMemo(() => storageSession.getSession(), [user])

    const history = useHistory();

    const getSchemaByPermissions = (fullSchema, permissions) => {
        const permissionFlags = getAdminPermissions(permissions).reverse();
        return permissionFlags.map((flag) => fullSchema[flag])
    }

    const getSchemaForSuperAdmin = () => {
        const keys = getSortKeysSchema(schema);
        return keys.map((key => schema[key]));
    }

    const actualSchema = useMemo(() => {
        if(user.role === ADMIN_ROLE.SUPER_ADMIN){
            return  getSchemaForSuperAdmin()
        } else {
            return getSchemaByPermissions(schema, user.adminPermissions)
        }
    }, [user])

    const updateUser = useCallback((updatedUser) => {
        if (updatedUser.id === user.id) {
            sendRefreshToken({refreshToken})
                .then(({data}) => {
                    storageSession.setSession(data.data);
                    setUser(updatedUser)
                    history.push(APP_GROUP_LINKS.BASE)
                })
        }
    }, [refreshToken])

    const defaultSocketHandlers = useMemo(() => (
        {
            [SOCKET_APP.ADMIN_EDIT_PERMISSIONS]: updateUser
        }
    ), [updateUser])

    useEffect(() => {
        webSocketService.onOpen()

        webSocketService.socket.onopen = () => {
            webSocketService.defaultHandlers = defaultSocketHandlers;
        }

        return () => {
            webSocketService.onClose()
        }
    }, [webSocketService])

    useEffect(() => {
        webSocketService.defaultHandlers = defaultSocketHandlers;
    }, [defaultSocketHandlers])

    return <VerticalLayout schema={actualSchema} user={user}>
            <ErrorBoundary>
                <Switch>
                    <Route path={ADMINS_GROUP_LINKS.BASE}>
                        <Admins/>
                    </Route>
                    <Route path={USERS_GROUP_LINKS.BASE} component={Users}/>
                    <Route path={TEAMS_GROUP_LINKS.BASE} component={TeamsRoutes}/>
                    <Route path={SETTINGS_GROUP_LINKS.BASE} component={SettingsRoutes}/>
                    <Route path={EVENTS_GROUP_LINKS.BASE} component={EventsRoutes}/>
                    <Route path={REPORTS_GROUP_LINKS.BASE} component={Reports}/>
                    <Route path={LEAGUES_GROUP_LINKS.BASE} component={Leagues}/>
                    <Redirect to={actualSchema[0].linkTo}/>
                </Switch>
            </ErrorBoundary>
        </VerticalLayout>
};

export default AppRoutes;
