import React from "react";
import {Card, CardBody} from "reactstrap";
import {Formik} from "formik";
import {initialValues, validationSchema} from "./form";
import FormikInput from "../../../../../../base/components/FormikInput";
import Button from "../../../../../../base/components/Button";
import {BUTTON_COLORS, BUTTON_STYLES} from "../../../../../../base/components/Button/appearance";
import {useService} from "../../../../../../base/hooks/useService";
import AdminsService from "../../../../../../services/AdminsService";
import {useLoading} from "../../../../../../base/hooks/useLoading";
import ToasterService from "../../../../../../services/ToastService";
import {useRequestErrorMessage} from "../../../../../../base/hooks/useRequestErrorMessage";

const ChangePasswordForm = () => {
    /**
     * @type {AdminsService}
     */
    const adminService = useService(AdminsService);
    /**
     * @type {ToasterService}
     */
    const toasterService = useService(ToasterService);
    const [isLoading, {registerPromise}] = useLoading();
    const [alertText, {onCatchError}] = useRequestErrorMessage([]);

    const changePassword = (value) => {
        registerPromise(adminService.changePassword(value))
            .then(({data}) => {
                toasterService.success(data.message)
            })
            .catch(onCatchError)
    }

    return (
        <Card className="overflow-hidden w-50 p-4">
            <h4 className="card-title mb-4">Change password</h4>
            <CardBody className="p-0">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        changePassword(values);
                    }}
                >
                    {({ errors, handleSubmit, handleReset }) => {
                        return (
                        <form className="form-horizontal" onSubmit={handleSubmit}>
                            <FormikInput
                                id="oldPassword"
                                name="oldPassword"
                                label="Current password"
                                containerClassName="mb-3"
                                placeholder="Enter current password"
                                type="password"
                            />

                            <FormikInput
                                id="password"
                                label="New password"
                                name="password"
                                containerClassName="mb-3"
                                type="password"
                                placeholder="Enter new password"
                            />

                            <FormikInput
                                id="confirmPassword"
                                label="Repeat password"
                                name="confirmPassword"
                                containerClassName="mb-3"
                                type="password"
                                placeholder="Repeat password"
                            />

                            <div className="d-flex justify-content-start">
                                <Button
                                    color={BUTTON_COLORS.primary}
                                    type="button"
                                    outline
                                    block={BUTTON_STYLES.block}
                                    className="mt-3 me-3 w-auto"
                                    onClick={handleReset}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    color={BUTTON_COLORS.primary}
                                    type="submit"
                                    block={BUTTON_STYLES.block}
                                    className="mt-3 w-auto"
                                    disabled={!!Object.keys(errors).length}
                                >
                                    Save
                                </Button>
                            </div>
                        </form>
                    )}}
                </Formik>
            </CardBody>
        </Card>
    );
}

export default ChangePasswordForm;
