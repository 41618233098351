import React, {useState, useEffect} from "react";
import {GoogleApiWrapper} from "google-maps-react";

import {useGoogleMap} from "./hooks";

const GoogleMap = ({google, children, initialLocation}) => {
    const [location, setLocation] = useState({name: "", value: initialLocation });
    const [prevLocation, setPrevLocation] = useState({
        name: "",
        value: initialLocation,
    });

    const [centerValue, setCenterValue] = useState(initialLocation);
    const {setAddressByLocation} = useGoogleMap({google});

    useEffect(() => {
        setAddressByLocation(initialLocation, setPrevLocation);
        setAddressByLocation(initialLocation, setLocation);
        //eslint-disable-next-line
    }, []);

    return (
        <>
            {children({
                google,
                location,
                setLocation,
                prevLocation,
                setPrevLocation,
                centerValue,
                setCenterValue,
            })}
        </>
    );
};

export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
})(GoogleMap);
