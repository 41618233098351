import React from 'react'
import { parseQuery } from "../../../../base/hooks/useQueryString";
import Icon from '../../../../base/components/Icon';
import {formatDate} from "../../../../base/helpers/formatDate";
import {SORT_KEY, SORT_TYPE} from "../../../../base/constants/query";

const DESC_SORT = 'desc';
const DESC_SORT_ICON = 'sortDesc';
const ASC_SORT_ICON = 'sortAsc';
const SORT_DEFAULT_ICON = 'sortDefault';

export default function AdminsTableStructure(location, decorateText) {
    const infoQuery = parseQuery(location.search);
    function getSortIcon(cellName) {
        if (infoQuery[SORT_KEY] === cellName) {
            return infoQuery[SORT_TYPE] === DESC_SORT ? ASC_SORT_ICON : DESC_SORT_ICON;
        }
        return SORT_DEFAULT_ICON;
    }

    return [
        {
            dataField: "firstName",
            sort: true,
            headerFormatter: () => (<>First name<Icon icon={getSortIcon('firstName')} /></>),
            formatter: (cellContent, user) => {
                return (
                    <section className="d-flex flex-row align-items-center">
                        <span className="tutee-list--list__name" {...decorateText(user.firstName)} />
                    </section>
                )
            }
        },
        {
            dataField: "lastName",
            sort: true,
            headerFormatter: () => (<>Last Name<Icon icon={getSortIcon('lastName')} /></>),
            formatter: (cellContent, user) => {
                return (
                    <section className="d-flex flex-row align-items-center">
                        <span className="tutee-list--list__name"  {...decorateText(user.lastName)} />
                    </section>
                )
            }
        },
        {
            dataField: "email",
            sort: false,
            headerFormatter: () => (<>Email address</>),
            formatter: (cellContent, user) => {
                return <span {...decorateText(user.email)} />
            }
        },
        {
            headerFormatter: () => (<>Date of joining <Icon icon={getSortIcon('registeredAt')} /></>),
            dataField: "registeredAt",
            sort: true,
            formatter: (cellContent, admin) => (
                <>
                    {formatDate(admin.dateOfJoining)}
                </>
            )
        },
        {
            dataField: "status",
            headerFormatter: () => (<>Status</>),
            formatter: (cellContent, user) => {
                return (
                    <section className="d-flex flex-row align-items-center">
                        <span className={`tutee-list--list__name ${user.status}`}>
                            {user.status}
                        </span>
                    </section>
                )
            }
        }
    ]
}