import {REPORTS_GROUP_LINKS} from "../../config";
import React from "react";
import CurrentPost from "./components/CurrentPost/CurrentPost";
import PostHistory from "./components/PostHistory/PostHistory";

//pop-up config

export const ACTION_TYPE = {
    DELETE: 'delete',
};

export const popupSchema = {
    [ACTION_TYPE.DELETE]: {
        isDanger: true,
        title: 'Delete post',
        body: 'Are you sure you want to delete this post?',
        labelCancel: 'No, Cancel',
        labelDone: 'Yes, Delete',
    },
}

//  breadcrumb config

export const breadcrumbSchema = [
    {
        title: 'Reports',
        path: REPORTS_GROUP_LINKS.POST_REPORTS
    },
    {
        title: 'Reports on a post',
    }
]

export const postsSchema = (post) => [
    {
        title: "Current version",
        page: <CurrentPost post={post} />
    },
    {
        title: "Version history",
        page: <PostHistory post={post} />
    }
]

export const reportsHistoryTitle = "Version history";