import React, {useCallback} from "react";
import {Route, Switch} from "react-router-dom";
import {TEAMS_GROUP_LINKS} from "./config";
import TeamsList from "./pages/TeamsList";
import TeamAcceptedInfo from "./pages/TeamAcceptedInfo";
import TeamWaitingInfo from "./pages/TeamWaitingInfo";
import {SORT_KEY, SORT_TYPE} from "../../base/constants/query";
import {useStateParams} from "../../base/hooks/useStateParams";

const DEFAULT_PARAMS = {[SORT_KEY]: 'createdAt', [SORT_TYPE]: 'desc'};

const TeamsRoutes = () => {
    const [acceptedTeamsParams] = useStateParams(TEAMS_GROUP_LINKS.ACCEPTED_TEAMS, DEFAULT_PARAMS);
    const [waitingTeamsParams] = useStateParams(TEAMS_GROUP_LINKS.WAITING_TEAMS);

    const TeamTables = useCallback(() => <TeamsList
        acceptedListParams={acceptedTeamsParams}
        waitingListParams={waitingTeamsParams}
    />, [acceptedTeamsParams, waitingTeamsParams])

    return (
        <Switch>
            <Route
                path={TEAMS_GROUP_LINKS.ACCEPTED_INFO}
                component={TeamAcceptedInfo}
                exact
            />
            <Route
                path={TEAMS_GROUP_LINKS.WAITING_INFO}
                component={TeamWaitingInfo}
                exact
            />
            <Route
                path={TEAMS_GROUP_LINKS.BASE}
                render={TeamTables}
            />
        </Switch>
    );
};

export default TeamsRoutes;
