import React from "react";
import NavTabs from "../../../../base/components/NavTabs/NavTabs";
import {teamsListSchema} from "./config";
import PageMeta from "../../../../base/components/MetaTags";
import {TEAMS_GROUP_LINKS} from "../../config";

const TeamsList = ({acceptedListParams, waitingListParams}) => {
    return (
        <div className="page-content">
            <PageMeta title={'Teams'} />
            <div className="content-header">
                <h2 className="content-header__title">Teams</h2>
            </div>
            <NavTabs
                rootLink={TEAMS_GROUP_LINKS.BASE}
                schema={teamsListSchema({acceptedListParams, waitingListParams})}
            />
        </div>

    );
};

export default TeamsList;