import {ReactComponent as FileIcon} from "../../../../../assets/images/file-icon.svg";
import {getFileNameFromLink} from "./getFileNameFromLink";
import React from "react";

export const generateFilesLink = (files) => {
    if (!files) return [];

    return files.map((doc) => {
        return {
            render: <a
                className="d-block w-100 text-dark"
                key={doc.originalPath}
                href={doc.originalPath} rel="noreferrer" target="_blank" download>
                <FileIcon className="me-1"/>
                {getFileNameFromLink(doc.originalPath)}
            </a>}
    })
}