import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {useLocationQuery} from "./useQueryString";

/**
 *
 * @returns {[unknown, ((value: unknown) => void)]}
 */
export const useStateParams = (path, defaultParams = {}) => {
    const [paramsList, setParamsList] = useState(defaultParams);
    const location = useLocation();
    const {params} = useLocationQuery();

    useEffect(() => {
        if (location.pathname === path && location.search) {
            setParamsList(params)
        }
    }, [location.search]);

    return [paramsList, setParamsList];
}