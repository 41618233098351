import React, {useCallback, useState} from "react";
import Table from "../../../../../../base/components/Table";
import {useService} from "../../../../../../base/hooks/useService";
import {useTable} from '../../../../../../base/hooks/useTable';
import {tableStructure} from "./tableStructure";
import {Container} from "reactstrap";
import {SEARCH_QUERY, SORT_KEY, SORT_TYPE} from "../../../../../../base/constants/query";
import EventsService from "../../../../../../services/EventsService";
import {useParams} from "react-router-dom";
import {TEAMS_GROUP_LINKS} from "../../../../../teams/config";
import {useLocationQuery} from "../../../../../../base/hooks/useQueryString";
import webSocketService from "../../../../../../services/WebSocketService";
import {SOCKET_EVENTS} from "../../../../constants/socket";
import {SOCKET_TEAMS} from "../../../../../teams/constants/socket";

const EVENTS_LIMIT = 10;

export const WaitingTeamsList = ({status}) => {
    /**
     * @type {EventsService}
     */
    const eventsService = useService(EventsService);
    const [update, setUpdate] = useState(false);

    const [requestId] = useState(null);
    const {id} = useParams();
    const { params } = useLocationQuery();

    const loadTeams = useCallback((params) => {
        return eventsService.getWaitingTeam(id, params);
    }, [id]);

    const [loading, pagination, data, handleTableChange, downloadNewData] = useTable({
        searchQueryParam: SEARCH_QUERY,
        sortTypeQueryParam: SORT_TYPE,
        sortByQueryParam: SORT_KEY,
        getNewDataService: loadTeams,
        limit: EVENTS_LIMIT,
        offset: params.offset || 0
    })

    const declineTeam = useCallback((requestId) => {
        eventsService.declineTeam(id, requestId)
            .then(() => {
                downloadNewData()
            })
    }, [id, requestId])

    const acceptedTeam = useCallback((requestId) => {
        eventsService.acceptTeam(id, requestId)
            .then(() => {
                downloadNewData()
            })
    }, [id])

    const textPlaceholder = 'There are no teams';

    const updateData = async () => {
        setUpdate(true)
        await downloadNewData()
        setUpdate(false)
    }

    webSocketService.addHandlers({
        [SOCKET_EVENTS.NEW_EVENT_JOIN_REQUEST]: updateData,
        [SOCKET_EVENTS.CANCEL_EVENT_JOIN_REQUEST]: updateData,
        [SOCKET_EVENTS.JOIN_REQUEST_ACCEPT]: updateData,
        [SOCKET_EVENTS.JOIN_REQUEST_DECLINE]: updateData,
    })

    return (
        <Container fluid className="p-0">
            <Table
                data={data}
                dataStructure={tableStructure(status, declineTeam, acceptedTeam)}
                loading={!update && loading}
                limit={EVENTS_LIMIT}
                offset={params.offset || 0}
                totalCount={pagination.totalCount}
                handleTableChange={handleTableChange}
                searchField={SEARCH_QUERY}
                textPlaceholder={textPlaceholder}
                withLocation={false}
                rowClickPath={TEAMS_GROUP_LINKS.ACCEPTED_TEAMS}
                locationIndex='teamId'
            />
        </Container>
    );
};
