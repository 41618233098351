import React, {useCallback, useEffect, useState} from "react";
import Table from "../../../../base/components/Table";
import {useService} from "../../../../base/hooks/useService";
import {useLocationQuery} from "../../../../base/hooks/useQueryString";
import {useTable} from '../../../../base/hooks/useTable';
import {tableStructure} from "./tableStructure";
import TableHeader from './TableHeader';
import {Container} from "reactstrap";
import {SEARCH_QUERY, SORT_KEY, SORT_TYPE} from "../../../../base/constants/query";
import ReportsService from "../../../../services/ReportsService";
import {useLocation, useParams} from "react-router-dom";
import PageMeta from "../../../../base/components/MetaTags";
import {REPORTS_GROUP_LINKS} from "../../config";
import {breadcrumbSchema} from "./config";
import Breadcrumb from "../../../../base/components/Breadcrumb";
import {getNewReportIds} from "../../helpers/getNewReportIds";
import LinkBack from "../../../../base/components/LinkBack";
import webSocketService from "../../../../services/WebSocketService";
import {SOCKET_REPORTS} from "../../constants/socket";

const REPORTS_LIMIT = 15;

export const PostReportedDetails = () => {
    /**
     * @type {ReportsService}
     */
    const reportsService = useService(ReportsService);
    const [update, setUpdate] = useState(false);
    const {params} = useLocationQuery();
    const {id} = useParams();
    const location = useLocation();

    const getReports = ((id) => {
        return (params) => reportsService.getReportsByPostId(id, params)
    })(id);

    const [loading, pagination, data, handleTableChange, downloadNewData] = useTable({
        searchQueryParam: SEARCH_QUERY,
        sortTypeQueryParam: SORT_TYPE,
        sortByQueryParam: SORT_KEY,
        getNewDataService: getReports,
        limit: REPORTS_LIMIT,
    });

    const textPlaceholder = !params[SEARCH_QUERY] ? 'There are no reports' : 'No search results found';

    useEffect( async () => {
        if(data?.length) {
            const newReportIds = getNewReportIds(data)
            if (newReportIds.length) {
                await reportsService.changeStatusReports(getNewReportIds(data))
            }
        }
    }, [data])

    const tableStructureWithLocation = useCallback(tableStructure(location), [location])

    const updateData = async () => {
        setUpdate(true)
        await downloadNewData()
        setUpdate(false)
    }

    webSocketService.onMessage({
        [SOCKET_REPORTS.NEW_POST_REPORT]: updateData,
        [SOCKET_REPORTS.ADMIN_VIEWED_REPORTS]: updateData,
    })

    return (
        <section className="page-content">
            <PageMeta title={'Reported posts'}/>
            <div className="content-header">
                <LinkBack defaultLink={REPORTS_GROUP_LINKS.POST_REPORTS}>Reports</LinkBack>
                <Breadcrumb schema={breadcrumbSchema}/>
            </div>
            <Container fluid className="p-0">
                <Table
                    data={data}
                    dataStructure={tableStructureWithLocation}
                    loading={!update && loading}
                    HeaderComponent={TableHeader}
                    limit={REPORTS_LIMIT}
                    offset={params.offset || 0}
                    totalCount={pagination.totalCount}
                    handleTableChange={handleTableChange}
                    searchField={SEARCH_QUERY}
                    textPlaceholder={textPlaceholder}
                    isRowClick={false}
                />
            </Container>
        </section>
    );
};
