import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import defaultAvatar from "../../../../assets/images/default-avatar.jpg"
import InfoItem from "../../../teams/components/MainInfo/components/InfoItem";
import Popup from "../../../../base/components/Popups/Popup";
import PopupDeleteOwner from "../../../../base/components/Popups/PopupDeleteOwner";
import Button from "../../../../base/components/Button";
import { ACTION_TYPE, popupSchema } from "./config";
import { useService } from "../../../../base/hooks/useService";
import ReportsService from "../../../../services/ReportsService";
import { useLoading } from "../../../../base/hooks/useLoading";
import { useRequestErrorMessage } from "../../../../base/hooks/useRequestErrorMessage";
import { useParams } from "react-router-dom";
import { USERS_STATUS } from "../../../../base/constants/users";
import { useHistory } from "react-router-dom";
import webSocketService from "../../../../services/WebSocketService";
import { SOCKET_REPORTS } from "../../constants/socket";
import { SPECIAL_ERROR_CODES } from "../../../../base/constants/errorCodes";

export default function TableHeader() {
    const history = useHistory();
    /**
     * @type {ReportsService}
     */

    const reportsService = useService(ReportsService);
    const [user, setUser] = useState(null);
    const [isDeleteOwner, setIsDeleteOwner] = useState(false);
    const { id } = useParams();
    const isBlocked = user?.status === USERS_STATUS.BLOCKED;
    const isDeleted = user?.status === USERS_STATUS.DELETED;

    const [, { registerPromise }] = useLoading();
    const [, { onCatchError }] = useRequestErrorMessage();

    const deleteUser = () => {
        registerPromise(reportsService.deleteReportedUserById(id))
            .then(({ data }) => {
                setUser(data)
            })
            .catch((e) => {
                if (e.code === SPECIAL_ERROR_CODES.ASSIGN_OWNER) {
                    setIsDeleteOwner(true);
                }
                else {
                    onCatchError;
                }
            })
    }

    const deleteOwner = () => {
        history.push(`/app/users/${user?.id}`);
        setIsDeleteOwner(false);
    }

    const changeStatusUser = () => {
        registerPromise(reportsService.changeStatusUserById(id))
            .then(({ data }) => setUser(data))
            .catch(onCatchError)
    }

    const loadUser = () => {
        registerPromise(reportsService.getReportedUserById(id))
            .then(({ data }) => setUser(data))
            .catch(onCatchError)
    }

    useEffect(() => {
        loadUser();
    }, [id]);

    const update = (updatedUser) => {
        if (updatedUser.id === Number(id)) {
            setUser(updatedUser)
        }
    }

    webSocketService.addHandlers({
        [SOCKET_REPORTS.ADMIN_DELETE_USER]: update,
    })

    return <section className="d-flex flex-column mb-3 py-3 justify-content-between w-100">
        <div className="d-flex justify-content-between align-items-center mb-3">
            <h4 className="card-title">Reports on a user</h4>
            <div className="d-flex">
                {!isDeleted
                    ? <>
                        {isBlocked
                            ? <Button
                                className="edit-user-btn ms-3"
                                outline
                                onClick={changeStatusUser}
                                color='primary'>
                                Unblock user
                            </Button>
                            : <Popup
                                {...popupSchema[ACTION_TYPE.BLOCK]}
                                action={changeStatusUser}
                            >
                                <Button
                                    className="edit-user-btn ms-3"
                                    color='primary'>
                                    Block user
                                </Button>
                            </Popup>}
                        <Popup
                            {...popupSchema[ACTION_TYPE.DELETE]}
                            action={deleteUser}
                        >
                            <Button
                                className="edit-user-btn ms-3"
                                color='danger'>
                                Delete user
                            </Button>
                        </Popup>
                    </>
                    : null}
                {isDeleteOwner ? <PopupDeleteOwner
                    {...popupSchema[ACTION_TYPE.DELETE_OWNER]}
                    action={deleteOwner} closeAction={()=>setIsDeleteOwner(false)} /> : null}
            </div>
        </div>
        <div className="d-flex">
            <img
                className="avatar avatar-xl me-3"
                src={user?.avatar?.originalPath || defaultAvatar}
            />
            <div className="user-info__info">
                <InfoItem title="User status" value={user?.status} className={user?.status} />
                <InfoItem
                    title="User name"
                    value={<>{user?.firstName} {user?.lastName}</>}
                />
                <InfoItem title="User email address" value={user?.email} />
            </div>
        </div>
    </section>
}

TableHeader.propTypes = {
    toolkitProps: PropTypes.object,
}