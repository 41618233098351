import React from "react";
import {REPORTS_GROUP_LINKS} from "../../config";
import CommentHistory from "./components/CommentsHistory/CommentHistory";
import CurrentComment from "./components/CurrentComment/CurrentComment";

//pop-up config

export const ACTIONS_TYPE = {
    DELETE: 'delete',
};

export const popupData = {
    [ACTIONS_TYPE.DELETE]: {
        isDanger: true,
        title: 'Delete comment',
        body: 'Are you sure you want to delete this comment?',
        labelCancel: 'No, Cancel',
        labelDone: 'Yes, Delete',
    },
}

export const ACTION_TYPE = {
    DELETE: 'delete',
};

export const popupSchema = {
    [ACTION_TYPE.DELETE]: {
        isDanger: true,
        title: 'Delete comment',
        body: 'Are you sure you want to delete this comment?',
        labelCancel: 'No, Cancel',
        labelDone: 'Yes, Delete',
    },
}

//  breadcrumb config

export const breadcrumbSchema = [
    {
        title: 'Reports',
        path: REPORTS_GROUP_LINKS.COMMENT_REPORTS
    },
    {
        title: 'Reports on a comment details',
    }
]

export const commentsSchema = (comment) => [
    {
        title: "Current version",
        page: <CurrentComment comment={comment} />
    },
    {
        title: "Version history",
        page: <CommentHistory comment={comment} />
    }
]

export const reportsHistoryTitle = "Version history";