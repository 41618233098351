import React from "react";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {ReactComponent as DangerIcon} from '../assets/images/danger-icon.svg'
import {ReactComponent as SuccessIcon} from '../assets/images/success-icon.svg'
import ToasterMessage from "../base/components/ToasterMessage";

toast.configure();

const options = {
    autoClose: 3000,
    position: toast.POSITION.TOP_RIGHT,
    closeOnClick: true,
    hideProgressBar: true,
    theme: "colored"
};

class ToasterService {

    success(message) {
        toast.success(<ToasterMessage icon={<SuccessIcon />} message={message}/>, options)
    }

    error(message) {
        toast.error(<ToasterMessage icon={<DangerIcon />} message={message}/>, options)
    }

    static $displayName = "ToasterService"
}


export default ToasterService
