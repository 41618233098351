import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import Context from "./Context";
// import * as serviceWorker from "./serviceWorker"

const Application = (
    <Context>
        <App/>
    </Context>
);

ReactDOM.render(Application, document.getElementById("root"))
// serviceWorker.unregister()
