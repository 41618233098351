import React from "react";
import PropTypes from "prop-types";
import {BrowserRouter as Router, BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";

import store from "./store";
import TranslateContainer from "./base/contexts/TranslateContainer";
import {LANGUAGES} from "./base/constants/languages";

import en from "./i18n/en";
import ru from "./i18n/ru";

export const languages = {
	[LANGUAGES.EN]: en,
	[LANGUAGES.RU]: ru
};

const Context = ({children}) => (
	<Provider store={store}>
		<TranslateContainer languages={languages} initialLanguage={LANGUAGES.EN}>
			<BrowserRouter>
				<Router>{children}</Router>
			</BrowserRouter>
		</TranslateContainer>
	</Provider>
);

Context.propTypes = {
	children: PropTypes.any,
};

export default Context;
