import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import Button from "../../../../../../base/components/Button";
import {Link, useHistory, useLocation} from 'react-router-dom';
import qs from "query-string";
import {parseQuery} from "../../../../../../base/hooks/useQueryString";
import {EVENTS_GROUP_LINKS} from "../../../../config";
import Filter from "../../../../../../base/components/Filters";
import {EVENTS_STATUS, EVENTS_STATUS_LABEL} from "../../../../../../base/constants/events";
import {useService} from "../../../../../../base/hooks/useService";
import TeamsService from "../../../../../../services/TeamsService";
import {useLoading} from "../../../../../../base/hooks/useLoading";
import SearchBar from "../../../../../../base/components/Table/tableSearch";
import {getFilterOptionFromQuery} from "../../../../../../base/helpers/getFiltersFromLocationSerch";

const FILTER_BY_STATUS = 'Status';
const FILTER_BY_TYPE = 'Type';

const DEFAULT_FILTER = {
    [FILTER_BY_STATUS]: undefined,
    [FILTER_BY_TYPE]: undefined
};

const filterStatusItems = Object.values(EVENTS_STATUS).map(status => ({
    text: EVENTS_STATUS_LABEL[status],
    value: status,
}))

const filterStatus = {
    name: FILTER_BY_STATUS,
    title: 'By Status',
    items: filterStatusItems,
};

export default function TableHeader({ toolkitProps }) {
    /**
     * @type {TeamsService}
     */
    const teamsService = useService(TeamsService);
    const [, {registerPromise}] = useLoading();
    const history = useHistory();
    const location = useLocation();

    const query = useMemo(() => parseQuery(location.search), [location]);
    const [eventTypes, setEventTypes] = useState([]);
    const [filterOption, setFilterOption] = useState(DEFAULT_FILTER);

    const filterType = useMemo(() => {
        return {
            name: FILTER_BY_TYPE,
            title: 'By Type',
            items: eventTypes,
            scroll: true,
        }
    }, [eventTypes])

    const filterSchema = useMemo(
        () => [filterStatus, filterType],
        [filterType, filterStatus]
    )

    const handleChangeFilters = (value) => {
        setFilterOption(value);
    };

    const handleSubmitFilters = () => {
        history.push({
            search: qs.stringify({
                ...query,
                offset: 0,
                [FILTER_BY_STATUS]: filterOption[FILTER_BY_STATUS],
                [FILTER_BY_TYPE]: filterOption[FILTER_BY_TYPE],
            })
        })
    };

    const resetFilters = () => {
        history.push({
            search: qs.stringify({
                ...query,
                [FILTER_BY_TYPE]: undefined,
                [FILTER_BY_STATUS]: undefined,
            })
        })
    };

    useEffect(() => {
        registerPromise(teamsService.getTeamTypes())
            .then(({data}) => {
                 setEventTypes(data.map(type => ({
                     text: type.name,
                     value: type.id
                 })));
            })
    }, [])

    useEffect(() => {
        setFilterOption(getFilterOptionFromQuery(Object.keys(DEFAULT_FILTER)))
    }, [query])

    return (
        <section className="d-flex flex-row align-items-center  m-0 py-3 justify-content-between w-100">
            <div className="search-box d-inline-block ">
                <div className="position-relative">
                    <SearchBar
                        {...toolkitProps.searchProps}
                    />
                    <i className="bx bx-search-alt search-icon" />
                </div>
            </div>
            <menu className="d-flex m-0">
                <Filter
                    defaultValue={DEFAULT_FILTER}
                    onChange={handleChangeFilters}
                    onReset={resetFilters}
                    onSubmit={handleSubmitFilters}
                    filterState={filterOption}
                    schema={filterSchema}
                />
                <Link to={EVENTS_GROUP_LINKS.LINK_EVENT_CREATE}>
                    <Button color="primary ms-4" className="d-flex align-items-center">+ Create an event</Button>
                </Link>
            </menu>
        </section>
    );
}

TableHeader.propTypes = {
    toolkitProps: PropTypes.object,
}
