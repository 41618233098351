import React, {useCallback, useEffect, useState} from "react";
import {useService} from "../../../../base/hooks/useService";
import {useLoading} from "../../../../base/hooks/useLoading";
import Breadcrumb from "../../../../base/components/Breadcrumb";
import {ACTION_WAITING_LEAGUE, breadcrumbSchema, menuSchema} from "./config";
import {useHistory, useParams} from "react-router-dom";
import {useRequestErrorMessage} from "../../../../base/hooks/useRequestErrorMessage";
import PageMeta from "../../../../base/components/MetaTags";
import ToasterService from "../../../../services/ToastService";
import LinkBack from "../../../../base/components/LinkBack";
import webSocketService from "../../../../services/WebSocketService";
import LeaguesService from "../../../../services/LeaguesService";
import {LEAGUES_GROUP_LINKS} from "../../config";
import {SOCKET_LEAGUES} from "../../constants/socket";
import MainInfo from "../../components/MainInfo";
import defaultAvatar from "../../../../assets/images/default-avatar.jpg";

const WaitingLeaguesDetails = () => {
    const leaguesService = useService(LeaguesService);
    const toasterService = useService(ToasterService);
    const [league, setLeague] = useState(null);
    const [, {registerPromise}] = useLoading();
    const [, {onCatchError}] = useRequestErrorMessage([]);
    const history = useHistory();
    const {id} = useParams();

    const loadLeague = () => {
        registerPromise(leaguesService.getWaitingLeagueById(id))
            .then(({data}) => {
                setLeague(data)
            })
            .catch(onCatchError);
    };

    const declineLeague = useCallback((data) => {
        const id = data.id;
        const body = {
            reasonText: data.inputText
        }
        leaguesService.declineLeague(id, body).then(() => {
            history.push(LEAGUES_GROUP_LINKS.WAITING_LEAGUES)
        });
    }, [])

    const acceptLeague = () => {
        registerPromise(leaguesService.acceptLeague(id))
            .then(({data}) => {
                toasterService.success(`League ${data.name} accepted`)
                history.push(LEAGUES_GROUP_LINKS.WAITING_LEAGUES)
            })
    }

    useEffect(() => {
        loadLeague();
        menuSchema[ACTION_WAITING_LEAGUE.DECLINE].action = declineLeague
        menuSchema[ACTION_WAITING_LEAGUE.ACCEPT].action = acceptLeague
    }, [registerPromise, leaguesService]);

    const update = (updatedLeague) => {
        if (updatedLeague.id === Number(id)) {
            setLeague(updatedLeague)
        }
    }

    webSocketService.onMessage({
        [SOCKET_LEAGUES.OWNER_EDIT_LEAGUE]: update,
        [SOCKET_LEAGUES.OWNER_DELETE_LEAGUE]: update,
    })

    return (
        <div className="page-content">
            <PageMeta title={'League details'}/>
            {league && (
                <>
                    <div className="content-header">
                        <LinkBack defaultLink={LEAGUES_GROUP_LINKS.WAITING_LEAGUES}>LEAGUES</LinkBack>
                        <Breadcrumb schema={breadcrumbSchema}/>
                    </div>
                    <MainInfo
                        avatar={league.logo?.originalPath || defaultAvatar}
                        name={league?.name}
                        owner={league?.owner}
                        description={league?.description}
                        type={league?.type}
                        menuSchema={menuSchema}
                        className="me-0"
                        id={league?.id}
                    />
                </>
            )}
        </div>
    );
};

export default WaitingLeaguesDetails;
