import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import {SETTINGS_GROUP_LINKS} from "./config";
import Settings from "./page/settings";

export default function SettingsRoutes() {
    return (
    <Switch>
      <Route
        path={SETTINGS_GROUP_LINKS.BASE}
        component={Settings}
        exact
      />
      <Redirect to={SETTINGS_GROUP_LINKS.BASE} />
    </Switch>
  );
}
