import React from "react";
import {validationSchema} from "./form";

import Button from "../../../../../../../../base/components/Button";
import {Formik} from "formik";
import FormikCheckbox from "../../../../../../../../base/components/FormikCheckbox";

import {BUTTON_COLORS, BUTTON_STYLES} from "../../../../../../../../base/components/Button/appearance";
import {PERMISSIONS} from "../../../../../../../../base/constants/admins";
import PropTypes from "prop-types";
import {getPermissionFlag} from "../../../../../../helpers/getPermissionFlag";

const FormPermissions = ({editAdminPermissions, permissions}) => {
    return (
        <div className="mt-3">
            <Formik
                enableReinitialize
                initialValues={{permissions}}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    editAdminPermissions(values.permissions);
                }}
            >
                {({errors, handleSubmit, values, initialValues, handleReset}) => {
                    const isDisableSubmit = Object.keys(errors).length || (getPermissionFlag(values.permissions) === getPermissionFlag(initialValues.permissions))
                    return (
                    <form className="form-horizontal" onSubmit={handleSubmit}>
                        <label className="mb-3 mt-4">Accessible tabs in the Admin panel</label>

                        {Object.keys(PERMISSIONS).map((key) => {
                                return (
                                    <FormikCheckbox
                                        key={key}
                                        label={PERMISSIONS[key].label}
                                        name="permissions"
                                        value={PERMISSIONS[key].value.toString()}
                                        className="mb-3"
                                        id={key}
                                    />
                                )
                            }
                        )}
                        <div className="d-flex justify-content-start">
                            <Button
                            color={BUTTON_COLORS.primary}
                            type="submit"
                            block={BUTTON_STYLES.block}
                            className="mt-2 w-auto"
                            outline
                            onClick={handleReset}
                        >
                            Cancel
                        </Button>

                            <Button
                                color={BUTTON_COLORS.primary}
                                type="submit"
                                block={BUTTON_STYLES.block}
                                className="mt-2 ms-2 w-auto"
                                disabled={isDisableSubmit}
                            >
                                Save
                            </Button>
                        </div>
                    </form>
                )}}
            </Formik>
        </div>
    );
};

FormPermissions.propTypes = {
    permissions: PropTypes.array,
    editAdminPermissions: PropTypes.func,
};

export default FormPermissions;
