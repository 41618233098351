import React from 'react'
import { parseQuery } from "../../../../../../base/hooks/useQueryString";
import Icon from '../../../../../../base/components/Icon';
import { SORT_KEY, SORT_TYPE } from "../../../../../../base/constants/query";
import Button from "../../../../../../base/components/Button";
import { handleWithoutPropagation } from "../../../../../../base/helpers/handleWithoutPropagation";
import NestedNotificationPopup from "../../../../../../base/components/Popups/NestedNotificationPopup";
import {REASON_FIELD_MAX_LENGTH} from "./config";

const DESC_SORT = 'desc';
const DESC_SORT_ICON = 'sortDesc';
const ASC_SORT_ICON = 'sortAsc';
const SORT_DEFAULT_ICON = 'sortDefault';

export const tableStructure = (declineTeam, acceptTeam) => (location, decorateText) => {
    const infoQuery = parseQuery(location.search);
    function getSortIcon(cellName) {
        if (infoQuery[SORT_KEY] === cellName) {
            return infoQuery[SORT_TYPE] === DESC_SORT ? ASC_SORT_ICON : DESC_SORT_ICON;
        }
        return SORT_DEFAULT_ICON;
    }

    return [
        {
            dataField: "name",
            sort: true,
            headerFormatter: () => (<>Team name<Icon icon={getSortIcon('name')} /></>),
            formatter: (cellContent, team) => {
                return (
                    <section className="d-flex flex-row align-items-center">
                        <span className="tutee-list--list__name teams-table__name" {...decorateText(team.teamName)} />
                    </section>
                )
            }
        },
        {
            headerFormatter: () => (<>Team owner</>),
            dataField: "teamOwner",
            sort: false,
            formatter: (cellContent, team) => (
                <>
                    <section className="d-flex flex-row align-items-center">
                        <span className="tutee-list--list__name mx-1" {...decorateText(team.ownerFirstName)} />
                        <span className="tutee-list--list__name" {...decorateText(team.ownerLastName)} />
                    </section>
                </>
            )
        },
        {
            headerFormatter: () => (<>Team type</>),
            dataField: "type",
            sort: false,
            formatter: (cellContent) => (
                <>
                    {cellContent.name}
                </>
            )
        },
        {
            sort: false,
            headerClasses: 'action-cell',
            headerFormatter: () => (<>Actions</>),
            formatter: (cellContent, team) => {
                return <div className="d-flex" onClick={handleWithoutPropagation()}>
                    <NestedNotificationPopup
                        close={close}
                        action={declineTeam}
                        title="Decline"
                        nestedTitle="Decline"
                        body="Are you sure you want to decline this request?"
                        nestedBody="Please provide reason for declining"
                        inputLabel="Reason"
                        inputPlaceholder="Enter reason"
                        labelCancel="No, Cancel"
                        nestedLabelCancel="Close"
                        labelDone="Yes, Decline"
                        nestedLabelDone="Confirm"
                        params={{id: team.id}}
                        maxLength={REASON_FIELD_MAX_LENGTH}
                    >
                        <Button
                            color="danger"
                            className="py-1 me-3"
                            outline>
                            Decline
                        </Button>
                    </NestedNotificationPopup>
                    <Button
                        color="success"
                        className="py-1"
                        onClick={() => { acceptTeam(team.id) }}>
                        Accept
                    </Button>
                </div>
            },
        },
    ]
}