export const SOCKET_TEAMS = {
    TEAM_ACCEPTED: 'admin_team_accepted',
    TEAM_DECLINED: 'admin_team_declined',
    TEAM_OWNER_CHANGED: 'admin_team_owner_changed',
    TEAM_MEMBER_DELETED: 'admin_team_member_deleted',
    NEW_CREATE_TEAM_REQUEST: 'new_create_team_request',
    EDIT_TEAM_MEMBER_DESCRIPTION: 'edit_team_member_description',
    TEAM_MEMBER_CHANGE_ROLE: 'team_member_change_role',
    OWNER_TEAM_MEMBER_DELETED: 'owner_team_member_deleted',
    OWNER_EDIT_TEAM: 'owner_edit_team',
    OWNER_DELETE_TEAM: 'owner_delete_team',
    ACCEPT_TEAM_JOIN_REQUEST: 'accept_team_join_request',
    DECLINE_TEAM_JOIN_REQUEST: 'decline_team_join_request',
    USER_LEAVE_TEAM: 'user_leave_team',
}