import React from "react";
import {useModal} from "../../../hooks/useModal";
import Button from "../../Button";
import {useHistory} from "react-router-dom";
import {AUTH_GROUP_LINKS} from "../../../../groups/auth/config";
import {ReactComponent as SignOutIcon} from "../../../../assets/images/profile-dropdown/sign-out.svg";
import {useService} from "../../../hooks/useService";
import AuthService from "../../../../services/AuthService";

const SignOutButton = () => {
    const {Modal, close, getModalProps, open, isOpen} = useModal();
    const history = useHistory();
    const authService = useService(AuthService);

    const handleLogout = () => {
        authService.logout()
            .finally(() => {
                history.push(AUTH_GROUP_LINKS.LINK_LOG_OUT)
            })
    }

    return <>
        <div onClick={open} className="d-flex align-items-center">
            <SignOutIcon/>
            <span className="px-1">Sign out</span>
            <Modal {...getModalProps} isOpen={isOpen} size="md">
                <Modal.Header onClose={close}>Sign out</Modal.Header>
                <Modal.Body>
                    <span>Are you sure you want to sign out?</span>
                    <menu className="modal-footer">
                        <Button color='primary' outline onClick={close}>No, stay</Button>
                        <Button color="danger" onClick={handleLogout}>Yes, sign out</Button>
                    </menu>
                </Modal.Body>
            </Modal>
        </div>

    </>
};

export default SignOutButton;