export const PERMISSIONS = {
    USER_MANAGEMENT: {
        label: 'Users management',
        value: 1,
    },
    TEAMS_MANAGEMENT: {
        label: 'Teams management',
        value: 2,
    },
    EVENTS_MANAGEMENT: {
        label: 'Events management',
        value: 4,
    },
    REPORTS_MANAGEMENT: {
        label: 'Reports management',
        value: 8,
    },
    LEAGUES_MANAGEMENT: {
        label: 'Leagues management',
        value: 16,
    }
}