import {TEAMS_GROUP_LINKS} from "../../config";

export const breadcrumbSchema = [
    {
        title: 'Teams',
        path: TEAMS_GROUP_LINKS.BASE
    },
    {
        title: 'Team details',
    }
];

export const ACTION_MEMBER_TYPE = {
    DELETE_MEMBER: 'deleteMember',
    DELETE_OWNER: 'deleteOwner',
    ASSIGN_TEAM_OWNER: 'assignTeamOwner'
};

export const popupSchema = {

    [ACTION_MEMBER_TYPE.DELETE_MEMBER]: {
        isDanger: true,
        title: 'Delete member',
        body: 'Are you sure you want to delete this member from the team?',
        labelCancel: 'No, Cancel',
        labelDone: 'Yes, Delete',
    },
    [ACTION_MEMBER_TYPE.ASSIGN_TEAM_OWNER]: {
        isDanger: false,
        title: 'Assign member',
        body: 'Are you sure you want to assign this member as a team owner?',
        labelCancel: 'No, Cancel',
        labelDone: 'Yes, Assign',
    },
    [ACTION_MEMBER_TYPE.DELETE_OWNER]: {
        isDanger: false,
        title: 'Delete team owner',
        body: 'You are trying to delete a Team Owner. Please, assign a new one first',
        labelCancel: 'OK',
    },
}

