export const SOCKET_LEAGUES = {
    LEAGUE_ACCEPTED: 'admin_league_accepted',
    LEAGUE_DECLINED: 'admin_league_declined',
    LEAGUE_OWNER_CHANGED: 'admin_league_owner_changed',
    OWNER_DELETE_LEAGUE: 'owner_delete_league',
    OWNER_EDIT_LEAGUE: 'owner_edit_league',
    LEAGUE_MEMBER_DELETED: 'admin_league_member_deleted',
    EDIT_LEAGUE_MEMBER_DESCRIPTION: 'edit_league_member_description',
    LEAGUE_MEMBER_CHANGE_ROLE: 'league_member_change_role',
    NEW_CREATE_LEAGUE_REQUEST: 'new_create_league_request',
    ACCEPT_LEAGUE_JOIN_REQUEST: 'accept_league_join_request',
    DECLINE_LEAGUE_JOIN_REQUEST: 'decline_league_join_request',
    MEMBER_LEAVE_LEAGUE: 'member_leave_league',
    OWNER_LEAGUE_MEMBER_DELETED: 'owner_league_member_deleted',
    LEAGUE_ADMIN_LEAVE_LEAGUE: 'league_admin_leave_league',
    OWNER_LEAGUE_OWNER_CHANGED: 'owner_league_owner_changed',
    ADMIN_LEAGUE_OWNER_CHANGED: 'admin_league_owner_changed'
}