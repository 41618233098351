import React, {useEffect, useState} from 'react';
import {useService} from "../../../../base/hooks/useService";
import {useLoading} from "../../../../base/hooks/useLoading";
import {useRequestErrorMessage} from "../../../../base/hooks/useRequestErrorMessage";
import {useHistory, useParams} from "react-router-dom";
import webSocketService from "../../../../services/WebSocketService";
import PageMeta from "../../../../base/components/MetaTags";
import LinkBack from "../../../../base/components/LinkBack";
import Breadcrumb from "../../../../base/components/Breadcrumb";
import defaultAvatar from "../../../../assets/images/default-avatar.jpg";
import LeaguesService from "../../../../services/LeaguesService";
import {SOCKET_LEAGUES} from "../../constants/socket";
import {LEAGUES_GROUP_LINKS} from "../../config";
import {LEAGUES_STATUS} from "../../../../base/constants/leagues";
import MainInfo from "../../components/MainInfo";
import {breadcrumbSchema} from './config';
import {TableMembers} from "./TableMembers";
import ToastService from "../../../../services/ToastService";

const AcceptedLeaguesDetails = () => {
    const leaguesService = useService(LeaguesService);
    const toastService = useService(ToastService);
    const [league, setLeague] = useState(null);
    const [isLoading, {registerPromise}] = useLoading();
    const [, {onCatchError}] = useRequestErrorMessage([]);
    const {id} = useParams();
    const isDeleted = league && league.status === LEAGUES_STATUS.DELETED;
    const history = useHistory();

    const loadLeague = () => {
        registerPromise(leaguesService.getLeagueById(id))
            .then(({data}) => {
                setLeague(data)
            })
            .catch(onCatchError);
    };

    const deleteLeague = (data) => {
        const body = {
            reasonText: data.inputText
        }
        registerPromise(leaguesService.deleteLeague(id, body))
            .then(({data}) => {
                toastService.success(data.message)
                history.push(LEAGUES_GROUP_LINKS.ACCEPTED_LEAGUES)
            })
            .catch(onCatchError);
    }

    useEffect(() => {
        loadLeague();
    }, [registerPromise, leaguesService]);

    const update = (updatedLeague) => {
        if (updatedLeague.id === Number(id)) {
            setLeague(updatedLeague)
        }
    }

    webSocketService.onMessage({
        [SOCKET_LEAGUES.OWNER_DELETE_LEAGUE]: update,
        [SOCKET_LEAGUES.OWNER_EDIT_LEAGUE]: update,
        [SOCKET_LEAGUES.LEAGUE_ADMIN_LEAVE_LEAGUE]: update
    })

    return (
        <div className="page-content">
            <PageMeta title={'League info'}/>
            {league && (
                <>
                    <div className="content-header">
                        <LinkBack defaultLink={LEAGUES_GROUP_LINKS.ACCEPTED_LEAGUES}>Leagues</LinkBack>
                        <Breadcrumb schema={breadcrumbSchema}/>
                    </div>

                    <MainInfo
                        avatar={league.logo?.originalPath || defaultAvatar}
                        name={league?.name}
                        owner={league?.owner}
                        status={league?.status}
                        description={league?.description}
                        type={league?.type}
                        isDeleted={isDeleted}
                        deleteLeague={deleteLeague}
                    />
                </>
            )}

            {!isLoading && <TableMembers
                isDelete={isDeleted}
            />}
        </div>
    );
};

export default AcceptedLeaguesDetails;