import Http from "./Http";

class AdminsService extends Http {
  static $displayName = "AdminsService";

  async getAdmins(params) {
    return await this.get("/admins", { params });
  }

  async createAdmin(value) {
    return await this.post("/admins", value);
  }

  async getAdminById(id) {
    return await this.get(`/admins/${id}`);
  }

  async changeAdminPermissions(id, value) {
    return await this.put(`/admins/${id}`, value);
  }

  async deleteAdminById(id) {
    return await this.delete(`/admins/${id}`);
  }

  async blockAdminById(id) {
    return await this.patch(`/admins/${id}`);
  }

  async changeEmail(value) {
    return await this.put(`/admin-settings/email`, value);
  }

  async confirmEmail (value) {
    return await this.put(`/admin-verifications/email`, value);
  }

  async changePassword(value) {
    return await this.put(`/admin-settings/password`, value);
  }
}

export default AdminsService;
