import React, {useEffect, useMemo, useState} from "react";
import MainUserInfo from "./components/MainUserInfo/";
import {useService} from "../../../../base/hooks/useService";
import UsersService from "../../../../services/UsersService";
import {useUserIdFromParams} from "../../hooks";
import {useLoading} from "../../../../base/hooks/useLoading";
import Breadcrumb from "../../../../base/components/Breadcrumb";
import {breadcrumbSchema, popupSchema, ACTION_USER_TYPE, listSchema} from "./config";
import {USERS_GROUP_LINKS} from "../../config";
import {useRequestErrorMessage} from "../../../../base/hooks/useRequestErrorMessage";
import PageMeta from "../../../../base/components/MetaTags";
import LinkBack from "../../../../base/components/LinkBack";
import webSocketService from "../../../../services/WebSocketService";
import {SOCKET_USERS} from "../../constants/socket";
import Popup from "../../../../base/components/Popups/Popup";
import {SPECIAL_ERROR_CODES} from "../../../../base/constants/errorCodes";
import NavTabs from "../../../../base/components/NavTabs/NavTabs";
import {useParams} from "react-router-dom";

const UserInfo = () => {
    /**
     * @type {UsersService}
     */
    const usersService = useService(UsersService);
    const [user, setUser] = useState(null);
    const [openDeleteOwner, setOpenDeleteOwner] = useState(false)
    const [, {registerPromise}] = useLoading();
    const [, {onCatchError}] = useRequestErrorMessage([]);

    const {userId} = useUserIdFromParams();

    const loadUser = () => {
        registerPromise(usersService.getUserById(userId))
            .then(({data}) => setUser(data))
            .catch(onCatchError);
    }

    const deleteUser = () => {
        registerPromise(usersService.deleteUserById(userId))
            .then(({data}) => setUser(data))
            .catch((error) => {
                if (error.code === SPECIAL_ERROR_CODES.ASSIGN_OWNER) {
                    setOpenDeleteOwner(true)
                } else {
                    onCatchError(error)
                }
            });
    }

    const deleteTeamOwner = () => {
        setOpenDeleteOwner(false)
    }

    const blockUser = () => {
        registerPromise(usersService.blockUserById(userId))
            .then(({data}) => setUser(data))
            .catch(onCatchError);
    }

    const unblockUser = () => {
        registerPromise(usersService.blockUserById(userId))
            .then(({data}) => setUser(data))
            .catch(onCatchError);
    }

    const updateData = (updatedUser) => {
        if (updatedUser.id === Number(userId)) {
            setUser(updatedUser)
        }
    }

    webSocketService.onMessage({
        [SOCKET_USERS.USER_EDIT_PROFILE]: updateData,
        [SOCKET_USERS.ADMIN_CHANGE_EMAIL]: updateData,
    })

    useEffect(() => {
        loadUser();
    }, [registerPromise, usersService]);

    const { id } = useParams();

    const rootLocation = useMemo(() => `${USERS_GROUP_LINKS.BASE}/${id}`, [id])

    return (
        <div className="page-content">
            <PageMeta title={'User info'}/>
            {user && (
                <>
                    <div className="content-header">
                        <LinkBack defaultLink={USERS_GROUP_LINKS.BASE}>Users</LinkBack>
                        <Breadcrumb schema={breadcrumbSchema}/>
                    </div>
                    <MainUserInfo
                        user={user}
                        blockUser={blockUser}
                        unblockUser={unblockUser}
                        deleteUser={deleteUser}
                    />
                    <NavTabs
                        rootLink={USERS_GROUP_LINKS.LINK_USER_DETAILS}
                        rootLocation={rootLocation}
                        schema={listSchema(id, user.status)}
                        className="m-0 px-2 py-0 nav-tabs-event-teams"
                    />
                </>
            )}
            <Popup
                {...popupSchema[ACTION_USER_TYPE.DELETE_OWNER]}
                action={deleteTeamOwner}
                isOpen={openDeleteOwner}
            />
        </div>
    );
};

export default UserInfo;
