import React from 'react'
import { parseQuery } from "../../../../../../base/hooks/useQueryString";
import Icon from '../../../../../../base/components/Icon';
import {formatDate} from "../../../../../../base/helpers/formatDate";
import {SORT_KEY, SORT_TYPE} from "../../../../../../base/constants/query";

const DESC_SORT = 'desc';
const DESC_SORT_ICON = 'sortDesc';
const ASC_SORT_ICON = 'sortAsc';
const SORT_DEFAULT_ICON = 'sortDefault';

export default function UsersTableStructure(location, decorateText) {
    const infoQuery = parseQuery(location.search);
    function getSortIcon(cellName) {
        if (infoQuery[SORT_KEY] === cellName) {
            return infoQuery[SORT_TYPE] === DESC_SORT ? ASC_SORT_ICON : DESC_SORT_ICON;
        }
        return SORT_DEFAULT_ICON;
    }

    return [
        {
            dataField: "name",
            sort: true,
            headerFormatter: () => (<>League name<Icon icon={getSortIcon('name')} /></>),
            formatter: (cellContent, league) => {
                return (
                    <section className="d-flex flex-row align-items-center">
                        <span className="tutee-list--list__name" {...decorateText(league.name)} />
                    </section>
                )
            }
        },
        {
            dataField: "type",
            sort: false,
            headerFormatter: () => (<>League owner name</>),
            formatter: (cellContent, league) => {
                return <span>{league.ownerName}</span>
            }
        },
        {
            headerFormatter: () => (<>Date of creation<Icon icon={getSortIcon('createdAt')} /></>),
            dataField: "createdAt",
            sort: true,
            formatter: (cellContent) => (
                <>
                    {formatDate(cellContent)}
                </>
            )
        }
    ]
}