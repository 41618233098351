import {TIMEZONE} from "../constants/timezone";
import moment from "moment";

const DEFAULT_DATETIME_PATTERN = "yyyy-MM-DDTHH:mm:ss"

export const getTimezoneByDate = (date, timezone, withUTC, formatPattern = DEFAULT_DATETIME_PATTERN) => {
    const newDate = new Date(date);
    Object.values(TIMEZONE).map((item) => {
        if(timezone && item.is === timezone.replace(/([A-Z])/g, ' $1').trim(withUTC)) {
            newDate.setHours(new Date(date).getHours() + item.time);
        }
    })
    const momentObject = moment(newDate.toISOString()).utc(false)
    return newDate && momentObject.isValid() ? momentObject.format(formatPattern) : ''
}