import React from "react";
import Dropdown from "../Dropdown";
import {profileDropdownSchema} from "./config";
import {generateAvatar} from "./helpers/generateAvatar";
import {ADMIN_ROLE_LABEL, ADMIN_ROLE} from "../../constants/users";

const SUPER_ADMIN_ABBREVIATION = 'SA';
const PLATFORM_ADMIN_ABBREVIATION = 'PA';

const ProfileDropdown = ({user}) => {
  const {avatar, lastName, firstName, role} = user;

  const isNoName = Boolean(!firstName || !lastName);
  const avatarNoName = role === ADMIN_ROLE.SUPER_ADMIN ? SUPER_ADMIN_ABBREVIATION : PLATFORM_ADMIN_ABBREVIATION;

  return  <Dropdown
      optionsSchema={profileDropdownSchema}
      inNavbar
      size="sm"
      direction="right"
  >
    <div className="d-flex align-items-center profile-dropdown">
        {avatar?.src
            ? <img src={avatar.src} className="avatar"/>
            : isNoName
                ? <div className="avatar">{avatarNoName}</div>
                : generateAvatar(firstName, lastName)
        }
      <span className="pe-4">{isNoName
          ? ADMIN_ROLE_LABEL[role]
          : `${firstName} ${lastName}`
      }</span>
    </div>
  </Dropdown>
};

export default ProfileDropdown;
