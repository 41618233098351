const TIMEZONES_LIST = {
    "HAWAII": "Hawaii",
    "ALASKA": "Alaska",
    "PACIFIC": "Pacific",
    "MOUNTAIN": "Mountain",
    "CENTRAL": "Central",
    "EASTERN": "Eastern"
}

export const TIMEZONE = {
    [TIMEZONES_LIST.HAWAII]: {
        is: 'Hawaii Time',
        time: -10
    },
    [TIMEZONES_LIST.ALASKA]: {
        is: 'Alaska Time',
        time: -8
    },
    [TIMEZONES_LIST.PACIFIC]: {
        is: 'Pacific Time',
        time: -7
    },
    [TIMEZONES_LIST.MOUNTAIN]: {
        is: 'Mountain Time',
        time: -6
    },
    [TIMEZONES_LIST.CENTRAL]: {
        is: 'Central Time',
        time: -5
    },
    [TIMEZONES_LIST.EASTERN]: {
        is: 'Eastern Time',
        time: -4
    }
}
