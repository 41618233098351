import React from 'react'
import {formatDatetime} from "../../../../base/helpers/formatDatetime";
import {REPORTS_STATUS_LABEL} from "../../../../base/constants/reports";
import {USERS_GROUP_LINKS} from "../../../users/config";
import {Link} from "react-router-dom";

const DATETIME_FORMAT_PATTERN = "DD MMM yyyy hh:mm A"

export const tableStructure = (currentPathName) => () => {
    return [
        {
            dataField: "text",
            sort: false,
            classes: 'cell-wrap',
            headerFormatter: () => 'Report message',
        },
        {
            dataField: "reporterName",
            sort: false,
            headerFormatter: () => 'Reporter name',
            formatter: (cellContent, report) =>
                <Link
                    to={{
                        pathname: `${USERS_GROUP_LINKS.BASE}/${report.reporter?.id}`,
                        state: {from: currentPathName}
                    }}
                    className="text-dark">
                    {report.reporter.firstName} {report.reporter.lastName}
                </Link>
        },
        {
            dataField: "createdAt",
            sort: false,
            headerFormatter: () => 'Report date & time',
            formatter: (cellContent) => formatDatetime(cellContent, DATETIME_FORMAT_PATTERN)
        },
        {
            dataField: "status",
            headerFormatter: () => 'Status',
            sort: true,
            formatter: (cellContent, report) => <span className={report.status}>{REPORTS_STATUS_LABEL[report.status]}</span>
        }
    ]
}