import React, { useEffect, useMemo, useState } from "react";
import { useService } from "../../../../base/hooks/useService";
import { Card, Container } from "reactstrap";
import Button from "../../../../base/components/Button";
import { Formik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import Breadcrumb from "../../../../base/components/Breadcrumb";
import PageMeta from "../../../../base/components/MetaTags";
import { BUTTON_COLORS, BUTTON_STYLES } from "../../../../base/components/Button/appearance";
import {EVENTS_GROUP_LINKS, format, platforms, timeZone} from "../../config";
import {ACTION_EVENT_TYPE, breadcrumbSchema, initialValues, popupSchema} from "./config";
import EventsService from "../../../../services/EventsService";
import { useLoading } from "../../../../base/hooks/useLoading";
import { EventInputs } from "../../components/EventInputs";
import TeamsService from "../../../../services/TeamsService";
import { teamsListSchema } from "./config";
import NavTabs from "../../../../base/components/NavTabs/NavTabs";
import { EVENTS_STATUS } from "../../../../base/constants/events";
import FormikCheckbox from "../../../../base/components/FormikCheckbox";
import Popup from "../../../../base/components/Popups/Popup";
import { validationSchema } from "../CreateEvent/form";
import FilesService from "../../../../services/FilesServise";
import { useRequestErrorMessage } from "../../../../base/hooks/useRequestErrorMessage";
import LinkBack from "../../../../base/components/LinkBack";
import webSocketService from "../../../../services/WebSocketService";
import {SOCKET_EVENTS} from "../../constants/socket";
import {getDateByTimezone} from "../../../../base/helpers/getDateByTimezone";
import {getTimezoneByDate} from "../../../../base/helpers/getTimezoneByDate";

const ADMIN_EVENT_TYPE = {
    TEMPLATE: 'Template',
    EVENT: 'Event',
}

export const EventDetails = () => {
    /**
     * @type {EventsService}
     */
    const eventsService = useService(EventsService);

    /**
     * @type {TeamsService}
     */
    const teamsService = useService(TeamsService);

    /**
     * @type {FilesService}
     */
    const filesService = useService(FilesService);

    const [event, setEvent] = useState(initialValues);
    const [types, setTypes] = useState([]);

    const isTemplate = event.adminEventType === ADMIN_EVENT_TYPE.TEMPLATE;

    const isActiveForm = [EVENTS_STATUS.DRAFTS, EVENTS_STATUS.UPCOMING, EVENTS_STATUS.SUSPENDED].includes(event.eventStatus) && !isTemplate;
    const isDrafts = event.eventStatus === EVENTS_STATUS.DRAFTS;
    const isSuspended = event.eventStatus === EVENTS_STATUS.SUSPENDED;
    const isSuspendedMode = [EVENTS_STATUS.UPCOMING, EVENTS_STATUS.SUSPENDED].includes(event.eventStatus) && !isTemplate;
    const isShowListRequests = !isDrafts && !isTemplate && event.eventStatus

    const [isEditTemplate, setIsEditTemplate] = useState(false);

    const isEditTemplateMode = isEditTemplate && isTemplate;

    const [isLoading, { registerPromise }] = useLoading();
    const [, { onCatchError }] = useRequestErrorMessage();
    const history = useHistory();
    const { id } = useParams();

    const mapEventToFormValues = (data) => {
        return {
            ...data,
            link: data.locationLink,
            saveAsTemplete: false,
            endDate: getTimezoneByDate(data.endDate, data.timezone, true),
            startDate: getTimezoneByDate(data.startDate, data.timezone, true),
            type: data.type.id,
            timezone: data.timezone && data.timezone.replace(/([A-Z])/g, ' $1').trim()
        }
    }

    const rootLocation = useMemo(() => `${EVENTS_GROUP_LINKS.BASE}/${id}`, [id])

    const getRequestValue = async (value) => {
        try {
            if (value.image instanceof File) {
                const { data } = await filesService.uploadImage(value.image)
                value.imageId = data.id;
            }

            return {
                name: value.name,
                typeId: value.type,
                location: value.location || null,
                locationLink: value.link || null,
                imageId: value.imageId || value.image?.id,
                startDate: getDateByTimezone(value.startDate, value.timezone),
                endDate: getDateByTimezone(value.endDate, value.timezone),
                description: value.description,
                saveAsTemplete: value.saveAsTemplete || false,
                saveAsDraft: false,
                timezone: value.timezone && value.timezone.split(/\s+/).join(''),
                format: value.format,
                platform: value.format === "Virtual" ? value.platform : null
            }
        } catch (err) {
            onCatchError(err)
        }
    }

    const publishEvent = async (value) => {
        registerPromise(eventsService.createEvents(await getRequestValue(value)))
            .then(({ data }) => {
                if (isDrafts) {
                    eventsService.deleteEvents(id)
                }
                history.push(`${EVENTS_GROUP_LINKS.BASE}/${data.id}`)
            })
            .catch(onCatchError)
    }

    const editEvent = async (id, value) => {
        registerPromise(eventsService.editEvents(id, await getRequestValue(value)))
            .then(({ data }) => setEvent(mapEventToFormValues(data)))
            .catch(onCatchError)
    }

    const changeDraft = async (id, value) => {
        registerPromise(eventsService.changeDraftStatus(id, await getRequestValue(value)))
            .then(({ data }) => setEvent(mapEventToFormValues(data)))
            .catch(onCatchError)
    }

    const handleSubmitForm = async (value) => {
        if (value.isEdit) {
            setIsEditTemplate(false)

            if (isDrafts) {
                await changeDraft(id, value)
            } else {
                await editEvent(id, value)
            }
        } else {
            publishEvent(value)
        }
    }

    const suspendedEvent = () => {
        eventsService.changeSuspendedStatus(id)
            .then(({ data }) => setEvent(mapEventToFormValues(data)))
    }

    const deleteEvent = () => {
        eventsService.deleteEvents(id)
            .then(() => {
                history.push(EVENTS_GROUP_LINKS.BASE)
            })
    }

    const loadEvent = () => {
        registerPromise(eventsService.getEventById(id))
            .then(({ data }) => setEvent(mapEventToFormValues(data)))
            .catch(onCatchError)
    }

    useEffect(() => {
        loadEvent();
    }, [id]);

    useEffect(() => {
        registerPromise(teamsService.getTeamTypes())
            .then(({ data }) => {
                setTypes(data.map(type => ({
                    label: type.name,
                    value: type.id
                })));
            })
    }, []);

    const update = (updatedEvent) => {
        if (updatedEvent.id === Number(id)) {
            setEvent(mapEventToFormValues(updatedEvent))
        }
    }

    webSocketService.onMessage({
        [SOCKET_EVENTS.EVENT_EDITED]: update,
        [SOCKET_EVENTS.EVENT_CREATED]: update,
        [SOCKET_EVENTS.EVENT_SUSPENDED_UNSUSPENDED]: update,
    })

    const title = isTemplate ? 'Event template detail' : 'Event detail'

    return (
        <section className="page-content">
            <PageMeta title={title} />
            <div className="content-header">
                <LinkBack defaultLink={EVENTS_GROUP_LINKS.BASE}>Events</LinkBack>
                <Breadcrumb schema={breadcrumbSchema(title)} />
            </div>
            <Container fluid className="p-0">
                <Card className="p-4 w-100">
                    <h4 className="card-title mb-4">{title}</h4>
                    {!isLoading && <Formik
                        initialValues={event}
                        enableReinitialize
                        onSubmit={handleSubmitForm}
                        validationSchema={validationSchema}
                    >
                        {({ dirty, handleReset, handleSubmit, setFieldValue, validateForm, status, values }) => {
                            const isEditMode = ((isActiveForm && dirty) || isDrafts) || isEditTemplateMode
                            return (
                                <form className="form-horizontal inline-form">
                                    <fieldset>
                                        {!isTemplate && <section className="inline-input py-2">
                                            <label>Status</label>
                                            <div className="py-2">
                                                <span className={event.eventStatus}>
                                                    {event.eventStatus}
                                                </span>
                                            </div>
                                        </section>}

                                        <EventInputs
                                            types={types}
                                            disabled={!(isTemplate || isActiveForm)}
                                            image={event?.image?.originalPath}
                                            format={format}
                                            platform={platforms}
                                            timeZone={timeZone}
                                            values={values}
                                        />

                                        {isDrafts && !isTemplate && <FormikCheckbox
                                            label='Save as template'
                                            className="right-block"
                                            name='saveAsTemplete'
                                            id="saveAsTemplete"
                                        />}

                                        <div className="d-flex">
                                            {isEditMode
                                                ? <div className="d-flex">
                                                    <Popup
                                                        {...popupSchema[ACTION_EVENT_TYPE.CANCEL_CHANGES]}
                                                        action={() => {
                                                            setIsEditTemplate(false)
                                                            handleReset()
                                                        }}
                                                    >
                                                        <Button
                                                            color={BUTTON_COLORS.primary}
                                                            type="button"
                                                            block={BUTTON_STYLES.block}
                                                            className="mt-4 ms-2 w-auto"
                                                            outline
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </Popup>
                                                    <Button
                                                        color={BUTTON_COLORS.primary}
                                                        type="button"
                                                        block={BUTTON_STYLES.block}
                                                        className="mt-4 ms-2 w-auto"
                                                        onClick={() => {
                                                            if (status?.errors) return
                                                            if (!isDrafts) {
                                                                setFieldValue('saveAsTemplete', isTemplate)
                                                            }
                                                            validateForm()
                                                                .then(() => {
                                                                    setFieldValue('isEdit', true)
                                                                    handleSubmit()
                                                                })
                                                        }}
                                                        disabled={isLoading}
                                                    >
                                                        {isDrafts ? 'Create event' : 'Save'}
                                                    </Button>
                                                </div>
                                                : <div className="d-flex">
                                                    <Popup
                                                        {...popupSchema[isTemplate
                                                            ? ACTION_EVENT_TYPE.DELETE_TEMPLATE
                                                            : ACTION_EVENT_TYPE.DELETE]
                                                        }
                                                        action={deleteEvent}
                                                    >
                                                        <Button
                                                            color={BUTTON_COLORS.danger}
                                                            type="button"
                                                            block={BUTTON_STYLES.block}
                                                            outline
                                                            className="mt-4 w-auto"
                                                        >
                                                            Delete
                                                        </Button>
                                                    </Popup>

                                                    {isSuspendedMode && <Button
                                                        color={BUTTON_COLORS.primary}
                                                        type="button"
                                                        block={BUTTON_STYLES.block}
                                                        className="mt-4 ms-2 w-auto"
                                                        outline
                                                        disabled={false}
                                                        onClick={suspendedEvent}
                                                    >
                                                        {isSuspended
                                                            ? 'Unsuspended'
                                                            : 'Suspended'
                                                        }
                                                    </Button>}
                                                    {isTemplate && <>
                                                        <Button
                                                            color={BUTTON_COLORS.primary}
                                                            type="button"
                                                            block={BUTTON_STYLES.block}
                                                            className="mt-4 ms-2 w-auto"
                                                            disabled={isLoading}
                                                            outline
                                                            onClick={() => { setIsEditTemplate(true) }}
                                                        >
                                                            Edit template
                                                        </Button>
                                                        <Button
                                                            color={BUTTON_COLORS.primary}
                                                            type="button"
                                                            block={BUTTON_STYLES.block}
                                                            className="mt-4 ms-2 w-auto"
                                                            disabled={isLoading}
                                                            onClick={() => {
                                                                if (status?.errors) return
                                                                validateForm()
                                                                    .then(() => {
                                                                        setFieldValue('saveAsTemplete', false)
                                                                        setFieldValue('isEdit', false)
                                                                        handleSubmit()
                                                                    })
                                                            }}
                                                        >
                                                            Publish event
                                                        </Button>
                                                    </>}
                                                </div>
                                            }
                                        </div>
                                    </fieldset>
                                </form>
                            )
                        }
                        }
                    </Formik>}
                </Card>
                {isShowListRequests && <NavTabs
                    rootLink={EVENTS_GROUP_LINKS.EVENT_DETAILS}
                    rootLocation={rootLocation}
                    schema={teamsListSchema(event.eventStatus)}
                    className="m-0 px-2 py-0 nav-tabs-event-teams"
                />}
            </Container>
        </section>
    );
};
