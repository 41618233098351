import React, {useEffect, useState} from "react";
import {useService} from "../../../../base/hooks/useService";
import {Card, Container} from "reactstrap";
import Button from "../../../../base/components/Button";
import {Formik} from "formik";
import {useHistory} from "react-router-dom";
import Breadcrumb from "../../../../base/components/Breadcrumb";
import {useRequestErrorMessage} from "../../../../base/hooks/useRequestErrorMessage";
import PageMeta from "../../../../base/components/MetaTags";
import {BUTTON_COLORS, BUTTON_STYLES} from "../../../../base/components/Button/appearance";
import {EVENTS_GROUP_LINKS, format, platforms, timeZone} from "../../config";
import {breadcrumbSchema} from "./config";
import {initialValues, validationSchema} from "./form";
import EventsService from "../../../../services/EventsService";
import {useLoading} from "../../../../base/hooks/useLoading";
import TeamsService from "../../../../services/TeamsService";
import FormikCheckbox from "../../../../base/components/FormikCheckbox";
import FilesService from "../../../../services/FilesServise";
import {EventInputs} from "../../components/EventInputs";
import LinkBack from "../../../../base/components/LinkBack";
import {getDateByTimezone} from "../../../../base/helpers/getDateByTimezone";

export const CreateEvent = () => {
    const history = useHistory();
    /**
     * @type {EventsService}
     */
    const eventsService = useService(EventsService);
    const teamsService = useService(TeamsService);
    const filesService = useService(FilesService);
    const [isLoading, {registerPromise}] = useLoading();
    const [types, setTypes] = useState([]);
    const [, {onCatchError}] = useRequestErrorMessage([]);

    const createEvent = async (value) => {
        try {
            if (value.image instanceof File) {
                const {data} = await registerPromise(filesService.uploadImage(value.image))
                value.image = data.id;
            }

            registerPromise(eventsService.createEvents({
                name: value.name,
                typeId: value.type,
                location: value.location || null,
                locationLink: value.link || null,
                imageId: value.image,
                startDate: getDateByTimezone(value.startDate, value.timezone),
                endDate: getDateByTimezone(value.endDate, value.timezone),
                description: value.description,
                saveAsTemplete: value.saveAsTemplete,
                saveAsDraft: value.saveAsDraft,
                timezone: value.timezone && value?.timezone.split(/\s+/).join(''),
                format: value.format,
                platform: value.format === "Virtual" ? value.platform : null
            })).then(() => {
                history.push(EVENTS_GROUP_LINKS.BASE);
            })
        } catch (err) {
            onCatchError(err)
        }
    }

    useEffect(() => {
        registerPromise(teamsService.getTeamTypes())
            .then(({data}) => {
                setTypes(data.map(type => ({
                    label: type.name,
                    value: type.id
                })));
            })
    }, []);

    return (
        <section className="page-content">
            <PageMeta title={'Create event'}/>
            <div className="content-header">
                <LinkBack defaultLink={EVENTS_GROUP_LINKS.BASE}>Events</LinkBack>
                <Breadcrumb schema={breadcrumbSchema}/>
            </div>
            <Container fluid className="p-0">
                <Card className="p-4 w-100">
                    <h4 className="card-title mb-4">Create event</h4>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            createEvent(values);
                        }}
                    >
                        {({errors, handleSubmit, dirty, setFieldValue, status, values}) => {
                            const invalid = !!Object.keys(errors).length

                            return (
                                <form className="form-horizontal inline-form">
                                    <EventInputs
                                        types={types}
                                        timeZone={timeZone}
                                        format={format}
                                        platform={platforms}
                                        values={values}
                                    />

                                    <FormikCheckbox
                                        label='Save as template'
                                        className="right-block"
                                        name='saveAsTemplete'
                                        id="saveAsTemplete"
                                    />
                                    <div>
                                        <Button
                                            color={BUTTON_COLORS.primary}
                                            type="button"
                                            block={BUTTON_STYLES.block}
                                            outline
                                            className="mt-4 w-auto"
                                            disabled={(dirty && (invalid || status?.errors)) || isLoading}
                                            onClick={() => {
                                                setFieldValue('saveAsDraft', true)
                                                handleSubmit()
                                            }}
                                        >
                                            Save as draft
                                        </Button>
                                        <Button
                                            color={BUTTON_COLORS.primary}
                                            type="button"
                                            block={BUTTON_STYLES.block}
                                            className="mt-4 ms-2 w-auto"
                                            onClick={handleSubmit}
                                        >
                                            Create Event
                                        </Button>
                                    </div>
                                </form>
                            )
                        }
                        }
                    </Formik>
                </Card>
            </Container>
        </section>
    );
};
