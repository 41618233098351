import React from 'react';
import InfoItem from "../../../../../teams/components/MainInfo/components/InfoItem";
import {formatDatetime} from "../../../../../../base/helpers/formatDatetime";

const DATETIME_FORMAT_PATTERN = "DD MMM yyyy hh:mm A"

const CurrentComment = ({comment}) => (
    <div className="user-info__info">
        <InfoItem title="Comment text" value={comment?.text}/>
        <InfoItem title="Date and time" value={formatDatetime(comment?.createdAt, DATETIME_FORMAT_PATTERN)}/>
    </div>
);

export default CurrentComment;