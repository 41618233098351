import React from "react";
import {EVENT_DETAILS_LINKS, EVENTS_GROUP_LINKS} from "../../config";
import {WaitingTeamsList} from "./components/WaitingTeamsList";
import {AcceptedTeamsList} from "./components/AcceptedTeamsList";
import {EVENTS_STATUS} from "../../../../base/constants/events";

export const breadcrumbSchema = (title) => [
    {
        title: 'Events',
        path: EVENTS_GROUP_LINKS.BASE
    },
    {
        title: title,
    }
]

// tabs config

const acceptedTeams = (status) => ({
    link: EVENT_DETAILS_LINKS.JOINED,
    label: 'List of joining teams',
    render: () => <AcceptedTeamsList status={status}/>
})

const getWaitingTeams = (status) => ({
    link: EVENT_DETAILS_LINKS.WAITING,
    label: 'List of waiting teams',
    render: () => <WaitingTeamsList status={status}/>
})

export const teamsListSchema = (status) => {
    if(status === EVENTS_STATUS.PAST) {
        return [acceptedTeams(status)]
    }

    return [getWaitingTeams(status), acceptedTeams(status)]
}

//pop-up config

export const ACTION_EVENT_TYPE = {
    DELETE: 'delete',
    CANCEL_CHANGES: 'cancelChanges',
    DECLINE_TEAM: 'declineTeam',
    DELETE_TEMPLATE: 'deleteTemplate',
};

export const popupSchema = {
    [ACTION_EVENT_TYPE.DELETE]: {
        isDanger: true,
        title: 'Delete event',
        body: 'Are you sure you want to delete this event?',
        labelCancel: 'No, Cancel',
        labelDone: 'Yes, Delete',
    },
    [ACTION_EVENT_TYPE.DELETE_TEMPLATE]: {
        isDanger: true,
        title: 'Delete event template',
        body: 'Are you sure you want to delete this event template?',
        labelCancel: 'No, Cancel',
        labelDone: 'Yes, Delete',
    },
    [ACTION_EVENT_TYPE.CANCEL_CHANGES]: {
        isDanger: false,
        title: 'Cancel',
        body: 'Are you sure you want to leave without changes being saved?',
        labelCancel: 'No, Cancel',
        labelDone: 'Yes, leave',
    },
    [ACTION_EVENT_TYPE.DECLINE_TEAM]: {
        isDanger: true,
        title: 'Decline',
        body: 'Are you sure you want to decline this join request?',
        labelCancel: 'No, Cancel',
        labelDone: 'Yes, decline',
    },
}

// form config

export const initialValues = {
    name: '',
    description: '',
    endDate: '',
    startDate: '',
    image: null,
    locationLink: '',
    location: null,
    type: undefined,
    saveAsTemplete: false,
    timezone: '',
    format: '',
    platform: ''
};
