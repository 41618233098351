import PropTypes from 'prop-types'
import React, {useCallback, useEffect} from "react"
import {connect} from "react-redux"
import {Link} from "react-router-dom"
import logo from "../../../assets/images/logo-light.svg"
import logoLightSvg from "../../../assets/images/logo-light.svg"
import logoDark from "../../../assets/images/logo.svg"
import {
    showRightSidebarAction,
    toggleLeftmenu,
    changeSidebarType,
} from "../../../store/actions"

import ProfileDropdown from "../ProfileDropdown/Index";

const Header = props => {

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

    function tToggle() {
        if (props.leftSideBarType === "default") {
            props.changeSidebarType("condensed")
        } else if (props.leftSideBarType === "condensed") {
            props.changeSidebarType("default")
        }
    }

    const toggleDefaultSideBar = useCallback(() => {
        if (props.leftSideBarType === "default" && isMobile) {
            props.changeSidebarType("condensed")
        }
    }, [props.leftSideBarType])

    useEffect(() => {
        window.addEventListener("click", toggleDefaultSideBar)

        return () => {
            window.removeEventListener("click", toggleDefaultSideBar)
        }
    }, [toggleDefaultSideBar])

    return (
        <React.Fragment>
            <header id="page-topbar">
                <div className="navbar-header">
                    <div className="d-flex">
                        <div className="navbar-brand-box">
                            <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22"/>
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="" height="17"/>
                </span>
                            </Link>

                            <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoLightSvg} alt="" height="22"/>
                </span>
                                <span className="logo-lg">
                  <img src={logoDark} alt="" />
                </span>
                            </Link>
                        </div>

                        <button
                            type="button"
                            onClick={() => {
                                tToggle()
                            }}
                            className="btn btn-sm px-3 font-size-16 header-item waves-effect"
                            id="vertical-menu-btn"
                        >
                            <i className="fa fa-fw fa-bars"/>
                        </button>

                    </div>
                    <div className="d-flex">
                        <ProfileDropdown user={props.user} />
                    </div>
                </div>
            </header>
        </React.Fragment>
    )
}

Header.propTypes = {
    changeSidebarType: PropTypes.func,
    leftMenu: PropTypes.any,
    leftSideBarType: PropTypes.any,
    showRightSidebar: PropTypes.any,
    showRightSidebarAction: PropTypes.func,
    t: PropTypes.any,
    toggleLeftmenu: PropTypes.func,
    user: PropTypes.object
}

const mapStatetoProps = state => {
    const {
        layoutType,
        showRightSidebar,
        leftMenu,
        leftSideBarType,
    } = state.Layout
    return {layoutType, showRightSidebar, leftMenu, leftSideBarType}
}

export default connect(mapStatetoProps, {
    showRightSidebarAction,
    toggleLeftmenu,
    changeSidebarType,
})(Header)
