import yup from './yup';
import {
    MAX_EVENT_DESCRIPTION,
    MAX_EVENT_NAME,
    MAX_YEAR_DATE_EVENT,
    MIN_EVENT_DESCRIPTION,
    MIN_EVENT_NAME,
} from "./lengthConstants";
import {LINK_PATTERN} from "../base/constants/patterns";
import {getUTCHours} from "../base/helpers/getUTCHours";
import {TIMEZONE} from "../base/constants/timezone";

const MAX_FILE_SIZE = 10485760;

yup.addMethod(yup.date, "maxDiffInYears", function (years, errorMessage) {
    return this.test(`test-card-type`, errorMessage, function (value) {
        const { path, createError } = this;
        const maxDate = new Date();
        maxDate.setFullYear(maxDate.getFullYear() + years);
        return (
            (value < maxDate) ||
            createError({ path, message: errorMessage })
        );
    });
});

export const EVENT_NAME = yup
    .string()
    .max(MAX_EVENT_NAME, `Name must be from ${MIN_EVENT_NAME} to ${MAX_EVENT_NAME} symbols`)
    .min(MIN_EVENT_NAME, `Name must be from ${MIN_EVENT_NAME} to ${MAX_EVENT_NAME} symbols`)
    .trim();

export const TYPE = yup
    .number()

export const LOCATION = yup.object().shape({
    latitude: yup.number(),
    longitude: yup.number(),
    address: yup.string(),
    placeId: yup.string(),
}).nullable().test('have-link-or-location', ' ', function (value) {
    return value?.address || this.parent.link
})

export const LINK = yup
    .string()
    .matches(LINK_PATTERN, 'Link is invalid')
    .trim()
    .nullable()
    .test('have-link-or-location', ' ', function (value) {console.log(value, this.parent.location?.address); return value || this.parent.location?.address });

export const IMAGE = yup
    .mixed()
    .test('max-size-image', 'The image size should not be larger than 10 Mb', (value) => {
        if (!value?.size) {
            return true
        }
        return value.size < MAX_FILE_SIZE
    })

export const START_DATE = yup
    .date()
    .when('timezone', {
        is: TIMEZONE.Hawaii.is,
        then: yup.date().test('date-is-future', 'Date must be in future', (value) => value > getUTCHours(TIMEZONE.Hawaii.time))
    })
    .when('timezone', {
        is: TIMEZONE.Alaska.is,
        then: yup.date().test('date-is-future', 'Date must be in future', (value) => value > getUTCHours(TIMEZONE.Alaska.time))
    })
    .when('timezone', {
        is: TIMEZONE.Pacific.is,
        then: yup.date().test('date-is-future', 'Date must be in future', (value) => value > getUTCHours(TIMEZONE.Pacific.time))
    })
    .when('timezone', {
        is: TIMEZONE.Mountain.is,
        then: yup.date().test('date-is-future', 'Date must be in future', (value) => value > getUTCHours(TIMEZONE.Mountain.time))
    })
    .when('timezone', {
        is: TIMEZONE.Central.is,
        then: yup.date().test('date-is-future', 'Date must be in future', (value) => value > getUTCHours(TIMEZONE.Central.time))
    })
    .when('timezone', {
        is: TIMEZONE.Eastern.is,
        then: yup.date().test('date-is-future', 'Date must be in future', (value) => value > getUTCHours(TIMEZONE.Eastern.time))
    })
    .maxDiffInYears(MAX_YEAR_DATE_EVENT, `Start date must be no later than ${MAX_YEAR_DATE_EVENT} years from now`)


export const END_DATE = yup
    .date()
    .when('timezone', {
        is: TIMEZONE.Hawaii.is,
        then: yup.date().test('date-is-future', 'Date must be in future', (value) => value > getUTCHours(TIMEZONE.Hawaii.time))
    })
    .when('timezone', {
        is: TIMEZONE.Alaska.is,
        then: yup.date().test('date-is-future', 'Date must be in future', (value) => value > getUTCHours(TIMEZONE.Alaska.time))
    })
    .when('timezone', {
        is: TIMEZONE.Pacific.is,
        then: yup.date().test('date-is-future', 'Date must be in future', (value) => value > getUTCHours(TIMEZONE.Pacific.time))
    })
    .when('timezone', {
        is: TIMEZONE.Mountain.is,
        then: yup.date().test('date-is-future', 'Date must be in future', (value) => value > getUTCHours(TIMEZONE.Mountain.time))
    })
    .when('timezone', {
        is: TIMEZONE.Central.is,
        then: yup.date().test('date-is-future', 'Date must be in future', (value) => value > getUTCHours(TIMEZONE.Central.time))
    })
    .when('timezone', {
        is: TIMEZONE.Eastern.is,
        then: yup.date().test('date-is-future', 'Date must be in future', (value) => value > getUTCHours(TIMEZONE.Eastern.time))
    })
    .maxDiffInYears(MAX_YEAR_DATE_EVENT, `End date must be no later than ${MAX_YEAR_DATE_EVENT} years from now`)
    .test('end-after-start', 'End date must be after start date', function (value) { return this.parent.startDate <= value })


export const DESCRIPTION = yup
    .string()
    .max(MAX_EVENT_DESCRIPTION, `Descriptions must be from ${MIN_EVENT_DESCRIPTION} to ${MAX_EVENT_DESCRIPTION} symbols`)
    .min(MIN_EVENT_DESCRIPTION, `Descriptions must be from ${MIN_EVENT_DESCRIPTION} to ${MAX_EVENT_DESCRIPTION} symbols`)
    .trim()

export const TIME_ZONE = yup
    .string()

export const FORMAT = yup
    .string()

export const PLATFORM = yup
    .string()

export const SAVE_AS_TEMPLATE = yup
    .bool()