import React from 'react'
import {formatDate} from "../../../../../../base/helpers/formatDate";

export default function TableStructure() {
    return [
        {
            dataField: "name",
            sort: false,
            headerFormatter: () => (<>Event name</>),
            formatter: (cellContent, event) => {
                return (<span>
                            {event.name}
                    </span>)
            }
        },
        {
            dataField: "type",
            sort: true,
            headerFormatter: () => (<>Event type</>),
            formatter: (cellContent, event) => {
                return (
                    <section className="d-flex flex-row align-items-center">
                        {event.type.name}
                    </section>
                )
            }
        },
        {
            headerFormatter: () => (<>Date of creation</>),
            dataField: "createdAt",
            sort: true,
            formatter: (cellContent) => (
                <>
                    {formatDate(cellContent)}
                </>
            )
        },
    ]
}