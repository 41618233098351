import React, {useCallback, useState} from "react";
import Table from "../../../../../../base/components/Table";
import {useService} from "../../../../../../base/hooks/useService";
import {useLocationQuery} from "../../../../../../base/hooks/useQueryString";
import {useTable} from '../../../../../../base/hooks/useTable';
import {Container} from "reactstrap";
import {SEARCH_QUERY, SORT_KEY, SORT_TYPE} from "../../../../../../base/constants/query";
import webSocketService from "../../../../../../services/WebSocketService";
import {SOCKET_LEAGUES} from "../../../../constants/socket";
import LeaguesTableHeader from "./TableHeader";
import tableStructure from "./tableStructure";
import LeaguesService from "../../../../../../services/LeaguesService";
import ToasterService from "../../../../../../services/ToastService";
import {useHistory} from "react-router-dom";

const LEAGUES_LIMIT = 15;

export const WaitingLeaguesList = ({waitingLeaguesParams}) => {
    const leaguesService = useService(LeaguesService);
    const toasterService = useService(ToasterService);
    const [update, setUpdate] = useState(false);
    const {params} = useLocationQuery();
    const history = useHistory();

    const [loading, pagination, data, handleTableChange, downloadNewData] = useTable({
        searchQueryParam: SEARCH_QUERY,
        sortTypeQueryParam: SORT_TYPE,
        sortByQueryParam: SORT_KEY,
        getNewDataService: leaguesService.getWaitingLeagues.bind(leaguesService),
        limit: LEAGUES_LIMIT,
        offset: params.offset || 0,
        defaultSort: waitingLeaguesParams,
    });

    const textPlaceholder = !params[SEARCH_QUERY] ? 'No requests yet' : 'No search results found';

    const updateData = async () => {
        setUpdate(true)
        await downloadNewData()
        setUpdate(false)
    }

    const updateOffset = (totalCount) => {
        if (params.offset === totalCount - 1 && params.offset) {
            history.push({
                search: `offset=${params.offset - LEAGUES_LIMIT}`
            });
        }
    }

    const acceptLeague = useCallback((id) => {
        leaguesService.acceptLeague(id)
            .then(({data}) => {
                toasterService.success(`League ${data.name} accepted`)
                downloadNewData();
                updateOffset(pagination.totalCount);
            })
    }, [pagination, params])

    const declineLeague = useCallback((data) => {
        const id = data.id;
        const body = {
            reasonText: data.inputText
        }
        leaguesService.declineLeague(id, body).then(() => {
            downloadNewData();
            updateOffset(pagination.totalCount);
        });
    }, [pagination, params])

    webSocketService.onMessage({
        [SOCKET_LEAGUES.LEAGUE_ACCEPTED]: updateData,
        [SOCKET_LEAGUES.LEAGUE_DECLINED]: updateData,
        [SOCKET_LEAGUES.NEW_CREATE_LEAGUE_REQUEST]: updateData,
        [SOCKET_LEAGUES.OWNER_DELETE_LEAGUE]: updateData,
        [SOCKET_LEAGUES.OWNER_EDIT_LEAGUE]: updateData,
    })

    return (
        <Container fluid className="p-0">
            <Table
                data={data}
                dataStructure={tableStructure(acceptLeague, declineLeague)}
                loading={!update && loading}
                HeaderComponent={LeaguesTableHeader}
                limit={LEAGUES_LIMIT}
                offset={params.offset || 0}
                totalCount={pagination.totalCount}
                handleTableChange={handleTableChange}
                searchField={SEARCH_QUERY}
                textPlaceholder={textPlaceholder}
            />
        </Container>
    );
};
