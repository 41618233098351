import React, {useState} from "react";
import Table from "../../../../base/components/Table";
import {useService} from "../../../../base/hooks/useService";
import {useLocationQuery} from "../../../../base/hooks/useQueryString";
import {useTable} from '../../../../base/hooks/useTable';
import tableStructure from "./tableStructure";
import UsersTableHeader from './TableHeader';
import {Container} from "reactstrap";
import {SEARCH_QUERY, SORT_KEY, SORT_TYPE} from "../../../../base/constants/query";
import AdminsService from "../../../../services/AdminsService";
import PageMeta from "../../../../base/components/MetaTags";
import webSocketService from "../../../../services/WebSocketService";
import {SOCKET_EVENTS} from "../../../events/constants/socket";
import {SOCKET_ADMINS} from "../../constants/socket";

const ADMINS_LIMIT = 15;

export const AdminsList = ({defaultParams}) => {
    /**
     * @type {AdminService}
     */
    const adminsService = useService(AdminsService);
    const [update, setUpdate] = useState(false);
    const {params} = useLocationQuery();

    const [loading, pagination, data, handleTableChange, downloadNewData] = useTable({
        searchQueryParam: SEARCH_QUERY,
        sortTypeQueryParam: SORT_TYPE,
        sortByQueryParam: SORT_KEY,
        getNewDataService: adminsService.getAdmins.bind(adminsService),
        limit: ADMINS_LIMIT,
        defaultSort: defaultParams,
        offset: params.offset || 0
    })

    const textPlaceholder = !params[SEARCH_QUERY] ? 'There are no admins' : 'No search results found';

    const updateData = async () => {
        setUpdate(true)
        await downloadNewData()
        setUpdate(false)
    }

    webSocketService.onMessage({
        [SOCKET_ADMINS.ADMIN_PASSWORD_CREATED]: updateData,
        [SOCKET_ADMINS.ADMIN_CHANGE_EMAIL]: updateData,
    })

    return (
        <section className="page-content">
            <PageMeta title={'Admins'}/>
            <div className="content-header">
                <h2 className="content-header__title">Admins</h2>
            </div>
            <Container fluid className="p-0">
                <Table
                    data={data}
                    dataStructure={tableStructure}
                    loading={!update && loading}
                    HeaderComponent={UsersTableHeader}
                    limit={ADMINS_LIMIT}
                    offset={params.offset || 0}
                    totalCount={pagination.totalCount}
                    handleTableChange={handleTableChange}
                    searchField={SEARCH_QUERY}
                    textPlaceholder={textPlaceholder}
                />
            </Container>
        </section>
    );
};
