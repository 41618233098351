import React, {useEffect, useState} from "react";
import {useService} from "../../../../base/hooks/useService";
import {useLoading} from "../../../../base/hooks/useLoading";
import Breadcrumb from "../../../../base/components/Breadcrumb";
import {breadcrumbSchema} from "./config";
import {useHistory, useParams} from "react-router-dom";
import defaultAvatar from "../../../../assets/images/default-avatar.jpg"
import {useRequestErrorMessage} from "../../../../base/hooks/useRequestErrorMessage";
import PageMeta from "../../../../base/components/MetaTags";
import TeamsService from "../../../../services/TeamsService";
import {TEAMS_GROUP_LINKS} from "../../config";
import MainInfo from "../../components/MainInfo";
import {TEAMS_STATUS} from "../../../../base/constants/teams";
import {TableMembers} from "./TableMembers";
import LinkBack from "../../../../base/components/LinkBack";
import webSocketService from "../../../../services/WebSocketService";
import {SOCKET_TEAMS} from "../../constants/socket";
import ToastService from "../../../../services/ToastService";

const TeamAcceptInfo = () => {
    /**
     * @type {TeamsService}
     */
    const teamsService = useService(TeamsService);
    const toastService = useService(ToastService);
    const [team, setTeam] = useState(null);
    const [isLoading, {registerPromise}] = useLoading();
    const [, {onCatchError}] = useRequestErrorMessage([]);
    const {id} = useParams();
    const isDeleted = team && team.status === TEAMS_STATUS.DELETED;
    const history = useHistory();

    const loadTeam = () => {
        registerPromise(teamsService.getTeamById(id))
            .then(({data}) => {
                setTeam(data)
            })
            .catch(onCatchError);
    };

    const deleteTeam = (data) => {
        const body = {
            reasonText: data.inputText
        }
        registerPromise(teamsService.deleteTeam(id, body))
            .then(({data}) => {
                toastService.success(data.message)
                history.push(TEAMS_GROUP_LINKS.ACCEPTED_TEAMS)
            })
            .catch(onCatchError);
    }

    useEffect(() => {
        loadTeam();
    }, [registerPromise, teamsService]);

    const update = (updatedTeam) => {
        if (updatedTeam.id === Number(id)) {
            setTeam(updatedTeam)
        }
    }

    webSocketService.onMessage({
        [SOCKET_TEAMS.OWNER_EDIT_TEAM]: update,
        [SOCKET_TEAMS.OWNER_DELETE_TEAM]: update,
    })

    return (
        <div className="page-content">
            <PageMeta title={'Team details'}/>
            {team && (
                <>
                    <div className="content-header">
                        <LinkBack defaultLink={TEAMS_GROUP_LINKS.ACCEPTED_TEAMS}>Teams</LinkBack>
                        <Breadcrumb schema={breadcrumbSchema}/>
                    </div>

                    <MainInfo
                        avatar={team.logo?.originalPath || defaultAvatar}
                        name={team?.name}
                        owner={team?.owner}
                        status={team?.status}
                        description={team?.description}
                        type={team?.type}
                        isDeleted={isDeleted}
                        id={team?.id}
                        deleteTeam={deleteTeam}
                    />

                    {!isLoading && <TableMembers
                        isDelete={isDeleted}
                    />}
                </>
            )}
        </div>
    );
};

export default TeamAcceptInfo;
