export const getInterval = (callback = () => {}, timeout = 0) => {
    return {
        timerId: null,
        start() {
            this.timerId = setInterval(callback, timeout)
        },
        stop() {
            clearInterval(this.timerId)
            this.timerId = null
        }
    }
}