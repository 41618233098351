import React, {useCallback} from "react";
import { Switch, Route } from "react-router-dom";

import {AdminsList} from "./pages/AdminsList/";
import {CreateAdmin} from "./pages/CreateAdmin";
import AdminInfo from "./pages/AdminInfo";

import {ADMINS_GROUP_LINKS} from "./config";
import {useStateParams} from "../../base/hooks/useStateParams";
import {SORT_KEY, SORT_TYPE} from "../../base/constants/query";
const DEFAULT_PARAMS = {[SORT_KEY]: 'registeredAt', [SORT_TYPE]: 'desc'}

const Admins = () => {
    const [adminListParams] = useStateParams(ADMINS_GROUP_LINKS.BASE, DEFAULT_PARAMS);

    const AdminsTable = useCallback(() => <AdminsList defaultParams={adminListParams} />, [adminListParams])

    return (
        <Switch>
            <Route
                path={ADMINS_GROUP_LINKS.BASE}
                render={AdminsTable}
                exact
            />
            <Route
                path={ADMINS_GROUP_LINKS.LINK_ADMIN_CREATE}
                component={CreateAdmin}
                exact
            />
            <Route
                path={ADMINS_GROUP_LINKS.LINK_ADMIN_DETAILS}
                component={AdminInfo}
                exact
            />
        </Switch>
    );
};

export default Admins                                                                                                                                                     ;
