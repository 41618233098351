import axios from "axios";

import { refreshSessionInterceptor } from "./interceptors/refreshSessionInterceptor";
import { mapResponse } from "./interceptors/mapResponse";
import { setAuthToken } from "./interceptors/setAuthToken";
import { getErrors } from "../base/helpers/getErrors";
import { SPECIAL_ERROR_CODES } from "../base/constants/errorCodes";


const interceptorsResponse = [
    mapResponse,
    refreshSessionInterceptor
];

const interceptorsRequest = [
    setAuthToken,
];

const SPECIAL_ERRORS_ARRAY = [
    SPECIAL_ERROR_CODES.ASSIGN_OWNER
]

const headers = {
    "Access-Control-Allow-Origin": "*",
};

class Http {
    constructor() {
        this.instance = Http.createInstance({
            baseURL: `${Http.api.common}/${Http.versions.v1}${this.getURLScope()}`,
            headers
        });
        this.instance.interceptors.request.use(...interceptorsRequest);
        this.instance.interceptors.response.use(...interceptorsResponse);
    }

    static $displayName = "Http";

    static createInstance() {
        return axios.create.apply(axios, arguments);
    }

    getURLScope() {
        return ""
    }

    get() {
        return this.instance.get.apply(this.instance, arguments)
            .catch(this.errorApiHandler);
    }

    patch() {
        return this.instance.patch.apply(this.instance, arguments)
            .catch(this.errorApiHandler);
    }

    put() {
        return this.instance.put.apply(this.instance, arguments)
            .catch(this.errorApiHandler);
    }

    post() {
        return this.instance.post.apply(this.instance, arguments)
            .catch(this.errorApiHandler);
    }

    delete() {
        return this.instance.delete.apply(this.instance, arguments)
            .catch(this.errorApiHandler);
    }

    errorApiHandler(error) {
        if (SPECIAL_ERRORS_ARRAY.includes(error.code)) {
            throw error;
        }
        const messages = error.errors.map(err => err.message)
        throw getErrors(...messages);
    }
}

Http.api = {
    common: process.env.REACT_APP_BASE_URL
};

Http.versions = {
    v1: process.env.REACT_APP_API_VERSION
};

export default Http;
