import React, { useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import SimpleBar from "simplebar-react";

const ACTIVE_LINK = 'waves-effect active mm-active';
const ARROW = 'has-arrow';
const CONDENSED = 'condensed';

export default function generateSidebarLinks(linksArray, sidebarType) {
    const location = useLocation();
    const currentLocation = useMemo(() => location.pathname, [location]);

    const isCondensed = sidebarType === CONDENSED;

    const [activeLink, setActiveLink] = useState(currentLocation);

    function getLinkClassName(link, arrow = '') {
        if (currentLocation.includes(link)) {
            return `${ACTIVE_LINK} ${arrow}`;
        }
        return `waves-effect ${arrow}`;
    }

    function isOpenedCollapse(link) {
        if (link?.length > activeLink?.length) {
            return;
        }
        return activeLink?.startsWith(link)
    }

    function getLinksFloor(children, parentLink) {
        return children.map(child => {
            const Icon = child.icon
            const hasChilds = !!child.children?.length;

            const toggleCollapse = (link) => {
                setActiveLink(prevState => {
                    return prevState === link ? parentLink : link;
                });
            }

            return (
                <li key={child.linkTo}>
                    <Link
                        to={child.linkTo}
                        className={hasChilds ? getLinkClassName(child.linkTo, ARROW) : getLinkClassName(child.linkTo)}
                        onClick={() => {
                            if (hasChilds) {
                                toggleCollapse(child.linkTo)
                            } else {
                                setActiveLink(child.linkTo)
                            }
                        }}
                    >
                        <i className="bx" >
                            {<Icon className="icon" />}
                        </i>
                        <span>{child.label}</span>
                    </Link>
                    {
                        (hasChilds && (!isCondensed ?
                            <Collapse isOpen={isOpenedCollapse(child.linkTo)}>
                                <ul className="sub-menu">
                                    {getLinksFloor(child.children, child.linkTo)}
                                </ul>
                            </Collapse>
                            :
                            <ul className="sub-menu">
                                {getLinksFloor(child.children, child.linkTo)}
                            </ul>
                        ))}
                </li >
            )
        })
    }

    return (
        <SimpleBar className="h-100">
            <div id="sidebar-menu">
                <ul className="metismenu list-unstyled custom-sidebar-menu">
                    {getLinksFloor(linksArray)}
                </ul>
            </div>
        </SimpleBar>
    )
}

generateSidebarLinks.propTypes = {
    linksArray: PropTypes.array,
}