import React from "react";
import {useService} from "../../../../base/hooks/useService";
import {Card, Container} from "reactstrap";
import AdminsService from "../../../../services/AdminsService";
import ToasterService from "../../../../services/ToastService";
import FormikInput from "../../../../base/components/FormikInput";
import Button from "../../../../base/components/Button";
import {Formik} from "formik";
import {Link, useHistory} from "react-router-dom";
import {getPermissionFlag} from "../../helpers/getPermissionFlag";
import FormikCheckbox from "../../../../base/components/FormikCheckbox";
import Breadcrumb from "../../../../base/components/Breadcrumb";
import {useRequestErrorMessage} from "../../../../base/hooks/useRequestErrorMessage";
import PageMeta from "../../../../base/components/MetaTags";
import {BUTTON_COLORS, BUTTON_STYLES} from "../../../../base/components/Button/appearance";
import {ADMINS_GROUP_LINKS} from "../../config";
import {PERMISSIONS} from "../../../../base/constants/admins";
import {breadcrumbSchema} from "./config";
import {initialValues, validationSchema} from "./form";

export const CreateAdmin = () => {
    const history = useHistory();
    /**
     * @type {AdminService}
     */
    const adminsService = useService(AdminsService);
    const toastService = useService(ToasterService);
    const [alertText, {onCatchError}] = useRequestErrorMessage([]);

    const createAdmin = ({email, firstName, lastName, permissions}) => {
        const permissionsFlag = getPermissionFlag(permissions)
        adminsService.createAdmin({email, lastName, firstName, permissionsFlag})
            .then(() => {
                toastService.success('Invitation link was sent');
                history.push(ADMINS_GROUP_LINKS.BASE);
            })
            .catch(onCatchError);
    }

    return (
        <section className="page-content">
            <PageMeta title={'Create admin'} />
            <div className="content-header">
                <Link to={ADMINS_GROUP_LINKS.BASE}><h3 className="content-header__title link-back">Admins</h3></Link>
                <Breadcrumb schema={breadcrumbSchema}/>
            </div>
            <Container className='col-12 .col-sm-10 col-md-8 col-lg-6 col-xl-4 m-0 p-0'>

                <Card className="p-4 ">
                    <h4 className="card-title mb-4">List of joined teams</h4>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            createAdmin(values);
                        }}
                    >
                        {({errors, handleSubmit}) => (
                            <form className="form-horizontal" onSubmit={handleSubmit}>
                                <FormikInput
                                    id="email"
                                    name="email"
                                    label="Email address"
                                    containerClassName="mb-3"
                                    placeholder="Enter email address"
                                    type="email"
                                />

                                <FormikInput
                                    id="firstName"
                                    label="First name"
                                    name="firstName"
                                    containerClassName="mb-3"
                                    type="text"
                                    placeholder="Enter admin first name"
                                />

                                <FormikInput
                                    id="lastName"
                                    label="Last name"
                                    name="lastName"
                                    containerClassName="mb-3"
                                    type="text"
                                    placeholder="Enter admin last name"
                                />

                                <label className="mb-3 mt-4">Select what tabs will be accessible</label>

                                {Object.keys(PERMISSIONS).map((key) => {
                                    return (
                                        <FormikCheckbox
                                            key={key}
                                            label={PERMISSIONS[key].label}
                                            name="permissions"
                                            value={PERMISSIONS[key].value.toString()}
                                            className="mb-3"
                                            id={key}
                                        />
                                    )}
                                )}

                                <Button
                                    color={BUTTON_COLORS.primary}
                                    type="submit"
                                    block={BUTTON_STYLES.block}
                                    className="mt-4"
                                    disabled={!!Object.keys(errors).length}
                                >
                                    Create Admin
                                </Button>

                                <div className="mt-4 text-center">
                                </div>
                            </form>
                        )}
                    </Formik>
                </Card>
            </Container>
        </section>
    );
};
