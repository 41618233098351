import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import defaultAvatar from "../../../../assets/images/default-avatar.jpg"
import InfoItem from "../../../teams/components/MainInfo/components/InfoItem";
import Popup from "../../../../base/components/Popups/Popup";
import Button from "../../../../base/components/Button";
import {ACTION_TYPE, popupSchema, postsSchema, reportsHistoryTitle} from "./config";
import {useService} from "../../../../base/hooks/useService";
import ReportsService from "../../../../services/ReportsService";
import {useLoading} from "../../../../base/hooks/useLoading";
import {useRequestErrorMessage} from "../../../../base/hooks/useRequestErrorMessage";
import {Link, useHistory, useLocation, useParams} from "react-router-dom";
import {USERS_STATUS} from "../../../../base/constants/users";
import {formatDatetime} from "../../../../base/helpers/formatDatetime";
import {USERS_GROUP_LINKS} from "../../../users/config";
import {TEAMS_GROUP_LINKS} from "../../../teams/config";
import {ITEM_POST_TYPE_LABEL} from "../../../../base/constants/reports";
import ToastService from "../../../../services/ToastService";
import {REPORTS_GROUP_LINKS} from "../../config";
import {LEAGUES_GROUP_LINKS} from "../../../leagues/config";
import {EVENTS_GROUP_LINKS} from "../../../events/config";

const DATETIME_FORMAT_PATTERN = "DD MMM yyyy hh:mm A"

export default function TableHeader() {
    /**
     * @type {ReportsService}
     */
    const reportsService = useService(ReportsService);
    /**
     * @type {ToastService}
     */
    const toastService = useService(ToastService);
    const [post, setPost] = useState(null);
    const {id} = useParams();
    const location = useLocation();
    const history = useHistory();
    const isDeleted = post?.status === USERS_STATUS.DELETED;
    const [activeLink, setActiveLink] = useState(postsSchema()[0].title);

    const [, {registerPromise}] = useLoading();
    const [, {onCatchError}] = useRequestErrorMessage();

    const deletePost = () => {
        registerPromise(reportsService.deleteReportedPostById(id))
            .then(({data}) => {
                toastService.success(data.message)
                history.push(REPORTS_GROUP_LINKS.POST_REPORTS)
            })
            .catch(onCatchError)
    }

    const loadPost = () => {
        registerPromise(reportsService.getReportedPostById(id))
            .then(({data}) => setPost(data))
            .catch(onCatchError)
    }

    useEffect(() => {
        loadPost();
    }, [id]);

    return <section className="d-flex flex-column report-details mb-3 py-3 justify-content-between w-100">
        <div className="d-flex justify-content-between align-items-center mb-3">
            <h4 className="card-title">Reports on a post</h4>
            <div className="d-flex">
                {!isDeleted && <Popup
                    {...popupSchema[ACTION_TYPE.DELETE]}
                    action={deletePost}
                >
                    <Button
                        className="edit-user-btn ms-3"
                        color='danger'>
                        Delete post
                    </Button>
                </Popup>}
            </div>
        </div>
        <div className="d-flex pb-4 border-bottom ">
            <img
                className="avatar avatar-xl me-3"
                src={post?.creator?.avatar?.originalPath || defaultAvatar}
            />
            <div className="user-info__info">
                <InfoItem
                    title="Creator name"
                    value={<Link
                        to={{
                            pathname: `${USERS_GROUP_LINKS.BASE}/${post?.creator?.id}`,
                            state: {from: location.pathname}
                        }}
                        className="text-dark">
                        {post?.creator?.firstName} {post?.creator?.lastName}
                    </Link>}/>
                <InfoItem title="Post type" value={post?.postType}/>
                <InfoItem title="Creation date and time"
                          value={formatDatetime(post?.createdAt, DATETIME_FORMAT_PATTERN)}/>

                {post?.team && <>
                    <InfoItem
                        title="Team post status"
                        className="badge-soft-secondary p-1 rounded-start rounded-end"
                        value={ITEM_POST_TYPE_LABEL[post?.type]}/>
                    <InfoItem
                        title="Team name"
                        value={<Link
                            to={{
                                pathname: `${TEAMS_GROUP_LINKS.ACCEPTED_TEAMS}/${post?.team?.id}`,
                                state: {from: location.pathname}
                            }}
                            className="text-dark">
                            {post?.team?.name}
                        </Link>}/>
                    <InfoItem title="Team logo"
                              value={post?.team?.logo && <img
                                  className="avatar-sm"
                                  src={post.team.logo.compactPath}/>
                              }
                    />
                </>}
                {post?.league && <>
                    <InfoItem
                        title="League post status"
                        className="badge-soft-secondary p-1 rounded-start rounded-end"
                        value={ITEM_POST_TYPE_LABEL[post?.type]}/>
                    <InfoItem
                        title="League name"
                        value={<Link
                            to={{
                                pathname: `${LEAGUES_GROUP_LINKS.ACCEPTED_LEAGUES}/${post?.team?.id}`,
                                state: {from: location.pathname}
                            }}
                            className="text-dark">
                            {post?.league?.name}
                        </Link>}/>
                    <InfoItem title="League logo"
                              value={post?.league?.logo && <img
                                  className="avatar-sm"
                                  src={post.league.logo.compactPath}/>
                              }
                    />
                </>}
                {post?.event && <>
                    <InfoItem
                        title="Event post status"
                        className="badge-soft-secondary p-1 rounded-start rounded-end"
                        value={ITEM_POST_TYPE_LABEL[post?.type]}/>
                    <InfoItem
                        title="Event name"
                        value={<Link
                            to={{
                                pathname: `${EVENTS_GROUP_LINKS.BASE}/${post?.event?.id}`,
                                state: {from: location.pathname}
                            }}
                            className="text-dark">
                            {post?.event?.name}
                        </Link>}/>
                    <InfoItem title="Event logo"
                              value={post?.event?.logo && <img
                                  className="avatar-sm"
                                  src={post.event.logo.compactPath}/>
                              }
                    />
                </>}
            </div>
        </div>
        <div className="nav-tabs-container d-flex my-3">
            {postsSchema(post).map(item => (
                    <div onClick={() => setActiveLink(item.title)} key={item.title}
                         className={`nav-tabs__link ${activeLink === item.title && "nav-tabs__link_active"} 
                                         ${reportsHistoryTitle === item?.title && !post?.history?.length && "disabled-link"}`}
                         style={{cursor: "pointer"}}
                    >
                        {item.title}
                    </div>
                )
            )}
        </div>
        <div>
            {postsSchema(post).map(item => (
                    <div key={item.title}>
                        {activeLink === item.title && item.page}
                    </div>
                )
            )}
        </div>
    </section>
}

TableHeader.propTypes = {
    toolkitProps: PropTypes.object,
}
