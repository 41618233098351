import React, {useState} from "react";
import {useService} from "../../../../../../base/hooks/useService";
import EventsService from "../../../../../../services/EventsService";
import {useTable} from "../../../../../../base/hooks/useTable";
import {SEARCH_QUERY, SORT_KEY, SORT_TYPE} from "../../../../../../base/constants/query";
import {useLocationQuery} from "../../../../../../base/hooks/useQueryString";
import {Card} from "reactstrap";
import Table from "../../../../../../base/components/Table";
import tableStructure from "./tableStructure";
import {EVENTS_GROUP_LINKS} from "../../../../config";
import webSocketService from "../../../../../../services/WebSocketService";
import {SOCKET_EVENTS} from "../../../../constants/socket";

const EVENTS_LIMIT = 15;

export const TemplateEventsList = ({eventTemplateParams}) => {
    /**
     * @type {EventsService}
     */
    const eventsService = useService(EventsService);
    const [update, setUpdate] = useState(false);
    const { params } = useLocationQuery();

    const [loading, pagination, data, handleTableChange, downloadNewData] = useTable({
        searchQueryParam: SEARCH_QUERY,
        sortTypeQueryParam: SORT_TYPE,
        sortByQueryParam: SORT_KEY,
        getNewDataService: eventsService.getEventTemplate.bind(eventsService),
        limit: EVENTS_LIMIT,
        defaultSort: eventTemplateParams,
        offset: params.offset || 0
    })

    const textPlaceholder = !params[SEARCH_QUERY] ? 'There are no events' : 'No search results found';

    const updateData = async () => {
        setUpdate(true)
        await downloadNewData()
        setUpdate(false)
    }

    webSocketService.onMessage({
        [SOCKET_EVENTS.EVENT_TEMPLATE_CREATED]: updateData
    })

    return <Card fluid className="pt-3">
            <Table
                data={data}
                dataStructure={tableStructure}
                loading={!update && loading}
                limit={EVENTS_LIMIT}
                offset={params.offset || 0}
                totalCount={pagination.totalCount}
                handleTableChange={handleTableChange}
                searchField={SEARCH_QUERY}
                textPlaceholder={textPlaceholder}
                withLocation={false}
                rowClickPath={EVENTS_GROUP_LINKS.BASE}
            />
        </Card>
};
