import React from "react";
import PropTypes from "prop-types";

const InfoItem = ({ title, value, className = '' }) => {
  return (
      <>
          <span className="title">{title}</span>
          <div><span className={`value ${className}`}>{value ? value : '-'}</span></div>
      </>
  );
};

InfoItem.propTypes = {
    title: PropTypes.string,
    value: PropTypes.any,
    className: PropTypes.string,
};

export default InfoItem;
