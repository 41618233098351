import React from 'react';
import InfoItem from "../../../../../teams/components/MainInfo/components/InfoItem";
import {formatDatetime} from "../../../../../../base/helpers/formatDatetime";

const DATETIME_FORMAT_PATTERN = "DD MMM yyyy hh:mm A"

const CommentHistory = ({comment}) => (
    <div className={`reports-history ${comment?.history.length > 5 && "reports-history-scroll"}`}>
        {comment?.history.map((item) => (
            <div key={item.id}>
                <div className="user-info__info pt-3 px-3">
                    <InfoItem title="Comment text" value={item?.text}/>
                    <InfoItem title="Date" value={formatDatetime(item?.createdAt, DATETIME_FORMAT_PATTERN)}/>
                </div>
                <hr className="mx-3"/>
            </div>
        ))}
    </div>
);

export default CommentHistory;