import yup from "../../../../../../validation/yup";
import {USER_PASSWORD} from "../../../../../../validation/user";

export const validationSchema = yup.object().shape({
    oldPassword: USER_PASSWORD.required(),
    password: USER_PASSWORD.required(),
    confirmPassword: USER_PASSWORD.required().test(
        'passwords-match',
        'Confirm new password does not match with new password',
        function (value) {
            return this.parent.password === value;
        }
    ),
});

export const initialValues = {
    oldPassword: "",
    password: "",
    confirmPassword: "",
};
