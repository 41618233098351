import React, {useCallback, useEffect, useState} from "react";
import Table from "../../../../base/components/Table";
import {useService} from "../../../../base/hooks/useService";
import {useLocationQuery} from "../../../../base/hooks/useQueryString";
import {useTable} from '../../../../base/hooks/useTable';
import {tableStructure} from "./tableStructure";
import {Container} from "reactstrap";
import {SEARCH_QUERY, SORT_KEY, SORT_TYPE} from "../../../../base/constants/query";
import ReportsService from "../../../../services/ReportsService";
import {Link, useHistory, useLocation, useParams} from "react-router-dom";
import PageMeta from "../../../../base/components/MetaTags";
import {REPORTS_GROUP_LINKS} from "../../config";
import {breadcrumbSchema, commentsSchema, reportsHistoryTitle, ACTIONS_TYPE, popupData} from "./config";
import Breadcrumb from "../../../../base/components/Breadcrumb";
import {getNewReportIds} from "../../helpers/getNewReportIds";
import LinkBack from "../../../../base/components/LinkBack";
import webSocketService from "../../../../services/WebSocketService";
import {SOCKET_REPORTS} from "../../constants/socket";
import Button from "../../../../base/components/Button";
import defaultAvatar from "../../../../assets/images/default-avatar.jpg";
import InfoItem from "../../../teams/components/MainInfo/components/InfoItem";
import {USERS_GROUP_LINKS} from "../../../users/config";
import {useLoading} from "../../../../base/hooks/useLoading";
import {useRequestErrorMessage} from "../../../../base/hooks/useRequestErrorMessage";
import Popup from "../../../../base/components/Popups/Popup";
import {USERS_STATUS} from "../../../../base/constants/users";
import ToasterService from "../../../../services/ToastService";

const REPORTS_LIMIT = 15;

export const CommentReportedDetails = () => {
    /**
     * @type {ReportsService}
     */
    const reportsService = useService(ReportsService);
    const toastService = useService(ToasterService);
    const [update, setUpdate] = useState(false);
    const {params} = useLocationQuery();
    const {id} = useParams();
    const location = useLocation();
    const history = useHistory();
    const [comment, setСomment] = useState(null);
    const [, {registerPromise}] = useLoading();
    const [, {onCatchError}] = useRequestErrorMessage();
    const [activeLink, setActiveLink] = useState(commentsSchema()[0].title);
    const isDeleted = comment?.status === USERS_STATUS.DELETED;

    const loadComment = () => {
        registerPromise(reportsService.getReportedCommentById(id))
            .then(({data}) => setСomment(data))
            .catch(onCatchError)
    }

    useEffect(() => {
        loadComment();
    }, [id]);

    const getReports = ((id) => {
        return (params) => reportsService.getReportsByCommentId(id, params)
    })(id);

    const [loading, pagination, data, handleTableChange, downloadNewData] = useTable({
        searchQueryParam: SEARCH_QUERY,
        sortTypeQueryParam: SORT_TYPE,
        sortByQueryParam: SORT_KEY,
        getNewDataService: getReports,
        limit: REPORTS_LIMIT,
    });

    const textPlaceholder = !params[SEARCH_QUERY] ? 'There are no reports' : 'No search results found';

    const deleteComment = () => {
        registerPromise(reportsService.deleteReportedCommentById(id))
            .then(({ data }) => {
                toastService.success(data.message)
                history.push(REPORTS_GROUP_LINKS.COMMENT_REPORTS)
            })
            .catch(onCatchError)
    }

    useEffect(async () => {
        if (data?.length) {
            const newReportIds = getNewReportIds(data)
            if (newReportIds.length) {
                await reportsService.changeStatusReports(getNewReportIds(data))
            }
        }
    }, [data])

    const tableStructureWithLocation = useCallback(tableStructure(location), [location])

    const updateData = async () => {
        setUpdate(true)
        await downloadNewData()
        setUpdate(false)
    }
    webSocketService.onMessage({
        [SOCKET_REPORTS.NEW_COMMENT_REPORT]: updateData,
        [SOCKET_REPORTS.ADMIN_VIEWED_REPORTS]: updateData,
    })

    return (
        <section className="page-content">
            <PageMeta title={'Reported comments'}/>
            <div className="content-header">
                <LinkBack defaultLink={REPORTS_GROUP_LINKS.COMMENT_REPORTS}>Reports</LinkBack>
                <Breadcrumb schema={breadcrumbSchema}/>
            </div>
            <Container fluid className="px-3 rounded-1 report-comment-details-container">
                <section className="d-flex flex-column report-details mb-3 py-3 justify-content-between w-100">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h4 className="card-title">Reported comment details</h4>
                        <div className="d-flex">
                            {!isDeleted && <Popup
                                {...popupData[ACTIONS_TYPE.DELETE]}
                                action={deleteComment}
                            >
                                <Button
                                    className="edit-user-btn ms-3"
                                    color='danger'>
                                    Delete comment
                                </Button>
                            </Popup>}
                        </div>
                    </div>
                    <div className="d-flex pb-4 border-bottom ">
                        <img
                            className="avatar avatar-xl me-3"
                            src={comment?.creator?.avatar?.originalPath || defaultAvatar}
                        />
                        <div className="user-info__info">
                            <InfoItem
                                title="Creator name"
                                value={<Link
                                    to={{
                                        pathname: `${USERS_GROUP_LINKS.BASE}/${comment?.creator?.id}`,
                                        state: {from: location.pathname}
                                    }}
                                    className="text-dark">
                                    {comment?.creator?.firstName} {comment?.creator?.lastName}
                                </Link>}/>
                        </div>
                    </div>
                    <div>
                        <div className="nav-tabs-container d-flex my-3">
                            {commentsSchema().map(item => (
                                    <div onClick={() => setActiveLink(item.title)} key={item.title}
                                         className={`nav-tabs__link ${activeLink === item.title && "nav-tabs__link_active"} 
                                         ${reportsHistoryTitle === item?.title && !comment?.history?.length && "disabled-link"}`}
                                         style={{cursor: "pointer"}}
                                    >
                                        {item.title}
                                    </div>
                                )
                            )}
                        </div>
                        <div>
                            {commentsSchema(comment).map(item => (
                                    <div key={item.title}>
                                        {activeLink === item.title && item.page}
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </section>
                <Table
                    data={data}
                    dataStructure={tableStructureWithLocation}
                    loading={!update && loading}
                    limit={REPORTS_LIMIT}
                    offset={params.offset || 0}
                    totalCount={pagination.totalCount}
                    handleTableChange={handleTableChange}
                    searchField={SEARCH_QUERY}
                    textPlaceholder={textPlaceholder}
                    isRowClick={false}
                    bodyPaddings={false}
                />
            </Container>
        </section>
    );
};
