import React, {useCallback} from "react";
import {CardBody, Card} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import FormikInput from "../../../../base/components/FormikInput";
import { Formik } from "formik";
import profile from "../../assets/profile-img.png";
import AuthCardContainer from "../../components/AuthCardContainer";
import Logo from "../../components/Logo";
import AuthHeader from "../../components/Header";

import {useService} from "../../../../base/hooks/useService";
import AuthService from "../../../../services/AuthService";
import {useRequestErrorMessage} from "../../../../base/hooks/useRequestErrorMessage";
import {useLoading} from "../../../../base/hooks/useLoading";
import StorageService from "../../../../services/StorageService";
import SessionStorage from "../../../../services/SessionStorage";
import {KEY_USER} from "../../../../base/constants/storage";
import Button from "../../../../base/components/Button/index";
import {BUTTON_COLORS, BUTTON_STYLES} from "../../../../base/components/Button/appearance";
import {initialValues, validationSchema} from "./form";
import {AUTH_GROUP_LINKS} from "../../config";

const Login = () => {
    /**
     * @type {AuthService}
     */
    const authService = useService(AuthService);
    /**
     * @type {StorageService}
     */
    const storage = useService(StorageService);
    /**
     * @type {SessionStorage}
     */
    const storageSession = useService(SessionStorage);

    const history = useHistory();

    const [alertText, {onCatchError}] = useRequestErrorMessage([]);
    const [isLoading, {registerPromise}] = useLoading();

    const loginUser = useCallback((values) => {
        registerPromise(authService.login(values))
            .then(({user, token}) => {
                storage.set(KEY_USER, user);
                storageSession.setSession(token);
                history.push(AUTH_GROUP_LINKS.EXTERNAL.AFTER_LOGIN);
            })
            .catch(onCatchError)
    }, [registerPromise, authService, storageSession, storage, history]);

    return (
        <AuthCardContainer metaText="Login">
            <Card className="overflow-hidden">
                <AuthHeader
                    headerText="Sign in"
                    icon={profile}
                />

                <CardBody className="pt-0">
                    <Logo />

                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            loginUser(values);
                        }}
                    >
                        {({ errors, handleSubmit }) => (
                            <form className="form-horizontal p-2" onSubmit={handleSubmit}>
                                <FormikInput
                                    id="email"
                                    name="email"
                                    label="Email"
                                    containerClassName="mb-3"
                                    placeholder="Enter email"
                                    type="email"
                                />

                                <FormikInput
                                    id="password"
                                    label="Password"
                                    name="password"
                                    containerClassName="mb-3"
                                    type="password"
                                    placeholder="Enter password"
                                />

                                <Button
                                    color={BUTTON_COLORS.primary}
                                    type="submit"
                                    block={BUTTON_STYLES.block}
                                    className="mt-3"
                                    disabled={isLoading || !!Object.keys(errors).length}
                                >
                                    Log In
                                </Button>

                                <div className="mt-4 text-center">
                                    <Link
                                        to={AUTH_GROUP_LINKS.LINK_FORGOT_PASSWORD}
                                        className="text-muted"
                                    >
                                        <i className="mdi mdi-lock me-1 font-size-16" />
                                        Forgot your password?
                                    </Link>
                                </div>
                            </form>
                        )}
                    </Formik>
                </CardBody>
            </Card>
        </AuthCardContainer>
    );
};

export default Login;
