import React, {useState} from "react";
import Table from "../../../../../base/components/Table/index";
import { useService } from "../../../../../base/hooks/useService";
import { useLocationQuery } from "../../../../../base/hooks/useQueryString";
import { useTable } from '../../../../../base/hooks/useTable';
import { Container } from "reactstrap";
import {SEARCH_QUERY, SORT_KEY, SORT_TYPE} from "../../../../../base/constants/query";
import {useHistory, useLocation, useParams} from "react-router-dom";
import ToasterService from "../../../../../services/ToastService";
import {useRequestErrorMessage} from "../../../../../base/hooks/useRequestErrorMessage";
import webSocketService from "../../../../../services/WebSocketService";
import {USERS_GROUP_LINKS} from "../../../../users/config";
import LeaguesService from "../../../../../services/LeaguesService";
import {SOCKET_LEAGUES} from "../../../constants/socket";
import {LeaguesTableStructure} from "./tableStructure";
import MemberLeagueTableHeader from "./TableHeader";

const MEMBER_LIMIT = 15;

export const TableMembers = ({isDelete}) => {
    const leaguesService = useService(LeaguesService);
    const toasterService = useService(ToasterService);
    const { params } = useLocationQuery();
    const {id} = useParams();
    const [, {onCatchError}] = useRequestErrorMessage();
    const [update, setUpdate] = useState(false);
    const history = useHistory();
    const location = useLocation();

    const getLeagueMember = ((id) => {
        return (params) => {
            return leaguesService.getLeagueMember.call(leaguesService, id, params)
        }
    })(id);

    const [loading, pagination, data, handleTableChange, downloadNewData] = useTable({
        searchQueryParam: SEARCH_QUERY,
        sortTypeQueryParam: SORT_TYPE,
        sortByQueryParam: SORT_KEY,
        getNewDataService: getLeagueMember,
        limit: MEMBER_LIMIT,
        offset: params.offset || 0
    });

    const deleteMember = (memberId) => {
        leaguesService.deleteMemberById(id, memberId)
            .then(({data}) => {
                toasterService.success(data.message)
                downloadNewData()
            })
            .catch(onCatchError)
    }

    const redirectToMemberDetails = (memberId) => {
        history.push({
            pathname: `${USERS_GROUP_LINKS.BASE}/${memberId}`,
            state: {
                from: location.pathname,
            }
        })
    }

    const assignLeagueOwner = (memberId) => {
        leaguesService.changeLeagueOwner(id, memberId)
            .then(({data}) => {
                toasterService.success(data.message)
                downloadNewData()
            })
            .catch(onCatchError)
    }

    const textPlaceholder = !params[SEARCH_QUERY] ? 'There are no league members yet' : 'No search results found';

    const updateData = async () => {
        setUpdate(true)
        await downloadNewData()
        setUpdate(false)
    }

    webSocketService.addHandlers({
        [SOCKET_LEAGUES.LEAGUE_MEMBER_DELETED]: updateData,
        [SOCKET_LEAGUES.LEAGUE_OWNER_CHANGED]: updateData,
        [SOCKET_LEAGUES.EDIT_LEAGUE_MEMBER_DESCRIPTION]: updateData,
        [SOCKET_LEAGUES.LEAGUE_MEMBER_CHANGE_ROLE]: updateData,
        [SOCKET_LEAGUES.NEW_CREATE_LEAGUE_REQUEST]: updateData,
        [SOCKET_LEAGUES.ACCEPT_LEAGUE_JOIN_REQUEST]: updateData,
        [SOCKET_LEAGUES.DECLINE_LEAGUE_JOIN_REQUEST]: updateData,
        [SOCKET_LEAGUES.MEMBER_LEAVE_LEAGUE]: updateData,
        [SOCKET_LEAGUES.OWNER_LEAGUE_MEMBER_DELETED]: updateData,
        [SOCKET_LEAGUES.LEAGUE_ADMIN_LEAVE_LEAGUE]: updateData,
        [SOCKET_LEAGUES.ADMIN_LEAGUE_OWNER_CHANGED]: updateData,
        [SOCKET_LEAGUES.OWNER_LEAGUE_OWNER_CHANGED]: updateData,
    })

    return (
        <Container fluid className="w-100 p-0">
            <Table
                data={data}
                dataStructure={LeaguesTableStructure(deleteMember, assignLeagueOwner, redirectToMemberDetails, isDelete, data)}
                loading={!update && loading}
                HeaderComponent={MemberLeagueTableHeader}
                limit={MEMBER_LIMIT}
                offset={params.offset || 0}
                totalCount={pagination.totalCount}
                handleTableChange={handleTableChange}
                searchField={SEARCH_QUERY}
                textPlaceholder={textPlaceholder}
                withLocation={false}
                rowClickPath={USERS_GROUP_LINKS.BASE}
                locationIndex='user'
                subIndex='id'
                rowClasses='cursor-pointer'
            />
        </Container>
    );
};
