import React from 'react';
import PropTypes from "prop-types";

export default function Placeholder({ text = "NO DATA", image }) {
    return (
        <section className="table-placeholder">
            <span className="table-placeholder__title">{text}</span>
            {image && <img src={image} alt="" className="table-placeholder__image" height="50" width="50" />}
        </section>
    );
}

Placeholder.propTypes = {
    text: PropTypes.string,
}