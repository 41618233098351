import React from 'react'
import PropTypes from 'prop-types';
import {
    BreadcrumbItem as BreadcrumbItemReact,
    Breadcrumb as BreadcrumbReact
} from 'reactstrap';
import {Link} from "react-router-dom";

function BreadcrumbItem({link}) {
    return (
        <BreadcrumbItemReact className='link breadcrumb-link mb-0'>
            {link.path
                ? <Link to={link.path}>
                    {link.title}
                </Link>
                : link.title
            }
        </BreadcrumbItemReact>
    )
}

export default function Breadcrumb({schema = []}) {
    return (
        <BreadcrumbReact className="breadcrumb-custom">
            {schema.map((link) => (
                <BreadcrumbItem key={link.title} link={link}/>
            ))}
        </BreadcrumbReact>
    );
}

Breadcrumb.propTypes = {
    path: PropTypes.array,
}