import React, {useMemo, useRef, useState} from "react";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";
import Controls from "./components/Controls";
import {getInterval} from "../../helpers/getInterval";
import {START_PROGRESS_VALUE, FINISH_PROGRESS_VALUE} from "./constansts"

const SEEK_STEP = 0.1
const SEEK_INTERVAL = 500

const Player = ({url}) => {
    const [playerConfig, setPlayerConfig] = useState({
        playing: false,
        loaded: 0,
        loadedSeconds: 0,
        played: 0,
        playedSeconds: 0,
        muted: true,
        seeking: false,
    });

    const refPlayer = useRef();

    const togglePlay = () => {
        setPlayerConfig({...playerConfig, playing: !playerConfig.playing})
    }

    const toggleMute = () => {
        setPlayerConfig({...playerConfig, muted: !playerConfig.muted})
    }

    const handleProgress = (value) => {
        if (!playerConfig.seeking) {
            setPlayerConfig({...playerConfig, ...value})
        }
    }

    const handleProgressControls = (value) => {
        setPlayerConfig({...playerConfig, played: parseFloat(value) })
    }

    const handleStartSeek = () => {
        setPlayerConfig({...playerConfig, seeking: true })
    }

    const handleFinishSeek = () => {
        setPlayerConfig({...playerConfig, seeking: false})
        refPlayer.current.seekTo(playerConfig.played)
    }

    const rewindInterval = useMemo(() =>
        getInterval(() => {
            setPlayerConfig(prevState => ({
                ...prevState,
                played: (prevState.played - SEEK_STEP <= START_PROGRESS_VALUE)
                    ? START_PROGRESS_VALUE
                    : prevState.played - SEEK_STEP
            }))
        }, SEEK_INTERVAL), [])

    const fastForwardInterval = useMemo(() =>
        getInterval(() => {
            setPlayerConfig(prevState => ({
                ...prevState,
                played: (prevState.played + SEEK_STEP >= FINISH_PROGRESS_VALUE)
                    ? FINISH_PROGRESS_VALUE
                    : prevState.played + SEEK_STEP
            }))
        }, SEEK_INTERVAL), [])

    const handleStartRewind = () => {
        setPlayerConfig({...playerConfig, seeking: true, playing: false })
        rewindInterval.start()
    }

    const handleFinishRewind = () => {
        setPlayerConfig({...playerConfig, seeking: false, playing: true })
        rewindInterval.stop()
        refPlayer.current.seekTo(playerConfig.played)
    }

    const handleStartFastForward = () => {
        setPlayerConfig({...playerConfig, seeking: true, playing: false })
        fastForwardInterval.start()
    }

    const handleFinishFastForward = () => {
        setPlayerConfig({...playerConfig, seeking: false, playing: true })
        fastForwardInterval.stop()
        refPlayer.current.seekTo(playerConfig.played)
    }

    return <div className="w-100 h-100 d-flex justify-content-center align-items-center position-relative">
        <ReactPlayer
            {...playerConfig}
            url={url}
            onProgress={handleProgress}
            width="100%"
            height="90vh"
            ref={refPlayer}
        />

        <Controls
            {...playerConfig}
            togglePlay={togglePlay}
            toggleMute={toggleMute}
            onChangeProgress={handleProgressControls}
            onSeekStart={handleStartSeek}
            onSeekFinish={handleFinishSeek}
            onStartRewind={handleStartRewind}
            onFinishRewind={handleFinishRewind}
            onStartFastForward={handleStartFastForward}
            onFinishFastForward={handleFinishFastForward}
        />
    </div>
};

Player.propTypes = {
  url: PropTypes.string,
};

export default Player;
