import {CustomModal, ModalBody, ModalHeader} from "../../features/CustomModal";
import {useCallback, useMemo, useState} from "react";

const Modal = CustomModal;
Modal.Header = ModalHeader;
Modal.Body = ModalBody;

/**
 *
 * @returns {{open: ((function(): void)), close: (*|(function(): void)), isOpen: boolean, Modal: CustomModal, getModalProps: (*|(function(): {isOpen: boolean, container: string, onClose: (*|(function(): void))}))}}
 */
export const useModal = () => {
    const [isOpen, updateIsOpen] = useState(false);
    const close = useCallback(() => updateIsOpen(false), []);
    const open = useCallback(() => {
        updateIsOpen(true)}, []);
    const toggle = useCallback(() => {
        updateIsOpen(!isOpen)}, [isOpen]);

    const getModalProps = useMemo(() => {
        return {
            isOpen,
            close,
            toggle,
        }
    }, [isOpen, close]);

    return {toggle ,open, close, isOpen, Modal, getModalProps}
};
