import React, {useEffect, useCallback} from "react";
import {useHistory} from "react-router-dom";
import {CardBody, Card} from "reactstrap";
import {Formik} from "formik";

import profile from "../../assets/profile-img.png";

import FormikInput from "../../../../base/components/FormikInput";
import {useLocationQuery} from "../../../../base/hooks/useQueryString";
import {useService} from "../../../../base/hooks/useService";

import AuthCardContainer from "../../components/AuthCardContainer";
import Logo from "../../components/Logo";
import AuthHeader from "../../components/Header";

import AuthService from "../../../../services/AuthService";

import {validationSchema, initialValues} from "./form";
import {useLoading} from "../../../../base/hooks/useLoading";
import {AUTH_GROUP_LINKS} from "../../config";
import {addQueryParams} from "../../../../base/helpers/addQueryParams";
import {useRequestErrorMessage} from "../../../../base/hooks/useRequestErrorMessage";

export default function ResetPassword() {
    const [isLoading, {registerPromise}] = useLoading();
    const [alertText, {onCatchError}] = useRequestErrorMessage([]);

    /**
     * @type {AuthService}
     */
    const authService = useService(AuthService);
    /**
     * @type {ToasterService}
     */

    const history = useHistory();

    const {params: {token}} = useLocationQuery();

    const resetPassword = (value) => {
        registerPromise(authService.resetPassword({
            ...value,
            token,
        }))
            .then(() => {
                history.push(AUTH_GROUP_LINKS.LINK_LOGIN);
            })
            .catch(onCatchError);
    };

    const checkLinkRelevance = useCallback(() => {
        registerPromise(authService.checkLinkRelevance({isReset: true, token})
            .catch(() => {
                history.push(addQueryParams(AUTH_GROUP_LINKS.RESULT_SEND, {
                    danger: true,
                    linkToLogin: true,
                    linkToForgotPass: true,
                }))
            }))
    }, [token, registerPromise]);

    useEffect(() => {
        checkLinkRelevance();
    }, [checkLinkRelevance]);

    return (
        <AuthCardContainer metaText="Reset password">
            <Card className="overflow-hidden">
                <AuthHeader headerText="Create new password" icon={profile}/>

                <CardBody className="pt-0">
                    <Logo/>

                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            resetPassword(values);
                        }}
                    >
                        {({errors, handleSubmit}) => (
                            <form className="form-horizontal p-2" onSubmit={handleSubmit}>
                                <FormikInput
                                    id="password"
                                    label="New password"
                                    name="password"
                                    containerClassName="mb-3"
                                    type="password"
                                    placeholder="Enter password"
                                />

                                <FormikInput
                                    id="repeatPassword"
                                    label="Repeat new password"
                                    name="confirmPassword"
                                    containerClassName="mb-3"
                                    type="password"
                                    placeholder="Repeat new password"
                                />
                                <button
                                    className="btn btn-primary btn-block waves-effect waves-light mt-3 w-100"
                                    type="submit"
                                    disabled={isLoading || Object.keys(errors).length}
                                >
                                    Save
                                </button>
                            </form>
                        )}
                    </Formik>
                </CardBody>
            </Card>
        </AuthCardContainer>
    );
}
