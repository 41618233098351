import Http from "./Http";

class EventsService extends Http {
  static $displayName = "EventsService";

  async getEvents(params) {
    return await this.get("/admin-events", { params });
  }

  async getEventById(id) {
    return await this.get(`/admin-events/${id}`);
  }

  async createEvents(value) {
    return await this.post("/admin-events", value);
  }

  async getAcceptedTeam(id, params) {
    return await this.get(`/admin-events/${id}/requests/accepted`, { params })
  }

  async getWaitingTeam(id, params) {
    return await this.get(`/admin-events/${id}/requests/waiting`, { params })
  }

  async deleteEvents(id) {
    return await this.delete(`/admin-events/${id}`)
  }

  async editEvents(id, value) {
    return await this.put(`/admin-events/${id}`, value)
  }

  async changeSuspendedStatus(id) {
    return await this.patch(`/admin-events/${id}`)
  }

  async acceptTeam(id, requestId) {
    return await this.patch(`/admin-events/${id}/requests/${requestId}`)
  }

  async declineTeam(id, requestId) {
    return await this.delete(`/admin-events/${id}/requests/${requestId}`)
  }

  async changeDraftStatus(id, value) {
    return await this.post(`/admin-events/${id}`, value)
  }

  async cancelTeam(id, teamId) {
    return await this.delete(`/admin-events/${id}/teams/${teamId}`)
  }

  //template event

  async getEventTemplate(params) {
    return await this.get('/admin-events/templates', { params })
  }
}

export default EventsService;
