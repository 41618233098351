import React from 'react';
import PropTypes from 'prop-types';
import SearchBar from "../../../../../base/components/Table/tableSearch";

const MemberLeagueTableHeader = ({toolkitProps}) => (
    <section className="d-flex flex-row align-items-center m-0 py-3 justify-content-between w-100">
        <h4 className="card-title">League members</h4>
        <div className="search-box d-inline-block">
            <div className="position-relative">
                <SearchBar
                    {...toolkitProps.searchProps}
                />
                <i className="bx bx-search-alt search-icon"/>
            </div>
        </div>
    </section>
);

export default MemberLeagueTableHeader;

MemberLeagueTableHeader.propTypes = {
    toolkitProps: PropTypes.object,
};