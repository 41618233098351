

export const REPORT_LIST_LINKS = {
	USER: '/user',
	POST: '/post',
	COMMENT: '/comment'
};

const BASE = '/app/reports';
const USER_REPORTS = BASE.concat(REPORT_LIST_LINKS.USER);
const POST_REPORTS = BASE.concat(REPORT_LIST_LINKS.POST);
const COMMENT_REPORTS = BASE.concat(REPORT_LIST_LINKS.COMMENT);
const USER_REPORTS_DETAILS = USER_REPORTS.concat('/:id');
const POST_REPORTS_DETAILS = POST_REPORTS.concat('/:id');
const COMMENT_REPORTS_DETAILS = COMMENT_REPORTS.concat('/:id');

export const REPORTS_GROUP_LINKS = {
	BASE,
	USER_REPORTS,
	POST_REPORTS,
	COMMENT_REPORTS,
	USER_REPORTS_DETAILS,
	POST_REPORTS_DETAILS,
	COMMENT_REPORTS_DETAILS,
}