export const EVENTS_STATUS = {
    UPCOMING: 'Upcoming',
    PAST: 'Past',
    DRAFTS: 'Drafts',
    SUSPENDED: 'Suspended',
    CURRENT: 'Current',
};

export const EVENTS_STATUS_LABEL = {
    [EVENTS_STATUS.UPCOMING]: 'Upcoming',
    [EVENTS_STATUS.PAST]: 'Past',
    [EVENTS_STATUS.DRAFTS]: 'Drafts',
    [EVENTS_STATUS.SUSPENDED]: 'Suspended',
    [EVENTS_STATUS.CURRENT]: 'Current',
};