import {ADMINS_GROUP_LINKS} from "../../config";

export const breadcrumbSchema = [
    {
        title: 'Admins',
        path: ADMINS_GROUP_LINKS.BASE
    },
    {
        title: 'Create new admin',
    }
]