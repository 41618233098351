import {PERMISSIONS} from "../constants/admins";

export const getAdminPermissions = (permissionFlag) => {
    let accPermission = permissionFlag;
     return Object.values(PERMISSIONS)
        .reverse()
        .filter((permission) => {
            if (permission.value <= accPermission) {
                accPermission -= permission.value
                return true;
            }
            return false;
        })
        .map((permission) => permission.value.toString())
}