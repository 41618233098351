import React, {useCallback} from "react";
import FormikInput from "../../../base/components/FormikInput";
import FormikSelect from "../../../base/components/FormikSelect";
import GoogleMap from "../../../base/components/GoogleMap";
import FormikTextarea from "../../../base/components/FormikTextarea";
import {LocationInputs} from "./LocationInputs";
import {IMAGE_EXTENSIONS} from "../../../base/constants/extensions";

import FormikImageInput from "../../../base/components/FormikImageInput";
import PropTypes from "prop-types";
import {useField} from "formik";

const imageEventAcceptExtensions = Object.values(IMAGE_EXTENSIONS).join(',')

const VIRTUAL_FORMAT = "Virtual";

export const EventInputs = ({types = [], disabled = false, image, timeZone, format, platform, values}) => {
    const [, metaLink] = useField({ name: 'link' });
    const [, metaLocation] = useField({ name: 'location' });

    const isLinkLocationError = metaLink.error && metaLocation.error && metaLink.touched && metaLocation.touched

    return (
        <>
            <FormikInput
                id="name"
                name="name"
                label="Event name"
                containerClassName="mb-3 inline-input"
                placeholder="Enter name"
                type="text"
                disabled={disabled}
            />

            <FormikSelect
                id="type"
                label="Event type"
                name="type"
                containerClassName="mb-3 inline-input select"
                options={types}
                disabled={disabled}
            />

            <FormikTextarea
                id="description"
                name="description"
                label="Event description"
                containerClassName="mb-3 inline-input"
                placeholder="Enter description"
                disabled={disabled}
            />

            <label className={`mb-4 ${isLinkLocationError ? "text-danger" : ""}`}>Please provide a link or a location</label>

            <FormikInput
                id="link"
                name="link"
                label="Event link"
                containerClassName="mb-3 inline-input"
                placeholder="Enter link"
                type="text"
                disabled={disabled}
            />

            <GoogleMap>
                {useCallback((props) => <LocationInputs {...props} disabled={disabled}/>, [disabled])}
            </GoogleMap>

            <FormikImageInput
                id="image"
                name="image"
                label="Event picture"
                containerClassName="mb-3 inline-input"
                type="file"
                accept={imageEventAcceptExtensions}
                disabled={disabled}
                imageUrl={image}
            />
            <FormikInput
                id="startDate"
                name="startDate"
                label="Start date and time"
                containerClassName="mb-3 inline-input"
                placeholder="Select start date and time"
                type="datetime-local"
                disabled={disabled}
            />
            <FormikInput
                id="endDate"
                name="endDate"
                label="End date and time"
                containerClassName="mb-3 inline-input"
                placeholder="Select end date and time"
                type="datetime-local"
                disabled={disabled}
            />
            <FormikSelect
                id="timezone"
                label="Select time zone"
                name="timezone"
                containerClassName="mb-3 inline-input select"
                optionPlaceholder="Select time zone"
                options={timeZone}
                disabled={disabled}
            />
            <FormikSelect
                id="format"
                label="Event format"
                name="format"
                containerClassName="mb-3 inline-input select"
                optionPlaceholder="Select format"
                options={format}
                disabled={disabled}
            />
            <FormikSelect
                id="platform"
                label="Select platforms"
                name="platform"
                containerClassName="mb-3 inline-input select"
                optionPlaceholder="Select platforms"
                options={values.format === VIRTUAL_FORMAT ? platform : []}
                disabled={disabled || values.format !== VIRTUAL_FORMAT}
            />
        </>
    );
};

EventInputs.propTypes = {
    types: PropTypes.array,
    disabled: PropTypes.bool,
    image: PropTypes.string,
}
