import React  from "react";
import { Card, CardBody } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { Formik } from "formik";

import profile from "../../assets/profile-img.png";

import FormikInput from "../../../../base/components/FormikInput";
import { useService } from "../../../../base/hooks/useService";

import AuthCardContainer from "../../components/AuthCardContainer";
import Logo from "../../components/Logo";
import AuthHeader from "../../components/Header";

import AuthService from "../../../../services/AuthService";

import { initialValues, validationSchema } from "./form";
import {useLoading} from "../../../../base/hooks/useLoading";
import {AUTH_GROUP_LINKS} from "../../config";
import {addQueryParams} from "../../../../base/helpers/addQueryParams";

const ForgetPasswordPage = () => {
  const [isLoading, {registerPromise}] = useLoading();
  const history = useHistory();
  /**
   * @type {AuthService}
   */
  const authService = useService(AuthService);

  const sendEmailLink = (email) => {
    registerPromise(authService.sendRestorePasswordEmail(email))
      .then(() => {
          history.push(addQueryParams(AUTH_GROUP_LINKS.RESULT_SEND, {
              linkToLogin: true,
          }))
      });
  };

  return (
    <AuthCardContainer metaText="Forget password">
      <Card className="overflow-hidden">
        <AuthHeader headerText="Reset password" icon={profile} />

        <CardBody className="pt-0">
          <Logo />

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              sendEmailLink(values);
            }}
          >
            {({ errors, handleSubmit }) => (
              <form className="form-horizontal p-2" onSubmit={handleSubmit}>
                <FormikInput
                  id="email"
                  name="email"
                  label="Email address"
                  containerClassName="mb-3"
                  placeholder="Enter email address"
                  type="email"
                />
                <button
                  className="btn btn-primary btn-block w-100 waves-effect waves-light"
                  type="submit"
                  disabled={isLoading || Object.keys(errors).length}
                >
                  Reset password
                </button>
              </form>
            )}
          </Formik>
        </CardBody>
      </Card>

      <div className="mt-4 text-center">
          <Link
            to={AUTH_GROUP_LINKS.LINK_LOGIN}
            className="font-weight-medium text-primary"
          >
            Back to sign in
          </Link>
      </div>
    </AuthCardContainer>
  );
};

export default ForgetPasswordPage;
