import React, {useState} from "react";
import {Dropdown, DropdownMenu, DropdownToggle, Input, Label} from "reactstrap";
import Button from "./Button";
import PropTypes from "prop-types";

const Filter = ({onChange, onSubmit, onReset, schema, filterState, defaultValue}) => {
    const [isFilterOpen, setIsFilterOpen] = useState(false);

    const isChangeFilter = Object.keys(filterState).find((key) => (filterState[key] !== defaultValue[key]))

    const handleChange = (filterBy, element) => {
        const {value} = element;

        onChange({
            ...filterState,
            [filterBy]: value,
        });
    };

    const handleSubmit = () => {
        setIsFilterOpen(prevState => !prevState);
        onSubmit();
    };

    const handleReset = (event) => {
        event.stopPropagation();
        onChange(defaultValue)
        setIsFilterOpen(false)
        onReset();
    }

    return <Dropdown
        isOpen={isFilterOpen}
        toggle={() => setIsFilterOpen(prevState => !prevState)}
    >
        <DropdownToggle tag="div">
            <>
                {isChangeFilter &&
                    <span onClick={handleReset} className="mx-3 cursor-pointer link-primary">Clear filter</span>}
                <Button className="btn" color="primary" outline>
                    Filter
                    <i className="mdi mdi-chevron-down"/>
                </Button>
            </>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-right pre-scrollable">
            <section className="users-table--filters--dropdown">
                {schema.map((filter) => {
                    return <div key={filter.title}>
                        <span className="users-table--filters--dropdown__title">{filter.title}:</span>
                        <div className={filter.scroll && `users-table--filters--dropdown__scroll`}>
                            {filter.items.map((item) => {
                                    return <Label key={item.value}
                                                  className="users-table--filters--dropdown__item custom-radio">
                                        <Input
                                            type="radio"
                                            id={item.value}
                                            name={filter.name}
                                            onChange={(event) => {
                                                handleChange(filter.name, event.target)
                                            }}
                                            checked={filterState[filter.name]?.toString() === item.value.toString()}
                                            value={item.value}
                                            text={item.text}
                                        />
                                        <span className="radio-label">{item.text}</span>
                                    </Label>
                                }
                            )}
                        </div>
                    </div>
                })}
                <Button
                    color="primary"
                    outline
                    className="w-100 mt-4"
                    onClick={handleSubmit}
                >
                    Apply
                </Button>
            </section>
        </DropdownMenu>
    </Dropdown>;
};

export default Filter;

const schemaTypes = PropTypes.exact({
    title: PropTypes.string,
    name: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.exact({
        value: PropTypes.string,
        text: PropTypes.string,
    })),
})

Filter.propTypes = {
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    onReset: PropTypes.func,
    schema: PropTypes.arrayOf(schemaTypes),
    filterState: PropTypes.object,
    defaultValue: PropTypes.object
}
