import React from "react";
import {Card, CardBody} from "reactstrap";
import {Formik} from "formik";
import {initialValues, validationSchema} from "./form";
import FormikInput from "../../../../../../base/components/FormikInput";
import Button from "../../../../../../base/components/Button";
import {BUTTON_COLORS, BUTTON_STYLES} from "../../../../../../base/components/Button/appearance";
import {useService} from "../../../../../../base/hooks/useService";
import AdminsService from "../../../../../../services/AdminsService";
import ToasterService from "../../../../../../services/ToastService";
import {useLoading} from "../../../../../../base/hooks/useLoading";
import {useRequestErrorMessage} from "../../../../../../base/hooks/useRequestErrorMessage";

const ChangeEmailForm = () => {
    /**
     * @type {AdminsService}
     */
    const adminService = useService(AdminsService);
    /**
     * @type {ToasterService}
     */
    const toasterService = useService(ToasterService);
    const [isLoading, {registerPromise}] = useLoading();
    const [alertText, {onCatchError}] = useRequestErrorMessage([]);

    const changeEmail = (value) => {
        registerPromise(adminService.changeEmail(value))
            .then(({data}) => {
                toasterService.success(data.message)
            })
            .catch(onCatchError)
    }

    return (
        <Card className="overflow-hidden w-50 p-4">
            <h4 className="card-title mb-4">Change email address</h4>
            <CardBody className="p-0">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        changeEmail(values);
                    }}
                >
                    {({ errors, handleSubmit, handleReset }) => {
                        return(
                        <form className="form-horizontal" onSubmit={handleSubmit}>
                            <FormikInput
                                id="currentEmail"
                                name="currentEmail"
                                label="Current email address"
                                containerClassName="mb-3"
                                placeholder="Enter current email address"
                                type="email"
                            />

                            <FormikInput
                                id="currentPassword"
                                label="Password"
                                name="currentPassword"
                                containerClassName="mb-3"
                                type="password"
                                placeholder="Enter password"
                            />

                            <FormikInput
                                id="newEmail"
                                name="newEmail"
                                label="New email address"
                                containerClassName="mb-3"
                                placeholder="Enter new email address"
                                type="email"
                            />

                            <Button
                                color={BUTTON_COLORS.primary}
                                type="button"
                                outline
                                block={BUTTON_STYLES.block}
                                className="mt-3 me-3 w-auto"
                                onClick={handleReset}
                            >
                                Cancel
                            </Button>

                            <Button
                                color={BUTTON_COLORS.primary}
                                type="submit"
                                block={BUTTON_STYLES.block}
                                className="mt-3 w-auto"
                                disabled={!!Object.keys(errors).length}
                            >
                                Save
                            </Button>
                        </form>
                    )}}
                </Formik>
            </CardBody>
        </Card>
    );
}

export default ChangeEmailForm;
