import React, {useEffect, useState} from "react";
import Table from "../../../../../../base/components/Table";
import {useService} from "../../../../../../base/hooks/useService";
import {useLocationQuery} from "../../../../../../base/hooks/useQueryString";
import {useTable} from '../../../../../../base/hooks/useTable';
import tableStructure from "./tableStructure";
import UsersTableHeader from './TableHeader';
import {Container} from "reactstrap";
import {SEARCH_QUERY, SORT_KEY, SORT_TYPE} from "../../../../../../base/constants/query";
import webSocketService from "../../../../../../services/WebSocketService";
import {SOCKET_LEAGUES} from "../../../../constants/socket";
import LeaguesService from "../../../../../../services/LeaguesService";

const LEAGUES_LIMIT = 15;

export const AcceptedLeaguesList = ({acceptedLeaguesParams}) => {
    /**
     * @type {LeaguesService}
     */
    const leaguesService = useService(LeaguesService);
    const [update, setUpdate] = useState(false);
    const {params} = useLocationQuery();

    const [loading, pagination, data, handleTableChange, downloadNewData] = useTable({
        searchQueryParam: SEARCH_QUERY,
        sortTypeQueryParam: SORT_TYPE,
        sortByQueryParam: SORT_KEY,
        getNewDataService: leaguesService.getAcceptedLeagues.bind(leaguesService),
        limit: LEAGUES_LIMIT,
        defaultSort: acceptedLeaguesParams,
        offset: params.offset || 0
    });


    const textPlaceholder = !params[SEARCH_QUERY] ? 'There are no accepted leagues' : 'No search results found';

    const updateData = async () => {
        setUpdate(true)
        await downloadNewData()
        setUpdate(false)
    }

    webSocketService.onMessage({
        [SOCKET_LEAGUES.LEAGUE_ACCEPTED]: updateData,
        [SOCKET_LEAGUES.LEAGUE_OWNER_CHANGED]: updateData,
        [SOCKET_LEAGUES.OWNER_DELETE_LEAGUE]: updateData,
        [SOCKET_LEAGUES.OWNER_EDIT_LEAGUE]: updateData,
    })

    return (
        <Container fluid className="p-0">
            <Table
                data={data}
                dataStructure={tableStructure}
                loading={!update && loading}
                HeaderComponent={UsersTableHeader}
                limit={LEAGUES_LIMIT}
                offset={params.offset || 0}
                totalCount={pagination.totalCount}
                handleTableChange={handleTableChange}
                searchField={SEARCH_QUERY}
                textPlaceholder={textPlaceholder}
            />
        </Container>
    );
};
