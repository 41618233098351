import React, {useEffect} from "react";
import PropTypes from "prop-types";
import { Card} from "reactstrap";
import Button from "../Button";
import {ModalHeader} from "../../../features/CustomModal";
import {useModal} from "../../hooks/useModal";

const Popup = ({
                 isDanger,
                 title,
                 body,
                 labelCancel= 'No, cancel',
                 labelDone,
                 action,
                 children,
                 isOpen = false
}) => {
  const {Modal, open, getModalProps, close, toggle} = useModal();

  const clickComplete = () => {
    close()
    action()
  }

  useEffect(() => {
    if(isOpen) {
      open()
    } else {
      close()
    }
  }, [isOpen])

  return <>
    <div onClick={open}>
      {children}
    </div>
    <Modal {...getModalProps} toggle={toggle}>
      <ModalHeader onClose={close}>
        {title}
      </ModalHeader>
      <div className="m-0 p-0">
        <Card className="mb-0">
          <p className="p-3 mb-0">{body}</p>
          <menu className="modal-footer p-3 mb-0 mt-0">
            <Button color='primary' outline onClick={close}>{labelCancel}</Button>
            {labelDone && <Button color={isDanger ? 'danger' : 'primary'} onClick={clickComplete}>{labelDone}</Button>}
          </menu>
        </Card>
      </div>
    </Modal>
  </>;
};

Popup.defaultProps = {
  isDanger: false,
};

Popup.propTypes = {
  isDanger: PropTypes.bool,
  title: PropTypes.string,
  body: PropTypes.string,
  labelCancel: PropTypes.string,
  labelDone: PropTypes.string,
  action: PropTypes.func
};

export default Popup;
