import {useCallback, useState} from "react";
import {useService} from "./useService";
import ToasterService from "../../services/ToastService";

/**
 *
 *
 * @returns {[string, {onCatchError: any, update: any}]}
 */
export const useRequestErrorMessage = (initial = []) => {
    const [alert, update] = useState(initial);
    const toastService = useService(ToasterService);
    const onCatchError = useCallback(error => {
            update(error.messages);
            error.messages?.forEach((message) => toastService.error(message))
            return Promise.reject(error)
    }, []);

    return [alert, {onCatchError, update}]
};
