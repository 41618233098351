import React, {useCallback} from "react";
import {Route, Switch} from "react-router-dom";
import {EVENTS_GROUP_LINKS} from "./config";
import EventsList from "./pages/EventLists";
import {CreateEvent} from "./pages/CreateEvent";
import {EventDetails} from "./pages/EventDetails";
import {useStateParams} from "../../base/hooks/useStateParams";

const Events = () => {
    const [eventsParams] = useStateParams(EVENTS_GROUP_LINKS.BASE);
    const [eventTemplateParams] = useStateParams(EVENTS_GROUP_LINKS.EVENT_TEMPLATES);

    const EventTables = useCallback(() => <EventsList
        eventsParams={eventsParams}
        eventTemplateParams={eventTemplateParams}
    />, [eventsParams, eventTemplateParams])

  return (
      <Switch>
          <Route path={[EVENTS_GROUP_LINKS.BASE, EVENTS_GROUP_LINKS.EVENT_TEMPLATES]} render={EventTables} exact/>
          <Route path={EVENTS_GROUP_LINKS.LINK_EVENT_CREATE} component={CreateEvent} exact/>
          <Route path={EVENTS_GROUP_LINKS.EVENT_DETAILS} component={EventDetails} />
      </Switch>
  );
};

export default Events;
