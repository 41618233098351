import React, {useCallback} from "react";
import {Route, Switch} from "react-router-dom";
import {LEAGUES_GROUP_LINKS} from "./config";
import {SORT_KEY, SORT_TYPE} from "../../base/constants/query";
import {useStateParams} from "../../base/hooks/useStateParams";
import LeaguesList from "./pages/LeaguesLists";
import AcceptedLeaguesDetails from "./pages/AcceptedLeaguesDetails";
import WaitingLeaguesDetails from "./pages/WaitingLeaguesDetails";

const DEFAULT_PARAMS = {[SORT_KEY]: 'createdAt', [SORT_TYPE]: 'desc'};

const Leagues = () => {
    const [acceptedLeaguesParams] = useStateParams(LEAGUES_GROUP_LINKS.ACCEPTED_LEAGUES, DEFAULT_PARAMS);
    const [waitingLeaguesParams] = useStateParams(LEAGUES_GROUP_LINKS.WAITING_LEAGUES);

    const LeagueTables = useCallback(() => <LeaguesList
        acceptedLeaguesParams={acceptedLeaguesParams}
        waitingLeaguesParams={waitingLeaguesParams}
    />, [acceptedLeaguesParams, waitingLeaguesParams])

    return (
        <Switch>
            <Route
                path={LEAGUES_GROUP_LINKS.ACCEPTED_LEAGUES_DETAILS}
                component={AcceptedLeaguesDetails}
                exact
            />
            <Route
                path={LEAGUES_GROUP_LINKS.WAITING_LEAGUES_DETAILS}
                component={WaitingLeaguesDetails}
                exact
            />
            <Route
                path={LEAGUES_GROUP_LINKS.BASE}
                render={LeagueTables}
            />
        </Switch>
    );
};

export default Leagues;