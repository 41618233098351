import {AcceptedTeamsList} from "./components/AcceptedTeamsList";
import {WaitingTeamsList} from "./components/WaitingTeamsList";
import {TEAMS_LIST_LINK} from "../../config";

export const teamsListSchema = ({acceptedListParams, waitingListParams}) => [
    {
        link: TEAMS_LIST_LINK.ACCEPTED_TEAMS,
        label: 'Accepted teams',
        render: AcceptedTeamsList,
        props: {acceptedListParams}
    },
    {
        link: TEAMS_LIST_LINK.WAITING_TEAMS,
        label: 'Waiting Teams',
        render: WaitingTeamsList,
        props: {waitingListParams}
    }
]