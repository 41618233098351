import React from 'react'
import {parseQuery} from "../../../../../../base/hooks/useQueryString";
import Icon from '../../../../../../base/components/Icon';
import {SORT_KEY, SORT_TYPE} from "../../../../../../base/constants/query";
import {handleWithoutPropagation} from "../../../../../../base/helpers/handleWithoutPropagation";
import Button from "../../../../../../base/components/Button";
import NestedNotificationPopup from "../../../../../../base/components/Popups/NestedNotificationPopup";
import {REASON_FIELD_MAX_LENGTH} from "./config";

const DESC_SORT = 'desc';
const DESC_SORT_ICON = 'sortDesc';
const ASC_SORT_ICON = 'sortAsc';
const SORT_DEFAULT_ICON = 'sortDefault';

const tableStructure = (acceptLeague, declineLeague) => (location, decorateText) => {
    const infoQuery = parseQuery(location.search);

    function getSortIcon(cellName) {
        if (infoQuery[SORT_KEY] === cellName) {
            return infoQuery[SORT_TYPE] === DESC_SORT ? ASC_SORT_ICON : DESC_SORT_ICON;
        }
        return SORT_DEFAULT_ICON;
    }

    return [
        {
            dataField: "name",
            sort: true,
            headerFormatter: () => (<>League name<Icon icon={getSortIcon('name')}/></>),
            formatter: (cellContent, league) => {
                return (
                    <section className="d-flex flex-row align-items-center">
                        <span className="tutee-list--list__name" {...decorateText(league.name)} />
                    </section>
                )
            }
        },
        {
            headerFormatter: () => (<>League owner name</>),
            dataField: "leagueOwner",
            sort: false,
            formatter: (cellContent, league) => (
                <>
                    <section className="d-flex flex-row align-items-center">
                        <span className="tutee-list--list__name" {...decorateText(league.ownerName)} />
                    </section>
                </>
            )
        },
        {
            sort: false,
            headerClasses: 'action-cell',
            headerFormatter: () => (<>Actions</>),
            formatter: (cellContent, league) => {
                return <div className="d-flex" onClick={handleWithoutPropagation()}>
                    <NestedNotificationPopup
                        action={declineLeague}
                        close={close}
                        title="Decline"
                        nestedTitle="Decline"
                        body="Are you sure you want to decline this request?"
                        nestedBody="Please provide reason for declining"
                        labelCancel="No, Cancel"
                        nestedLabelCancel="Close"
                        labelDone="Yes, Decline"
                        nestedLabelDone="Confirm"
                        inputLabel="Reason"
                        inputPlaceholder="Enter reason"
                        isDanger={true}
                        isNotification={true}
                        params={{id: league.id}}
                        maxLength={REASON_FIELD_MAX_LENGTH}
                    >
                        <Button
                            color="danger"
                            className="py-1 me-3"
                            outline>
                            Decline
                        </Button>
                    </NestedNotificationPopup>
                    <Button
                        color="success"
                        className="py-1"
                        onClick={() => { acceptLeague(league?.id) }}>
                        Accept
                    </Button>
                </div>
            },
        },
    ]
}

export default tableStructure;