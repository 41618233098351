import React, {useState} from "react";
import Table from "../../../../../base/components/Table/index";
import { useService } from "../../../../../base/hooks/useService";
import { useLocationQuery } from "../../../../../base/hooks/useQueryString";
import { useTable } from '../../../../../base/hooks/useTable';
import {TeamsTableStructure} from "./tableStructure";
import TeamsTableHeader from './TableHeader';
import { Container } from "reactstrap";
import {SEARCH_QUERY, SORT_KEY, SORT_TYPE} from "../../../../../base/constants/query";
import {useHistory, useLocation, useParams} from "react-router-dom";
import TeamsService from "../../../../../services/TeamsService";
import ToasterService from "../../../../../services/ToastService";
import {useRequestErrorMessage} from "../../../../../base/hooks/useRequestErrorMessage";
import webSocketService from "../../../../../services/WebSocketService";
import {SOCKET_TEAMS} from "../../../constants/socket";
import {USERS_GROUP_LINKS} from "../../../../users/config";

const MEMBER_LIMIT = 15;

export const TableMembers = ({isDelete}) => {
  /**
   * @type {TeamsService}
   */
  const teamsService = useService(TeamsService);
  const toasterService = useService(ToasterService);
  const { params } = useLocationQuery();
  const {id} = useParams();
  const [, {onCatchError}] = useRequestErrorMessage();
  const [update, setUpdate] = useState(false);
  const history = useHistory();
  const location = useLocation();

    const getTeamMember = ((id) => {
        return (params) => {
            return teamsService.getTeamMember.call(teamsService, id, params)
        }
    })(id);

    const [loading, pagination, data, handleTableChange, downloadNewData] = useTable({
        searchQueryParam: SEARCH_QUERY,
        sortTypeQueryParam: SORT_TYPE,
        sortByQueryParam: SORT_KEY,
        getNewDataService: getTeamMember,
        limit: MEMBER_LIMIT,
        offset: params.offset || 0
    });

  const deleteMember = (userId) => {
    teamsService.deleteMemberById(id, userId)
        .then(({data}) => {
          toasterService.success(data.message)
          downloadNewData()
        })
        .catch(onCatchError)
  }

  const redirectToUserDetails = (userId) => {
      history.push({
          pathname: `${USERS_GROUP_LINKS.BASE}/${userId}`,
          state: {
              from: location.pathname,
          }
      })
  }

  const assignTeamOwner = (userId) => {
    teamsService.changeTeamOwner(id, userId)
        .then(({data}) => {
          toasterService.success(data.message)
          downloadNewData()
        })
        .catch(onCatchError)
  }

  const textPlaceholder = !params[SEARCH_QUERY] ? 'There are no Members' : 'No search results found';

    const updateData = async () => {
        setUpdate(true)
        await downloadNewData()
        setUpdate(false)
    }

    webSocketService.addHandlers({
        [SOCKET_TEAMS.TEAM_MEMBER_DELETED]: updateData,
        [SOCKET_TEAMS.TEAM_OWNER_CHANGED]: updateData,
        [SOCKET_TEAMS.EDIT_TEAM_MEMBER_DESCRIPTION]: updateData,
        [SOCKET_TEAMS.TEAM_MEMBER_CHANGE_ROLE]: updateData,
        [SOCKET_TEAMS.NEW_CREATE_TEAM_REQUEST]: updateData,
        [SOCKET_TEAMS.ACCEPT_TEAM_JOIN_REQUEST]: updateData,
        [SOCKET_TEAMS.DECLINE_TEAM_JOIN_REQUEST]: updateData,
        [SOCKET_TEAMS.USER_LEAVE_TEAM]: updateData,
        [SOCKET_TEAMS.OWNER_TEAM_MEMBER_DELETED]: updateData
    })

    return (
        <Container fluid className="w-100 p-0">
            <Table
                data={data}
                dataStructure={TeamsTableStructure(deleteMember, assignTeamOwner, redirectToUserDetails, isDelete, data)}
                loading={!update && loading}
                HeaderComponent={TeamsTableHeader}
                limit={MEMBER_LIMIT}
                offset={params.offset || 0}
                totalCount={pagination.totalCount}
                handleTableChange={handleTableChange}
                searchField={SEARCH_QUERY}
                textPlaceholder={textPlaceholder}
                withLocation={false}
                rowClickPath={USERS_GROUP_LINKS.BASE}
            />
        </Container>
    );
};
