import React, {useCallback, useEffect, useState} from "react";
import {CardBody, Card, Alert} from "reactstrap";
import {Link, useHistory} from "react-router-dom";
import FormikInput from "../../../../base/components/FormikInput";
import {Formik} from "formik";
import profile from "../../assets/profile-img.png";
import AuthCardContainer from "../../components/AuthCardContainer";
import Logo from "../../components/Logo";
import AuthHeader from "../../components/Header";
import {useService} from "../../../../base/hooks/useService";
import AuthService from "../../../../services/AuthService";
import {useLoading} from "../../../../base/hooks/useLoading";
import Button from "../../../../base/components/Button/index";
import {BUTTON_COLORS, BUTTON_STYLES} from "../../../../base/components/Button/appearance";
import {initialValues, validationSchema} from "./form";
import {useLocationQuery} from "../../../../base/hooks/useQueryString";
import {AUTH_GROUP_LINKS} from "../../config";
import {addQueryParams} from "../../../../base/helpers/addQueryParams";
import {useRequestErrorMessage} from "../../../../base/hooks/useRequestErrorMessage";

const CreatePassword = () => {
    const [isLoading, {registerPromise}] = useLoading();
    /**
     * @type {AuthService}
     */
    const authService = useService(AuthService);
    const {params: {token}} = useLocationQuery();
    const [alertText, {onCatchError}] = useRequestErrorMessage([]);

    const createPassword = (value) => {
        registerPromise(authService.createPassword({
            ...value,
            token,
        }))
            .then(() => {
                history.push(AUTH_GROUP_LINKS.LINK_LOGIN);
            })
            .catch(onCatchError);
    };

    const history = useHistory();

    const checkLinkRelevance = useCallback(() => {
        registerPromise(authService.checkLinkRelevance({token})
            .catch(() => {
                history.push(addQueryParams(AUTH_GROUP_LINKS.RESULT_SEND, {
                    danger: true,
                    linkToLogin: false,
                }))
            }))
    }, [token, registerPromise]);

    useEffect(() => {
        checkLinkRelevance();
    }, [checkLinkRelevance]);

    return <AuthCardContainer metaText="Create password">
        <Card className="overflow-hidden">
            <AuthHeader
                headerText="Create password"
                icon={profile}
            />

            <CardBody className="pt-0">
                <Logo/>

                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        createPassword(values);
                    }}
                >
                    {({errors, handleSubmit}) => (
                        <form className="form-horizontal p-2" onSubmit={handleSubmit}>
                            <FormikInput
                                id="password"
                                label="Set password"
                                name="password"
                                containerClassName="mb-3"
                                type="password"
                                placeholder="Set password"
                            />

                            <FormikInput
                                id="password"
                                label="Repeat password"
                                name="confirmPassword"
                                containerClassName="mb-3"
                                type="password"
                                placeholder="Repeat password"
                            />

                            <Button
                                color={BUTTON_COLORS.primary}
                                type="submit"
                                block={BUTTON_STYLES.block}
                                className="mt-3"
                                disabled={isLoading || !!Object.keys(errors).length}
                            >
                                Save
                            </Button>
                        </form>
                    )}
                </Formik>
            </CardBody>
        </Card>
    </AuthCardContainer>

};

export default CreatePassword;
