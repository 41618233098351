import Http from "./Http";

class TeamsService extends Http {
  static $displayName = "TeamsService";

  async getAcceptedTeams(params) {
    return await this.get("/admin-teams/accepted", { params });
  }

  async getTeamById(id) {
    return await this.get(`/admin-teams/accepted/${id}`);
  }

  async deleteById(id) {
    return await this.delete(`/admin-teams/${id}`);
  }

  async getTeamTypes() {
    return await this.get("/system/types");
  }

  async getTeamMember(id, params) {
    return await this.get(`/admin-teams/${id}/users`, {params})
  }

  async deleteMemberById(id, userId) {
    return await this.delete(`/admin-teams/${id}/users/${userId}`)
  }

  async changeTeamOwner(id, userId) {
    return await this.put(`/admin-teams/${id}/users?id=${userId}`)
  }

  async getWaitingTeams(params) {
    return await this.get("/admin-teams/waiting", { params });
  }

  async getWaitingTeamById(id) {
    return await this.get(`/admin-teams/waiting/${id}`);
  }

  async acceptTeam(id) {
    return await this.patch(`/admin-teams/waiting/${id}`);
  }

  async declineTeam(id, data) {
    return await this.delete(`/admin-teams/waiting/${id}`, {data});
  }

  async deleteTeam(id, data) {
    return this.delete(`/admin-teams/${id}`, { data });
  }
}

export default TeamsService;
