import yup from "../../../../validation/yup";
import {USER_EMAIL} from "../../../../validation/user";

export const validationSchema = yup.object().shape({
  email: USER_EMAIL.required('Is required'),
});

export const initialValues = {
  email: "",
};
