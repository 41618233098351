import {LEAGUES_GROUP_LINKS} from "../../config";

export const breadcrumbSchema = [
    {
        title: 'Leagues',
        path: LEAGUES_GROUP_LINKS.BASE
    },
    {
        title: 'League info',
    }
];

export const ACTION_MEMBER_TYPE = {
    DELETE_MEMBER: 'deleteMember',
    DELETE_OWNER: 'deleteOwner',
    ASSIGN_LEAGUE_OWNER: 'assignLeagueOwner'
};

export const popupSchema = {

    [ACTION_MEMBER_TYPE.DELETE_MEMBER]: {
        isDanger: true,
        title: 'Delete member',
        body: 'Are you sure you want to delete this member from the league?',
        labelCancel: 'No, Cancel',
        labelDone: 'Yes, Delete',
    },
    [ACTION_MEMBER_TYPE.ASSIGN_LEAGUE_OWNER]: {
        isDanger: false,
        title: 'Assign member',
        body: 'Are you sure you want to assign this member as a league owner?',
        labelCancel: 'No, Cancel',
        labelDone: 'Yes, Assign',
    },
    [ACTION_MEMBER_TYPE.DELETE_OWNER]: {
        isDanger: true,
        title: 'Delete league owner',
        body: 'You are trying to delete a League Owner. Please, assign a new one first',
        labelCancel: 'OK',
    },
}

export const ACTION_TYPE = {
    DELETE: 'delete',
};

export const popupSchemaLeagueDelete = {
    [ACTION_TYPE.DELETE]: {
        isDanger: true,
        title: 'Delete league',
        body: 'Are you sure you want to delete this league?',
        labelCancel: 'No, Cancel',
        labelDone: 'Yes, Delete',
    },
}
