import React from "react";
import PropTypes from "prop-types";
import {Card} from "reactstrap";
import Button from "../../../../../../base/components/Button";

const BlockAdmin = ({ action, close }) => {
    return (
        <Card className="mb-0">
            <p className="p-3 mb-0">Are you sure you want to block this platform admin?</p>
            <menu className="modal-footer p-3 mb-0 mt-0">
                <Button color='primary' outline onClick={close}>No, Cancel</Button>
                <Button color="primary" onClick={action}>Yes, Block</Button>
            </menu>
        </Card>
    );
};

BlockAdmin.propTypes = {
    action: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
};

export default BlockAdmin;
