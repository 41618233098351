import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {AUTH_GROUP_LINKS} from "../../auth/config";
import {useService} from "../../../base/hooks/useService";
import ToasterService from "../../../services/ToastService";
import {useLocationQuery} from "../../../base/hooks/useQueryString";
import AdminsService from "../../../services/AdminsService";
import {useLoading} from "../../../base/hooks/useLoading";

import {addQueryParams} from "../../../base/helpers/addQueryParams";
import Spinner from "../../../base/components/Spiner";

const ConfirmEmail = () => {
	/**
	 * @type {ToasterService}
	 */
	const toasterService = useService(ToasterService);
	/**
	 * @type {AdminsService}
	 */
	const adminService = useService(AdminsService);
	const history = useHistory();
	const {params: {token}} = useLocationQuery();
	const [loading, {registerPromise}] = useLoading();

	const linkExpiredEmail = addQueryParams(AUTH_GROUP_LINKS.RESULT_SEND, {danger: true})

	const confirmEmail = (token) => {
		registerPromise(adminService.confirmEmail({
			token,
		}))
			.then(() => {
				toasterService.success('Email has been successfully changed');
				history.push(AUTH_GROUP_LINKS.LINK_LOG_OUT);
			})
			.catch(() => {
				history.push(linkExpiredEmail);
			});
	};

	useEffect(() => {
		confirmEmail(token);
	},[])

	return <Spinner />
};

export default ConfirmEmail;
