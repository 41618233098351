import Http from "./Http";

class LeaguesService extends Http {
    static $displayName = "LeaguesService";

    async getWaitingLeagues(params) {
        return await this.get("/admin-leagues/waiting", { params });
    }

    async getWaitingLeagueById(id) {
        return await this.get(`/admin-leagues/${id}`);
    }

    async getAcceptedLeagues(params) {
        return await this.get("/admin-leagues/accepted", { params });
    }

    async acceptLeague(id) {
        return await this.patch(`/admin-leagues/waiting/${id}`);
    }

    async getLeagueById(id) {
        return await this.get(`/admin-leagues/${id}`);
    }

    async getLeagueMember(id, params) {
        return await this.get(`/admin-leagues/${id}/members`, { params });
    }

    async changeLeagueOwner(id, memberId) {
        return await this.patch(`/admin-leagues/${id}/members?memberId=${memberId}`);
    }

    async declineLeague(id, data) {
        return await this.delete(`/admin-leagues/waiting/${id}`, { data });
    }

    async deleteMemberById(id, memberId) {
        return this.delete(`/admin-leagues/${id}/members/${memberId}`)
    }

    async deleteLeague(id, data) {
        return this.delete(`/admin-leagues/${id}`, { data });
    }

    async getLeagueTypes() {
        return this.get("/system/types");
    }
}

export default LeaguesService;