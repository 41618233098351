import React from 'react'
import { parseQuery } from "../../../../../../base/hooks/useQueryString";
import Icon from '../../../../../../base/components/Icon';
import {SORT_KEY, SORT_TYPE} from "../../../../../../base/constants/query";
import {formatDatetime} from "../../../../../../base/helpers/formatDatetime";

const DESC_SORT = 'desc';
const DESC_SORT_ICON = 'sortDesc';
const ASC_SORT_ICON = 'sortAsc';
const SORT_DEFAULT_ICON = 'sortDefault';

const DATETIME_FORMAT_PATTERN = "DD MMM yyyy hh:mm A"

export default function TableStructure(location, decorateText) {
    const infoQuery = parseQuery(location.search);
    function getSortIcon(cellName) {
        if (infoQuery[SORT_KEY] === cellName) {
            return infoQuery[SORT_TYPE] === DESC_SORT ? ASC_SORT_ICON : DESC_SORT_ICON;
        }
        return SORT_DEFAULT_ICON;
    }

    return [
        {
            dataField: "name",
            sort: false,
            headerFormatter: () => (<>Event name</>),
            formatter: (cellContent, event) => {
                return (<span>
                        <section className="d-flex flex-row align-items-center event-teams-table__message">
                            <span className="tutee-list--list__name" {...decorateText(event.name)} />
                        </section>

                    </span>)
            }
        },
        {
            dataField: "type",
            sort: true,
            headerFormatter: () => (<>Event type<Icon icon={getSortIcon('type')} /></>),
            formatter: (cellContent, event) => {
                return (
                    <section className="d-flex flex-row align-items-center">
                        {event.type.name}
                    </section>
                )
            }
        },
        {
            headerFormatter: () => (<>Start date <Icon icon={getSortIcon('startDate')} /></>),
            dataField: "startDate",
            sort: true,
            formatter: (cellContent) => (
                <>
                    {formatDatetime(cellContent, DATETIME_FORMAT_PATTERN, true)}
                </>
            )
        },
        {
            headerFormatter: () => (<>End date</>),
            dataField: "endDate",
            sort: false,
            formatter: (cellContent) => (
                <>
                    {formatDatetime(cellContent,  DATETIME_FORMAT_PATTERN, true)}
                </>
            )
        },
        {
            dataField: "status",
            headerFormatter: () => (<>Status</>),
            formatter: (cellContent, team) => {
                return (
                    <section className="d-flex flex-row align-items-center">
                        <span className={`tutee-list--list__name ${team.status}`}>
                            {team.status}
                        </span>
                    </section>
                )
            }
        }
    ]
}