import React, {useEffect, useState, useCallback, useMemo} from "react";

import MainAdminInfo from "./components/MainAdminInfo/";
import {useService} from "../../../../base/hooks/useService";
import {useUserIdFromParams} from "../../hooks";
import {useModal} from "../../../../base/hooks/useModal";
import {useLoading} from "../../../../base/hooks/useLoading";
import Breadcrumb from "../../../../base/components/Breadcrumb";
import {breadcrumbSchema} from "./config";
import {ADMINS_GROUP_LINKS} from "../../config";
import BlockAdmin from "./components/BlockAdmin";
import DeleteAdmin from "./components/DeleteAdmin";
import {useRequestErrorMessage} from "../../../../base/hooks/useRequestErrorMessage";
import PageMeta from "../../../../base/components/MetaTags";
import UnblockAdmin from "./components/UnblockAdmin";
import AdminsService from "../../../../services/AdminsService";
import {getPermissionFlag} from "../../helpers/getPermissionFlag";
import Spinner from '../../../../base/components/Spiner';
import LinkBack from "../../../../base/components/LinkBack";
import webSocketService from "../../../../services/WebSocketService";
import {SOCKET_ADMINS} from "../../constants/socket";

const ACTION_TYPE = {
    BLOCK: 'block',
    UNBLOCK: 'unblock',
    DELETE: 'delete',
}

const MODAL_TITLE = {
    [ACTION_TYPE.BLOCK]: 'Block user',
    [ACTION_TYPE.DELETE]: 'Delete user',
    [ACTION_TYPE.UNBLOCK]: 'Unblock user',
}

const MODAL_BODY = {
    [ACTION_TYPE.BLOCK]: BlockAdmin,
    [ACTION_TYPE.DELETE]: DeleteAdmin,
    [ACTION_TYPE.UNBLOCK]: UnblockAdmin,
}


const UserInfo = () => {
    const [admin, setAdmin] = useState(null);
    const [isLoading, {registerPromise}] = useLoading();
    const [actionType, setAction] = useState(ACTION_TYPE.BLOCK);
    const [{onCatchError}] = useRequestErrorMessage([]);
    /**
     * @type {AdminsService}
     */
    const adminsService = useService(AdminsService);

    const ACTIONS = useMemo(() => (
        {
            [ACTION_TYPE.BLOCK]: adminsService.blockAdminById.bind(adminsService),
            [ACTION_TYPE.DELETE]: adminsService.deleteAdminById.bind(adminsService),
            [ACTION_TYPE.UNBLOCK]: adminsService.blockAdminById.bind(adminsService),
        }
    ), [ACTION_TYPE])

    const {userId} = useUserIdFromParams();
    const {Modal, open, getModalProps, close} = useModal();

    const selectTypeModal = useMemo(() => {
        const generatedSelectTypeModal = {}
        Object.values(ACTION_TYPE).forEach((actionType) => {
            generatedSelectTypeModal[actionType] = () => {
                setAction(actionType)
                open();
            }
        })

        return generatedSelectTypeModal
    }, [ACTION_TYPE, open]);

    const userAction = useCallback(() => {
        registerPromise(ACTIONS[actionType](userId))
            .then(({data}) => {
                setAdmin(data)
                close()
            })
            .catch(onCatchError);
    }, [actionType])

    const handleEditPermission = (value) => {
        const newPermissions = {
            permissionsFlag: getPermissionFlag(value)
        }
        registerPromise(adminsService.changeAdminPermissions(userId, newPermissions))
            .then(({data}) => setAdmin(data))
            .catch(onCatchError);
    }

    const loadAdmin = () => {
        registerPromise(adminsService.getAdminById(userId))
            .then(({data}) => setAdmin(data))
            .catch(onCatchError);
    }

    useEffect(() => {
        loadAdmin();
    }, [registerPromise, adminsService]);

    const updateData = (admin) => {
        if (admin.id === userId) {
            setAdmin(admin)
        }
    }

    webSocketService.onMessage({
        [SOCKET_ADMINS.ADMIN_CHANGE_EMAIL]: updateData,
    })

    const modalTitle = MODAL_TITLE[actionType];
    const ModalBody = MODAL_BODY[actionType];

    return (
        <div className="page-content">
            {isLoading
                ? <Spinner />
                : <>
                    <PageMeta title={'Admin details'}/>
                    {admin && (
                        <>
                            <div className="content-header">
                                <LinkBack defaultLink={ADMINS_GROUP_LINKS.BASE}>Admins</LinkBack>
                                <Breadcrumb schema={breadcrumbSchema}/>
                            </div>
                            <MainAdminInfo
                                firstName={admin.firstName}
                                lastName={admin.lastName}
                                email={admin.email}
                                status={admin.status}
                                role={admin.role}
                                permissions={admin.permissions}
                                dateOfJoining={admin.dateOfJoining}
                                onOpenBlock={selectTypeModal[ACTION_TYPE.BLOCK]}
                                unblockUser={selectTypeModal[ACTION_TYPE.UNBLOCK]}
                                onOpenDelete={selectTypeModal[ACTION_TYPE.DELETE]}
                                onEditPermissions={handleEditPermission}
                            />

                            <Modal {...getModalProps}>
                                <Modal.Header {...getModalProps} onClose={close}>
                                    {modalTitle}
                                </Modal.Header>
                                <div className="m-0 p-0">
                                    <ModalBody action={userAction} close={close}/>
                                </div>
                            </Modal>
                        </>
                    )}
                </>
            }

        </div>
    );
};

export default UserInfo;
