import React from "react";
import {Card} from "reactstrap";
import {useLocationQuery} from "../../../../base/hooks/useQueryString";
import {Link} from "react-router-dom";
import {AUTH_GROUP_LINKS} from "../../config";
import Button from "../../../../base/components/Button/index";
import {BUTTON_COLORS, BUTTON_STYLES} from "../../../../base/components/Button/appearance";

export default function Status() {

    const {params: {danger, linkToLogin}} = useLocationQuery();
    return (
        <div className="d-flex justify-content-center align-items-center vw-100 vh-100">
            {
                danger
                    ? <Card className='shadow-none card-result danger'>
                        <h2>Expired!</h2>
                        <p>The link has expired</p>
                        {linkToLogin && <div className="d-flex justify-content-center card-result__nav">
                            <Link to={AUTH_GROUP_LINKS.LINK_LOGIN}>
                                <Button
                                    color={BUTTON_COLORS.primary}
                                    outline
                                    type="submit"
                                    block={BUTTON_STYLES.block}
                                    className="mt-3">
                                    Sign In
                                </Button>
                            </Link>
                            <Link to={AUTH_GROUP_LINKS.LINK_FORGOT_PASSWORD}>
                                <Button
                                    color={BUTTON_COLORS.primary}
                                    type="submit"
                                    block={BUTTON_STYLES.block}
                                    className="mt-3">
                                    Forgot password
                                </Button>
                            </Link>
                        </div>
                        }
                    </Card>
                    : <Card className='card-result success'>
                        <h2>Success!</h2>
                        <p>Link for password resetting has been sent to your email</p>
                        {linkToLogin && <Link to={AUTH_GROUP_LINKS.LINK_LOGIN} className="flex-shrink-1 flex-grow-1">
                            <Button
                                color={BUTTON_COLORS.primary}
                                type="submit"
                                block={BUTTON_STYLES.block}
                                className="mt-3 w-auto">
                                Sign In
                            </Button>
                        </Link>
                        }
                    </Card>
            }
        </div>
    )
        ;
}
