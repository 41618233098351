import PropTypes from "prop-types";
import React from "react";

import {Switch, Redirect, Route} from "react-router-dom";
import {connect} from "react-redux";

import AuthRoutes from "./groups/auth";

import PrivateRoute from "./base/components/PrivateRoute";
import Page404 from "./base/pages/404";

import "./assets/scss/theme.scss";

import AppRoutes from "./groups/app";
import ErrorBoundary from "./base/components/ErrorBoundary";
import {AUTH_GROUP_LINKS} from "./groups/auth/config";
import {APP_GROUP_LINKS} from "./groups/app/config";
import {BASE_GROUP_LINKS} from "./base/config";
import ConfirmEmail from "./groups/app/pages/ConfirmEmail";

const App = () => {
	return (
		<ErrorBoundary>
			<Switch>
				{/* Auth */}
				<Route path={AUTH_GROUP_LINKS.BASE} component={AuthRoutes}/>

				{/* App */}
				<PrivateRoute path={APP_GROUP_LINKS.BASE} component={AppRoutes}/>
        		<Redirect from="/" to={APP_GROUP_LINKS.BASE} exact/>

				{/* Confirm email */}
				<Route path={APP_GROUP_LINKS.CONFIRM_EMAIL} component={ConfirmEmail} exact/>

				{/* Not found page */}
				<Route path={BASE_GROUP_LINKS.LINK_404} component={Page404}/>
				<Redirect to={BASE_GROUP_LINKS.LINK_404}/>
			</Switch>
		</ErrorBoundary>
	);
};

App.propTypes = {
	layout: PropTypes.any,
};

AppRoutes.propTypes = {
	match: PropTypes.object,
};

const mapStateToProps = (state) => {
	return {
		layout: state.Layout,
	};
};

export default connect(mapStateToProps)(App);
