import React from "react";
import PropTypes from "prop-types";
import { Card } from "reactstrap";
import Button from "../Button";
import { ModalHeader } from "../../../features/CustomModal";
import { useModal } from "../../hooks/useModal";

const PopupDeleteOwner = ({ isDanger, title, body, labelCancel = 'No, cancel', labelDone, action, isOpen = false, closeAction }) => {
    const { Modal, getModalProps, close } = useModal();

    const clickComplete = () => {
        close()
        action()
    }

    const closeComplete = () => {
        close()
        closeAction()
    }

    return <>
        <Modal {...getModalProps} isOpen={isOpen}>
            <ModalHeader onClose={closeComplete}>
                {title}
            </ModalHeader>
            <div className="m-0 p-0">
                <Card className="mb-0">
                    <p className="p-3 mb-0">{body}</p>
                    <menu className="modal-footer p-3 mb-0 mt-0">
                        <Button color='primary' outline onClick={closeComplete}>{labelCancel}</Button>
                        <Button color={isDanger ? 'danger' : 'primary'} onClick={clickComplete}>{labelDone}</Button>
                    </menu>
                </Card>
            </div>
        </Modal>
    </>;
};

PopupDeleteOwner.defaultProps = {
    isDanger: false,
};

PopupDeleteOwner.propTypes = {
    isDanger: PropTypes.bool,
    title: PropTypes.string,
    body: PropTypes.string,
    labelCancel: PropTypes.string,
    labelDone: PropTypes.string,
    action: PropTypes.func
};

export default PopupDeleteOwner;
