import React, {useCallback, useEffect, useMemo, useState} from "react";
import LocationInput from "../../../base/components/GoogleMap/components/LocationInput";
import Map from "../../../base/components/GoogleMap/components/GoogleMapCustom";
import {useField, useFormikContext} from "formik";
import {useGoogleMap} from "../../../base/components/GoogleMap/hooks";

const ERROR_MESSAGE_LOCATION = 'Location not found';

export const LocationInputs = (
    {
        google,
        location,
        setLocation,
        centerValue,
        setCenterValue,
        prevLocation,
        setPrevLocation,
        disabled,
    }
) => {
    const {setLocationByAddress} = useGoogleMap({
        google,
    });

    const {validateForm, setStatus} = useFormikContext()

    const [errorLocation, setErrorLocation] = useState(false);

    const [locationField, locationMeta, locationHelpers] = useField('location');

    const coordinates = useMemo(() => {
        if (locationMeta.value) {
            return {
                lng: locationMeta.value.longitude,
                lat: locationMeta.value.latitude,
            }
        }
    }, [locationMeta.value?.longitude, locationMeta.value?.latitude])

    // Address actions

    const onChangeAddress = useCallback((address) => {
        locationHelpers.setTouched(true)
        locationHelpers.setValue({
            ...locationMeta.value,
            address,
        })

    }, [locationMeta, locationHelpers])

    const onBlurAddress = useCallback((event) => {
        const {target: {value}} = event;

        locationHelpers.setTouched(true)

        locationField.onBlur(event);
        const isError = value && value !== location?.name
        setErrorLocation(isError ? ERROR_MESSAGE_LOCATION : null)
    }, [location]);

    const onSelectAddress = useCallback((address) => {
        setErrorLocation(false);
        return setLocationByAddress(address, setLocation, setCenterValue);
    }, [setCenterValue, setLocation, setLocationByAddress]);

    useEffect(() => {
        if (location?.value) {
            locationHelpers.setTouched(true)
            locationHelpers.setValue({
                latitude: location.value.lat,
                longitude: location.value.lng,
                address: location.name,
                placeId: location.placeId,
            })
            setErrorLocation(null)
        }
    }, [location?.value?.lng, location?.value?.lat])

    useEffect(() => {
        if (!locationMeta.error) {
            locationHelpers.setError(errorLocation ? ERROR_MESSAGE_LOCATION : undefined);
        }
    }, [errorLocation, locationMeta.error])

    useEffect(() => {
        setStatus({errors: Boolean(errorLocation)})
    }, [errorLocation])

    useEffect(() => {
        validateForm();
    }, [locationMeta?.value?.address])

    useEffect(() => {
        if (!location?.value && locationMeta?.value) {
            setCenterValue({
                lng: locationMeta.value.longitude,
                lat: locationMeta.value.latitude,
            })
        }
    }, [locationMeta?.value?.longitude])

    return (
        <div className="mb-3 inline-input">
            <label
                className={locationMeta.touched && (errorLocation || locationMeta.error) ? "text-danger" : ""}
                htmlFor="locationLink">
                Event Location
            </label>
            <div className="w-100">
                <LocationInput
                    label="Location"
                    onBlur={onBlurAddress}
                    onSelect={onSelectAddress}
                    disabled={disabled}
                    error={locationMeta.touched ? (errorLocation || locationMeta.error) : null}
                    value={locationMeta.value?.address}
                    onChange={onChangeAddress}
                />
                <Map
                    coordinates={coordinates}
                    setLocation={setLocation}
                    centerValue={centerValue}
                    setCenterValue={setCenterValue}
                    prevLocation={prevLocation}
                    setPrevLocation={setPrevLocation}
                    google={google}
                    classNameContainer='ms-auto mt-4'
                    disabled={disabled}
                />
            </div>
        </div>
    );
};
