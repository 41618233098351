import React from 'react';
import PropTypes from 'prop-types';
import SearchBar from "../../../../../base/components/Table/tableSearch";

export default function UserTeamsTableHeader({ toolkitProps }) {

    return (
        <section className="d-flex flex-row align-items-center m-0 py-3 justify-content-between w-100">
                <h4 className="card-title">Team members</h4>
                <div className="search-box d-inline-block">
                    <div className="position-relative">
                        <SearchBar
                            {...toolkitProps.searchProps}
                        />
                        <i className="bx bx-search-alt search-icon" />
                    </div>
                </div>
        </section>
    );
}

UserTeamsTableHeader.propTypes = {
    toolkitProps: PropTypes.object,
};