import React from "react";
import PropTypes from "prop-types";
import {Card, CardBody, Media} from "reactstrap";
import {USERS_ROLE_LABEL, USERS_STATUS} from "../../../../../../base/constants/users";
import {BUTTON_COLORS, BUTTON_STYLES} from "../../../../../../base/components/Button/appearance";
import Button from "../../../../../../base/components/Button";
import Popup from "../../../../../../base/components/Popups/Popup";
import defaultAvatar from "../../../../../../assets/images/default-avatar.jpg"
import InfoItem from "./components/InfoItem";
import {popupSchema, ACTION_USER_TYPE} from "../../config"

const MainUserInfo = ({
    user: {
        firstName,
        lastName,
        username,
        role,
        email,
        status,
        phoneNumber,
        bio,
        avatar,
    },
    deleteUser,
    unblockUser,
    blockUser
}) => {
    const isBlocked = status === USERS_STATUS.BLOCKED;
    const isDeleted = status === USERS_STATUS.DELETED;

    return (
        <Card>
            <CardBody className="user-info">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h4 className="card-title mb-3">User info</h4>
                    {!isDeleted && <div className="d-flex flex-nowrap">
                        {!isBlocked
                            ? <Popup
                                {...popupSchema[ACTION_USER_TYPE.BLOCK_USER]}
                                action={blockUser}
                            >
                                <Button
                                    color={BUTTON_COLORS.primary}
                                    type="button"
                                    block={BUTTON_STYLES.block}
                                    className="ms-2 w-auto"
                                >
                                    Block user
                                </Button>
                            </Popup>
                            : <Popup
                                {...popupSchema[ACTION_USER_TYPE.UNBLOCK_USER]}
                                action={unblockUser}
                            >
                                <Button
                                    color={BUTTON_COLORS.primary}
                                    type="button"
                                    block={BUTTON_STYLES.block}
                                    className="ms-2 w-auto"
                                    outline
                                >
                                    Unblock user
                                </Button>
                            </Popup>}

                        <Popup
                            {...popupSchema[ACTION_USER_TYPE.DELETE_USER]}
                            action={deleteUser}
                        >
                            <Button
                                color={BUTTON_COLORS.danger}
                                type="button"
                                block={BUTTON_STYLES.block}
                                className="ms-2 w-auto"
                            >
                                Delete user
                            </Button>
                        </Popup>
                    </div>}
                </div>
                <Media>
                    <div>
                        <img
                            className="avatar"
                            src={avatar?.originalPath || defaultAvatar}
                            alt="user avatar"
                        />
                    </div>
                    <Media body className="align-self-center">
                        <div className="edit-wrapper">
                            <div className="user-info__info">
                                <InfoItem title="Status" value={status} className={status}/>
                                <InfoItem title="First name" value={firstName}/>
                                <InfoItem title="Last Name" value={lastName}/>
                                <InfoItem title="Username" value={username}/>
                                <InfoItem title="Role" value={USERS_ROLE_LABEL[role]}/>
                                <InfoItem title="Phone" value={phoneNumber}/>
                                <InfoItem title="Email address" value={email}/>
                                <InfoItem title="Bio" value={bio}/>
                            </div>
                        </div>
                    </Media>
                </Media>
            </CardBody>
        </Card>
    );
};

MainUserInfo.propTypes = {
    user: PropTypes.object,
    unblockUser: PropTypes.func,
    blockUser: PropTypes.func,
    deleteUser: PropTypes.func
};

export default MainUserInfo;
