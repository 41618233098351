import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import SearchBar from "../../../../../../base/components/Table/tableSearch";
import Filter from "../../../../../../base/components/Filters";
import {useService} from "../../../../../../base/hooks/useService";
import LeaguesService from "../../../../../../services/LeaguesService";
import {useHistory, useLocation} from "react-router-dom";
import {parseQuery} from "../../../../../../base/hooks/useQueryString";
import {useLoading} from "../../../../../../base/hooks/useLoading";
import {useRequestErrorMessage} from "../../../../../../base/hooks/useRequestErrorMessage";
import qs from "query-string";
import {getFilterOptionFromQuery} from "../../../../../../base/helpers/getFiltersFromLocationSerch";

const FILTER_BY_TYPES = 'Types';

const DEFAULT_FILTER = {
    [FILTER_BY_TYPES]: undefined,
}

export default function LeaguesTableHeader({ toolkitProps }) {
    const leagueService = useService(LeaguesService);
    const history = useHistory();
    const location = useLocation();
    const [types, setTypes] = useState([]);

    const query = useMemo(() => parseQuery(location.search), [location]);

    const [filterOption, setFilterOption] = useState(query);
    const [, {registerPromise}] = useLoading();
    const [, {onCatchError}] = useRequestErrorMessage([]);

    const handleChangeFilters = (value) => {
        setFilterOption(value)
    }

    const handleSubmitFilters = () => {
        history.push({
            search: qs.stringify({
                ...query,
                offset: 0,
                [FILTER_BY_TYPES]: filterOption[FILTER_BY_TYPES],
            })
        })
    }

    const resetFilters = () => {
        setFilterOption(DEFAULT_FILTER)
        history.push({
            search: qs.stringify({
                ...query,
                [FILTER_BY_TYPES]: undefined,
            })
        })
    }

    const filterType = useMemo(() => {
        return {
            name: FILTER_BY_TYPES,
            title: 'By Type',
            items: types,
            scroll: true,
        }
    }, [types])

    const filterSchema = useMemo(
        () => [filterType],
        [filterType]
    )

    useEffect(() => {
        registerPromise(leagueService.getLeagueTypes())
            .then(({data}) => {
                setTypes(data.map(type => ({
                    text: type.name,
                    value: type.id
                })));
            })
            .catch(onCatchError)
    }, [])

    useEffect(() => {
        setFilterOption(getFilterOptionFromQuery(Object.keys(DEFAULT_FILTER)))
    }, [query])

    return (
        <section className="d-flex flex-row py-3 m-0 justify-content-between users-table--filters">
            <section className="d-flex justify-content-between align-items-center w-100 flex-row">
                <div className="search-box me-3 mb-0 d-inline-block">
                    <div className="position-relative">
                        <SearchBar
                            {...toolkitProps.searchProps}
                        />
                        <i className="bx bx-search-alt search-icon" />
                    </div>
                </div>
                <Filter
                    defaultValue={DEFAULT_FILTER}
                    onChange={handleChangeFilters}
                    onReset={resetFilters}
                    onSubmit={handleSubmitFilters}
                    filterState={filterOption}
                    schema={filterSchema}
                />
            </section>
        </section>
    );
}

LeaguesTableHeader.propTypes = {
    toolkitProps: PropTypes.object,
}