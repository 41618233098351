import React, {useState} from "react";
import {ReactComponent as DropdownArrow} from "../assets/dropdown-arrow.svg";
import {
    DropdownMenu,
    Dropdown as ReactDropdown,
    DropdownToggle,
    DropdownItem,
} from "reactstrap";
import {Link} from "react-router-dom";

const Dropdown = ({
  disabled = false,
  optionsSchema = [],
  direction,
  disableArrow = false,
  tagToggle= 'div',
  color,
  children,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
    return <ReactDropdown
        {...props}
        isOpen={isOpen}
        toggle={() => setIsOpen(prevState => !prevState)}
        direction={direction}
    >
        <DropdownToggle
            caret
            tag={tagToggle}
            className={`custom-toggle ${isOpen ? 'open' : ''}`}
            disabled={disabled}
        >
            {children}
            {!disableArrow && <DropdownArrow className="dropdown-arrow"/>}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-right">
            {optionsSchema.map(({render, isLink, to, action, toggle}, index) => {
                const handleClick = () => {
                    action && action()
                };
                return <DropdownItem toggle={toggle} key={index} >
                    {
                        isLink
                            ? <Link className="dropdown-link" to={to}>{render}</Link>
                            : <div onClick={handleClick}>{render}</div>
                    }
                </DropdownItem>
            })}
        </DropdownMenu>
    </ReactDropdown>
};

export default Dropdown;
