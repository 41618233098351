const BASE = '/app/leagues';

export const LEAGUES_LIST_LINKS = {
    ACCEPTED: '/accepted',
    WAITING: '/waiting'
};

export const LEAGUES_GROUP_LINKS = {
    BASE,
    ACCEPTED_LEAGUES: `${BASE}${LEAGUES_LIST_LINKS.ACCEPTED}`,
    WAITING_LEAGUES: `${BASE}${LEAGUES_LIST_LINKS.WAITING}`,
    ACCEPTED_LEAGUES_DETAILS: `${BASE}${LEAGUES_LIST_LINKS.ACCEPTED}`.concat('/:id'),
    WAITING_LEAGUES_DETAILS: `${BASE}${LEAGUES_LIST_LINKS.WAITING}`.concat('/:id'),
};