import React from "react";
import NavTabs from "../../../../base/components/NavTabs/NavTabs";
import {teamsListSchema} from "./config";
import PageMeta from "../../../../base/components/MetaTags";
import {REPORTS_GROUP_LINKS} from "../../config";

const ReportsList = ({userReportsParams, postReportsParams, commentReportsParams}) => {
    return (
        <section className="page-content">
            <PageMeta title={'Reports'} />
            <div className="content-header">
                <h2 className="content-header__title">Reports</h2>
            </div>
            <NavTabs
                rootLink={REPORTS_GROUP_LINKS.BASE}
                schema={teamsListSchema(userReportsParams, postReportsParams, commentReportsParams)}
            />
        </section>

    );
};

export default ReportsList;