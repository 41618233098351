import React from 'react'
import { EVENTS_STATUS } from "../../../../../../base/constants/events";
import Button from "../../../../../../base/components/Button";
import Dropdown from "../../../../../../base/components/Dropdown";
import { UncontrolledTooltip } from 'reactstrap';
import { ReactComponent as DropdownArrow } from "../../../../../../base/assets/dropdown-arrow.svg";
import { handleWithoutPropagation } from "../../../../../../base/helpers/handleWithoutPropagation";
import { generateFilesLink } from "../../helpers/generateFileLink";
import Popup from "../../../../../../base/components/Popups/Popup";

export const tableStructure = (status, declineTeam, acceptTeam) => () => {
    const isShowMenuAction = [EVENTS_STATUS.UPCOMING, EVENTS_STATUS.SUSPENDED].includes(status)

    return [
        {
            dataField: "name",
            sort: true,
            headerFormatter: () => (<>Team name</>),
            formatter: (cellContent, team) => {
                return (
                    <section className="d-flex flex-row align-items-center teams-table__name">
                        {team.name}
                    </section>
                )
            }
        },
        {
            dataField: "type",
            sort: false,
            headerFormatter: () => (<>Team type</>),
            formatter: (cellContent, team) => {
                return (
                    <section className="d-flex flex-row align-items-center">
                        {team.type.name}
                    </section>
                )
            }
        },
        {
            dataField: "message",
            headerFormatter: () => (<>Team owner message</>),
            formatter: (cellContent, team) => {
                return (
                    <>
                        <UncontrolledTooltip
                            placement="top"
                            target={`eventTeam${team.id}`}
                        >
                            {team.message}
                        </UncontrolledTooltip>
                        <span id={`eventTeam${team.id}`} className="d-inline-block event-teams-table__message">
                            {team.message}
                        </span>
                    </>
                )
            }
        },
        {
            sort: false,
            headerClasses: 'action-cell',
            headerFormatter: () => (<>Actions</>),
            formatter: (cellContent, team) => {
                const isNoFiles = !team.files?.length

                return <div className="d-flex gap-3" onClick={handleWithoutPropagation()}>
                    <Dropdown
                        color="primary"
                        className="text-primary"
                        disabled={isNoFiles}
                        outline
                        disableArrow
                        direction="left"
                        optionsSchema={generateFilesLink(team.files)}
                    >
                        <Button
                            className="py-1 position-relative pe-4"
                            outline
                            color={isNoFiles ? 'secondary' : 'primary'}
                            disabled={isNoFiles}
                        >
                            Documents
                            <DropdownArrow className="dropdown-arrow" />
                        </Button>
                    </Dropdown>
                    {isShowMenuAction && <>
                        <Popup
                            action={() => declineTeam(team.id)}
                            isDanger={true}
                            title='Decline'
                            body='Are you sure you want to decline this join request?'
                            labelCancel='No, Cancel'
                            labelDone='Yes, decline'
                        >
                            <Button
                                color="danger"
                                className="py-1"
                                outline
                            >
                                Decline
                            </Button>
                        </Popup>
                        <Button
                            color="success"
                            className="py-1"
                            onClick={() => acceptTeam(team.id)}>
                            Accept
                        </Button>
                    </>}
                </div>
            },
        },
    ]
}