import SessionStorage from "./SessionStorage";
const sessionStorage = new SessionStorage();

class WebSocketService {
    constructor() {
        this.socket = WebSocket;
        this.uri = process.env.REACT_APP_WS;
        this.defaultHandlers = {}
        this.handlers = {}
    }

    onClose = () => {
        this.socket.close();
    };

    onOpen = () => {
        const { accessToken } = sessionStorage.getSession();

        WebSocketService.key = accessToken || "";
        this.socket = new WebSocket(this.uri, WebSocketService.key);

        this.socket.onerror = () => {
            if (accessToken) {
                this.onOpen();
            }
        };

        this.socket.onmessage = (event => {
            const {eventType, data} = JSON.parse(event.data)

            if(Object.keys(this.defaultHandlers).includes(eventType)) {
                this.defaultHandlers[eventType](data)
            }

            if(Object.keys(this.handlers).includes(eventType)) {
                this.handlers[eventType](data)
            }
        })

        return this;
    };

    addHandlers = (handlers) => {
        this.handlers = {...this.handlers, ...handlers}
    }

    onMessage = (handlers) => {
        this.handlers = handlers
    }
}

export default new WebSocketService();