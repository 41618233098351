import React, {useState, useMemo, useEffect} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'query-string';
import { parseQuery } from '../../../../../../base/hooks/useQueryString';
import PropTypes from 'prop-types';
import {useLoading} from "../../../../../../base/hooks/useLoading";
import {useService} from "../../../../../../base/hooks/useService";
import TeamsService from "../../../../../../services/TeamsService";
import {useRequestErrorMessage} from "../../../../../../base/hooks/useRequestErrorMessage";
import SearchBar from "../../../../../../base/components/Table/tableSearch";
import Filter from "../../../../../../base/components/Filters";
import {getFilterOptionFromQuery} from "../../../../../../base/helpers/getFiltersFromLocationSerch";


const FILTER_BY_TYPES = 'Types';

const DEFAULT_FILTER = {
    [FILTER_BY_TYPES]: undefined,
}

export default function TeamsTableHeader({ toolkitProps }) {

    /**
     * @type {TeamsService}
     */
    const teamsService = useService(TeamsService);
    const history = useHistory();
    const location = useLocation();
    const [types, setTypes] = useState([]);

    const query = useMemo(() => parseQuery(location.search), [location]);

    const [filterOption, setFilterOption] = useState(query);
    const [, {registerPromise}] = useLoading();
    const [, {onCatchError}] = useRequestErrorMessage([]);

    const handleChangeFilters = (value) => {
        setFilterOption(value)
    }

    const handleSubmitFilters = () => {
        history.push({
            search: qs.stringify({
                ...query,
                offset: 0,
                [FILTER_BY_TYPES]: filterOption[FILTER_BY_TYPES],
            })
        })
    }

    const resetFilters = () => {
        setFilterOption(DEFAULT_FILTER)
        history.push({
            search: qs.stringify({
                ...query,
                [FILTER_BY_TYPES]: undefined,
            })
        })
    }

    const filterType = useMemo(() => {
        return {
            name: FILTER_BY_TYPES,
            title: 'By Type',
            items: types,
            scroll: true,
        }
    }, [types])

    const filterSchema = useMemo(
        () => [filterType],
        [filterType]
    )

    useEffect(() => {
        registerPromise(teamsService.getTeamTypes())
            .then(({data}) => {
                setTypes(data.map(type => ({
                    text: type.name,
                    value: type.id
                })));
            })
            .catch(onCatchError)
    }, [])

    useEffect(() => {
        setFilterOption(getFilterOptionFromQuery(Object.keys(DEFAULT_FILTER)))
    }, [query])

    return (
        <section className="d-flex flex-row py-3 m-0 justify-content-between users-table--filters">
            <section className="d-flex justify-content-between align-items-center w-100 flex-row">
                    <div className="search-box me-3 mb-0 d-inline-block">
                        <div className="position-relative">
                            <SearchBar
                                {...toolkitProps.searchProps}
                            />
                            <i className="bx bx-search-alt search-icon" />
                        </div>
                    </div>
                    <Filter
                        defaultValue={DEFAULT_FILTER}
                        onChange={handleChangeFilters}
                        onReset={resetFilters}
                        onSubmit={handleSubmitFilters}
                        filterState={filterOption}
                        schema={filterSchema}
                    />
            </section>
        </section>
    );
}

TeamsTableHeader.propTypes = {
    toolkitProps: PropTypes.object,
}