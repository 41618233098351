import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useHistory, useLocation} from 'react-router-dom';
import qs from "query-string";
import {parseQuery} from "../../../../../../base/hooks/useQueryString";
import Filter from "../../../../../../base/components/Filters";
import {REPORTS_STATUS, REPORTS_STATUS_LABEL} from "../../../../../../base/constants/reports";
import SearchBar from "../../../../../../base/components/Table/tableSearch";
import {getFilterOptionFromQuery} from "../../../../../../base/helpers/getFiltersFromLocationSerch";

const FILTER_BY_STATUS = 'Status';

const DEFAULT_FILTER = {
    [FILTER_BY_STATUS]: undefined,
};

const filterStatusItems = Object.values(REPORTS_STATUS).map(status => ({
    text: REPORTS_STATUS_LABEL[status],
    value: status,
}))

const filterStatus = {
    name: FILTER_BY_STATUS,
    title: 'By Status',
    items: filterStatusItems,
};

export default function TableHeader({ toolkitProps }) {
    const history = useHistory();
    const location = useLocation();

    const query = useMemo(() => parseQuery(location.search), [location]);
    const [filterOption, setFilterOption] = useState(DEFAULT_FILTER);



    const filterSchema = useMemo(
        () => [filterStatus],
        [filterStatus]
    )

    const handleChangeFilters = (value) => {
        setFilterOption(value);
    };

    const handleSubmitFilters = () => {
        history.push({
            search: qs.stringify({
                ...query,
                offset: 0,
                [FILTER_BY_STATUS]: filterOption[FILTER_BY_STATUS],
            })
        })
    };

    const resetFilters = () => {
        history.push({
            search: qs.stringify({
                ...query,
                [FILTER_BY_STATUS]: undefined,
            })
        })
    };

    useEffect(() => {
        setFilterOption(getFilterOptionFromQuery(Object.keys(DEFAULT_FILTER)))
    }, [query])

    return (
        <section className="d-flex flex-row align-items-center  m-0 py-3 justify-content-between w-100">
                <div className="search-box d-inline-block me-3">
                    <div className="position-relative">
                        <SearchBar
                            {...toolkitProps.searchProps}
                        />
                        <i className="bx bx-search-alt search-icon" />
                    </div>
                </div>
                <Filter
                    defaultValue={DEFAULT_FILTER}
                    onChange={handleChangeFilters}
                    onReset={resetFilters}
                    onSubmit={handleSubmitFilters}
                    filterState={filterOption}
                    schema={filterSchema}
                />
        </section>
    );
}

TableHeader.propTypes = {
    toolkitProps: PropTypes.object,
}
