export const SOCKET_EVENTS = {
    EVENT_CREATED: 'event_created',
    EVENT_TEMPLATE_CREATED: 'event_template_created',
    EVENT_EDITED: 'event_edited',
    EVENT_DELETED: 'event_deleted',
    EVENT_SUSPENDED_UNSUSPENDED: 'event_suspended_unsuspended',
    NEW_EVENT_JOIN_REQUEST: 'new_event_join_request',
    CANCEL_EVENT_JOIN_REQUEST: 'cancel_event_join_request',
    JOIN_REQUEST_ACCEPT: 'event_join_request_accept',
    JOIN_REQUEST_DECLINE: 'event_join_request_decline',
    EVENT_TEAM_PARTICIPATION_CANCELED: 'event_team_participation_canceled'
}