import React from "react";
import Icon from "../../../base/components/Icon";

export default function Logo() {
  return (
    <div className="auth-logo-light">
      <div className="avatar-md profile-user-wid mb-3">
        <span className="rounded-circle logo">
          <Icon icon="logoLight" height="44" />
        </span>
      </div>
    </div>
  );
}
