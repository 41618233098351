import React from "react";
import PropTypes from "prop-types";

const InfoItem = ({ title, value, className = '' }) => {
  return (
      <div className="d-flex align-items-start">
          <span className="title">{title}</span>
          <span className={`value ${className}`}>{value}</span>
      </div>
  );
};

InfoItem.propTypes = {
    title: PropTypes.string,
    value: PropTypes.any,
    className: PropTypes.string,
};

export default InfoItem;
