//user

export const MIN_PASSWORD_LENGTH = 6;
export const MAX_PASSWORD_LENGTH = 50;
export const MAX_LOCAL_PART_EMAIL = 64;
export const MAX_DOMAIN_PART_EMAIL = 64;
export const MIN_NAME_LENGTH = 1;
export const MAX_NAME_LENGTH = 50;

// event

export const MIN_EVENT_NAME = 1;
export const MAX_EVENT_NAME = 200;
export const MIN_EVENT_DESCRIPTION = 1;
export const MAX_EVENT_DESCRIPTION = 500;
export const MAX_YEAR_DATE_EVENT = 2;


