import React from "react";
import {CustomModal} from "../../../../../../features/CustomModal";

export const ModalGallery = ({children, close, ...modalProps }) => {
    return <CustomModal {...modalProps} size="lg" className="modal-file-list">
        <button className="modal-file-list__close-button" onClick={close}/>
        {children}
    </CustomModal>

};
