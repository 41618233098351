import Http from "./Http";

class ReportsService extends Http {
  static $displayName = "ReportsService";

  async getUsersReports(params) {
    return await this.get("/admin-reports/users", { params });
  }

  async getPostsReports(params) {
    return await this.get("/admin-reports/posts", { params });
  }

  async getCommentsReports(params) {
    return await this.get("/admin-reports/comments", { params });
  }

  async getReportsByUserId(id, params) {
    return await this.get(`/admin-reports/users/${id}/reports`, { params });
  }

  async getReportsByPostId(id, params) {
    return await this.get(`/admin-reports/posts/${id}/reports`, { params });
  }

  async getReportsByCommentId(id, params) {
    return await this.get(`/admin-reports/comments/${id}/reports`, { params });
  }

  async getReportedUserById(id) {
    return await this.get(`/admin-reports/users/${id}`);
  }

  async getReportedPostById(id) {
    return await this.get(`/admin-reports/posts/${id}`);
  }

  async getReportedCommentById(id) {
    return await this.get(`/admin-reports/comments/${id}`);
  }

  async deleteReportedUserById(id) {
    return await this.delete(`/admin-reports/users/${id}`);
  }

  async deleteReportedPostById(id) {
    return await this.delete(`/admin-reports/posts/${id}`);
  }

  async deleteReportedCommentById(id) {
    return await this.delete(`/admin-reports/comments/${id}`);
  }

  async changeStatusUserById(id) {
    return await this.patch(`/admin-reports/users/${id}`);
  }

  async changeStatusReports(ids) {
    return await this.patch(`/admin-reports`, {reportsIds: ids});
  }
}

export default ReportsService;
