import {EventsList} from "./components/EventsList";
import {TemplateEventsList} from "./components/TemplateEventsList";
import {EVENT_LIST_LINKS} from "../../config";

export const teamsListSchema = (eventsParams, eventTemplateParams) => [
    {
        link: EVENT_LIST_LINKS.EVENTS,
        label: 'Events',
        render: EventsList,
        props: {eventsParams}
    },
    {
        link: EVENT_LIST_LINKS.TEMPLATES,
        label: 'Event templates',
        render: TemplateEventsList,
        props: {eventTemplateParams}
    }

]