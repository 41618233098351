import React from 'react';
import PageMeta from "../../../../base/components/MetaTags";
import NavTabs from "../../../../base/components/NavTabs/NavTabs";
import {LEAGUES_GROUP_LINKS} from "../../config";
import {leaguesListSchema} from "./config";

const LeaguesList = ({acceptedLeaguesParams, waitingLeaguesParams}) => {
    return (
        <section className="page-content">
            <PageMeta title={'Leagues'}/>
            <div className="content-header">
                <h2 className="content-header__title">Leagues</h2>
            </div>
            <NavTabs
                rootLink={LEAGUES_GROUP_LINKS.BASE}
                schema={leaguesListSchema({acceptedLeaguesParams, waitingLeaguesParams})}
            />
        </section>
    );
};

export default LeaguesList;