import React from 'react';
import PropTypes from 'prop-types';
import Button from "../../../../base/components/Button";
import {ADMINS_GROUP_LINKS} from "../../config";
import {Link} from 'react-router-dom';
import SearchBar from "../../../../base/components/Table/tableSearch";

export default function AdminsTableHeader({ toolkitProps }) {
    return (
        <section className="d-flex flex-row align-items-center justify-content-between py-3 w-100">
            <div className="search-box d-inline-block ">
                <div className="position-relative">
                    <SearchBar
                        {...toolkitProps.searchProps}
                    />
                    <i className="bx bx-search-alt search-icon" />
                </div>
            </div>
            <menu className="m-0">
                <Link to={ADMINS_GROUP_LINKS.LINK_ADMIN_CREATE}>
                    <Button type="button" color="primary ms-4" className="d-flex align-items-center">+  Create new admin</Button>
                </Link>
            </menu>
        </section>
    );
}

AdminsTableHeader.propTypes = {
    toolkitProps: PropTypes.object,
}
