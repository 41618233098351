import React, {useState} from "react";
import ChangeEmailForm from "./components/ChangeEmailForm/Index";
import ChangePasswordForm from "./components/ChangePasswordForm/Index";
import PageMeta from "../../../../base/components/MetaTags";
import {KEY_USER} from "../../../../base/constants/storage";
import {useService} from "../../../../base/hooks/useService";
import StorageService from "../../../../services/StorageService";
import {ADMIN_ROLE} from "../../../../base/constants/users";

export default function Settings() {
    /**
     * @type {StorageService}
     */
    const storage = useService(StorageService);

    const user = storage.get(KEY_USER);
    const isPlatformAdmin = user.role === ADMIN_ROLE.PLATFORM_ADMIN

    console.log(user.role)

    return (
        <div className="page-content">
            <PageMeta title="Settings" />
            <div className="content-header">
                <h2 className="content-header__title">Settings</h2>
            </div>
            <div className="setting-content">
                {isPlatformAdmin && <ChangeEmailForm />}
                <ChangePasswordForm/>
            </div>
        </div>

    );
}
