import Http from "./Http";

class UsersService extends Http {
  static $displayName = "UsersService";

  async getUsers(params) {
    return await this.get("/admin-users", { params });
  }

  async getUserById(id) {
    return await this.get(`/admin-users/${id}`);
  }

  async deleteUserById(id) {
    return await this.delete(`/admin-users/${id}`);
  }

  async blockUserById(id) {
    return await this.patch(`/admin-users/${id}`);
  }

  async getUsersTeams(id, params) {
    return await this.get(`/admin-users/${id}/teams`, {params});
  }

  async getUsersLeagues(id, params) {
    return await this.get(`/admin-users/${id}/leagues`, {params});
  }
}

export default UsersService;
