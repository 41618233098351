import React, {useCallback, useState} from "react";
import Table from "../../../../../../base/components/Table";
import {useService} from "../../../../../../base/hooks/useService";
import {useLocationQuery} from "../../../../../../base/hooks/useQueryString";
import {useTable} from '../../../../../../base/hooks/useTable';
import {tableStructure} from "./tableStructure";
import UsersTableHeader from './TableHeader';
import {Container} from "reactstrap";
import {SEARCH_QUERY, SORT_KEY, SORT_TYPE} from "../../../../../../base/constants/query";
import TeamsService from "../../../../../../services/TeamsService";
import {useLoading} from "../../../../../../base/hooks/useLoading";
import {useRequestErrorMessage} from "../../../../../../base/hooks/useRequestErrorMessage";
import webSocketService from "../../../../../../services/WebSocketService";
import {SOCKET_TEAMS} from "../../../../constants/socket";
import {useHistory} from "react-router-dom";

const TEAMS_LIMIT = 15;

export const WaitingTeamsList = ({waitingListParams}) => {
    /**
     * @type {TeamsService}
     */
    const teamsService = useService(TeamsService);
    const [team, setTeam] = useState(null);
    const [isLoading, {registerPromise}] = useLoading();
    const [, {onCatchError}] = useRequestErrorMessage([]);
    const [update, setUpdate] = useState(false);
    const {params} = useLocationQuery();
    const history = useHistory();

    const [loading, pagination, data, handleTableChange, downloadNewData] = useTable({
        searchQueryParam: SEARCH_QUERY,
        sortTypeQueryParam: SORT_TYPE,
        sortByQueryParam: SORT_KEY,
        getNewDataService: teamsService.getWaitingTeams.bind(teamsService),
        offset: params.offset || 0,
        limit: TEAMS_LIMIT,
        defaultSort: waitingListParams,
    });

    const updateOffset = (totalCount) => {
        if (params.offset === totalCount - 1 && params.offset) {
            history.push({
                search: `offset=${params.offset - TEAMS_LIMIT}`
            });
        }
    }


    const handleDecline = useCallback((data) => {
        const id = data.id;
        const body = {
            reasonText: data.inputText
        }
        registerPromise(teamsService.declineTeam(id, body))
            .then(({data}) => {
                setTeam(data);
                downloadNewData();
                updateOffset(pagination.totalCount);
            })
            .catch(onCatchError);
    }, [pagination, params]);

    const handleAccept = useCallback((id) => {
        return registerPromise(teamsService.acceptTeam(id))
            .then(({data}) => {
                setTeam(data);
                downloadNewData();
                updateOffset(pagination.totalCount);
            })
            .catch(onCatchError);
    }, [pagination, params]);

    const textPlaceholder = !params[SEARCH_QUERY] ? 'No requests yet' : 'No search results found';

    const updateData = async () => {
        setUpdate(true)
        await downloadNewData()
        setUpdate(false)
    }

    webSocketService.onMessage({
        [SOCKET_TEAMS.TEAM_ACCEPTED]: updateData,
        [SOCKET_TEAMS.TEAM_DECLINED]: updateData,
        [SOCKET_TEAMS.NEW_CREATE_TEAM_REQUEST]: updateData,
        [SOCKET_TEAMS.OWNER_DELETE_TEAM]: updateData,
    })

    return (
        <Container fluid className="p-0">
            {!isLoading && <Table
                data={data}
                dataStructure={tableStructure(handleDecline, handleAccept)}
                loading={!update && loading}
                HeaderComponent={UsersTableHeader}
                limit={TEAMS_LIMIT}
                offset={params.offset || 0}
                totalCount={pagination.totalCount}
                handleTableChange={handleTableChange}
                searchField={SEARCH_QUERY}
                textPlaceholder={textPlaceholder}
            />}
        </Container>
    );
};
