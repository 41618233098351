
const BASE = '/app/teams';

export const TEAMS_LIST_LINK = {
	ACCEPTED_TEAMS: `/accepted-teams`,
	WAITING_TEAMS: `/waiting-teams`,
}

export const TEAMS_GROUP_LINKS = {
	BASE,
	ACCEPTED_TEAMS: `${BASE}${TEAMS_LIST_LINK.ACCEPTED_TEAMS}`,
	WAITING_TEAMS: `${BASE}${TEAMS_LIST_LINK.WAITING_TEAMS}`,
	ACCEPTED_INFO: `${BASE}${TEAMS_LIST_LINK.ACCEPTED_TEAMS}`.concat('/:id'),
	WAITING_INFO: `${BASE}${TEAMS_LIST_LINK.WAITING_TEAMS}`.concat('/:id'),
};