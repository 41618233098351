import {AcceptedLeaguesList} from "./components/AcceptedLeaguesList";
import {WaitingLeaguesList} from "./components/WaitingLeaguesList";
import {LEAGUES_LIST_LINKS} from "../../config";

export const leaguesListSchema = ({acceptedLeaguesParams, waitingLeaguesParams}) => [
    {
        link: LEAGUES_LIST_LINKS.ACCEPTED,
        label: 'Accepted leagues',
        render: AcceptedLeaguesList,
        props: {acceptedLeaguesParams}
    },
    {
        link: LEAGUES_LIST_LINKS.WAITING,
        label: 'Waiting leagues',
        render: WaitingLeaguesList,
        props: {waitingLeaguesParams}
    }
]