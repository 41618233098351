import React from "react";

export const LinksList = ({links = []}) => {
    return <ul className="d-flex flex-column">
        {
            links.map(link => <li key={link}>
                <a href={link} rel="noreferrer" target="_blank">{link}</a>
            </li>)
        }
    </ul>
};
