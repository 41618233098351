import React from 'react';
import {formatDatetime} from "../../../../../../base/helpers/formatDatetime";
import {FilesList} from "../FilesList";
import {LinksList} from "../LinksList";
import InfoItem from "../../../../../teams/components/MainInfo/components/InfoItem";

const DATETIME_FORMAT_PATTERN = "DD MMM yyyy hh:mm A"

const PostHistory = ({post}) => (
    <div className={`reports-history ${post?.history.length > 5 && "reports-posts-history-scroll"}`}>
        {post?.history.map((item) => (
            <div key={item.id}>
                <div className="user-info__info pt-3 px-3">
                    {item?.files && <InfoItem title="Post info" value={<FilesList filesList={item?.files}/>}/>}
                    {item?.description && <InfoItem title="Post description" value={item?.description}/>}
                    {item?.links && <InfoItem title="Post links" value={<LinksList links={item?.links}/>}/>}
                    <InfoItem title="Post date and time"
                              value={formatDatetime(item?.createdAt, DATETIME_FORMAT_PATTERN)}/>
                </div>
                <hr className="mx-3"/>
            </div>
        ))}
    </div>
);


export default PostHistory;