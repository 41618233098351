import React, {useMemo} from "react";
import PropTypes from "prop-types";
import {Card, CardBody, Media} from "reactstrap";

import Button from "../../../../../../base/components/Button";
import {USERS_STATUS} from "../../../../../../base/constants/users";
import InfoItem from "./components/InfoItem";
import {formatDate} from "../../../../../../base/helpers/formatDate";
import FormPermissions from "./components/FormPermissions";
import {getAdminPermissions} from "../../../../../../base/helpers/getAdminPermissions";

const MainAdminInfo = (
    {
        firstName,
        lastName,
        email,
        status,
        dateOfJoining,
        permissions,
        onOpenBlock,
        unblockUser,
        onOpenDelete,
        onEditPermissions,
    }) => {

    const isBlocked = status === USERS_STATUS.BLOCKED;
    const isDeleted = status === USERS_STATUS.DELETED;

    const permissionsList = useMemo(() => {
        return getAdminPermissions(permissions)
    }, [permissions])

    return (
        <Card className='col-12 .col-sm-12 col-md-12 col-lg-12 col-xl-5 m-0 p-0'>
            <CardBody className="admin-info">
                <div className='d-flex justify-content-between align-items-center mb-4'>
                    <h4 className="card-title mb-0">Admin details</h4>
                    {!isDeleted && <div>
                        {!isBlocked
                            ? (<Button className="edit-user-btn " color="primary" onClick={onOpenBlock}>
                                Block
                            </Button>)
                            : (<Button className="edit-user-btn " outline color="primary" onClick={unblockUser}>
                                Unblock
                            </Button>)}
                        <Button className="edit-user-btn ms-3" color="danger" onClick={onOpenDelete}>
                            Delete
                        </Button>
                    </div>}
                </div>

                <Media>
                    <Media body className="align-self-center">
                        <div className="edit-wrapper">
                            <div className="admin-info__info">
                                <InfoItem title="Status" value={status} className={`${status} mt-1`} />
                                <InfoItem title="Email" value={email} />
                                <InfoItem title="First name" value={firstName}  />
                                <InfoItem title="Last Name" value={lastName} />
                                {status === USERS_STATUS.PENDING
                                    ? <InfoItem title="Date of sending the invitation" value={formatDate(dateOfJoining)} />
                                    : <InfoItem title="Date of joining" value={formatDate(dateOfJoining)} />
                                }
                            </div>
                        </div>
                        <div>
                            <FormPermissions
                                editAdminPermissions={onEditPermissions}
                                permissions={permissionsList}
                            />
                        </div>
                    </Media>
                </Media>
            </CardBody>
        </Card>
    );
};

MainAdminInfo.propTypes = {
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
    dateOfJoining: PropTypes.string,
    permissions: PropTypes.number,
    unblockUser: PropTypes.func,
    onOpenBlock: PropTypes.func,
    onOpenDelete: PropTypes.func,
    onEditPermissions: PropTypes.func,
    status: PropTypes.oneOf(Object.values(USERS_STATUS))
};

export default MainAdminInfo;
