import React from 'react';
import {Field} from 'formik';
import Checkbox from "./Checkbox";

const FormikCheckbox = ({name, value, label, className, id}) => {
    const type = 'checkbox';

    return <div className={className}>
        <Field
            name={name}
            className={className}
            value={value}
            type={type}
            render={({field}) => {
                return (
                    <Checkbox
                        {...field}
                        name={name}
                        label={label}
                        id={id}
                    />
                )
            }}
        />
    </div>
}

export default FormikCheckbox;