import React, {useCallback} from "react";
import {Route, Switch} from "react-router-dom";
import {REPORTS_GROUP_LINKS} from "./config";
import ReportsList from "./pages/ReportsLists";
import {useStateParams} from "../../base/hooks/useStateParams";
import {UserReportedDetails} from "./pages/UserReportsDetails";
import {PostReportedDetails} from "./pages/PostReportsDetails";
import {CommentReportedDetails} from "./pages/CommentReportsDetails";

const Reports = () => {
    const [userReportsParams] = useStateParams(REPORTS_GROUP_LINKS.USER_REPORTS);
    const [postReportsParams] = useStateParams(REPORTS_GROUP_LINKS.POST_REPORTS);
    const [commentReportsParams] = useStateParams(REPORTS_GROUP_LINKS.COMMENT_REPORTS);

    const ReportsTables = useCallback(() => <ReportsList
        userReportsParams={userReportsParams}
        postReportsParams={postReportsParams}
        commentReportsParams={commentReportsParams}
    />, [userReportsParams, postReportsParams, commentReportsParams])

    return (
        <Switch>
            <Route
                path={[REPORTS_GROUP_LINKS.BASE, REPORTS_GROUP_LINKS.POST_REPORTS, REPORTS_GROUP_LINKS.USER_REPORTS, REPORTS_GROUP_LINKS.COMMENT_REPORTS]}
                render={ReportsTables}
                exact
            />
            <Route
                path={REPORTS_GROUP_LINKS.USER_REPORTS_DETAILS}
                component={UserReportedDetails}
                exact
            />
            <Route
                path={REPORTS_GROUP_LINKS.POST_REPORTS_DETAILS}
                component={PostReportedDetails}
                exact
            />
            <Route
                path={REPORTS_GROUP_LINKS.COMMENT_REPORTS_DETAILS}
                component={CommentReportedDetails}
                exact
            />
        </Switch>
    );
};

export default Reports;
