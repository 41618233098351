import {TEAMS_GROUP_LINKS} from "../../config";

export const breadcrumbSchema = [
    {
        title: 'Teams',
        path: TEAMS_GROUP_LINKS.BASE
    },
    {
        title: 'Team details',
    }
];

export const ACTION_WAITING_TEAM = {
    DECLINE: 'decline',
    ACCEPT: 'accept'
};

export const popupSchema = {
    [ACTION_WAITING_TEAM.DECLINE]: {
        isDanger: true,
        title: 'Decline',
        body: 'Are you sure you want to decline this request?',
        labelDone: 'Yes, Decline',
    }
}

export const menuSchema = {
    [ACTION_WAITING_TEAM.DECLINE]: {
        color: 'danger',
        label: 'Decline',
        outline: true,
        popupSchema: popupSchema[ACTION_WAITING_TEAM.DECLINE],
    },
    [ACTION_WAITING_TEAM.ACCEPT]: {
        color: 'success',
        label: 'Accept',
        outline: false,
    },
};



