export const USERS_STATUS = {
    BLOCKED: 'Blocked',
    ACTIVE: 'Active',
    DELETED: 'Deleted',
    PENDING: 'Pending',
};

export const USERS_STATUS_LABEL = {
    [USERS_STATUS.BLOCKED]: 'Blocked',
    [USERS_STATUS.ACTIVE]: 'Active',
    [USERS_STATUS.DELETED]: 'Deleted',
};

export const ADMIN_ROLE = {
    SUPER_ADMIN: 'superAdmin',
    PLATFORM_ADMIN: 'admin',
}

export const ADMIN_ROLE_LABEL = {
    [ADMIN_ROLE.SUPER_ADMIN]: 'Super admin',
    [ADMIN_ROLE.PLATFORM_ADMIN]: 'Platform admin',
}

export const USERS_ROLE = {
    SPECTATOR: 'Spectator',
    MEMBER: 'Member',
    TEAM_MEMBER: 'TeamMember',
    LEAGUE_MEMBER: 'LeagueMember',
}

export const USERS_ROLE_LABEL = {
    [USERS_ROLE.SPECTATOR]: 'Spectator',
    [USERS_ROLE.MEMBER]: 'Member',
    [USERS_ROLE.TEAM_MEMBER]: 'Team Member',
    [USERS_ROLE.LEAGUE_MEMBER]: 'League Member',
}

export const ROLE_IN_TEAM = {
    REGULAR_MEMBER: 'regularMember',
    TEAM_OWNER: 'teamOwner',
    TEAM_ADMIN: 'teamAdmin',
}

export const ROLE_IN_TEAM_LABEL = {
    [ROLE_IN_TEAM.REGULAR_MEMBER]: 'Regular member',
    [ROLE_IN_TEAM.TEAM_OWNER]: 'Team Owner',
    [ROLE_IN_TEAM.TEAM_ADMIN]: 'Team Admin',
}

export const ROLE_IN_LEAGUE = {
    REGULAR_MEMBER: 'regularMember',
    LEAGUE_OWNER: 'leagueOwner',
    LEAGUE_ADMIN: 'leagueAdmin',
}

export const ROLE_IN_LEAGUE_LABEL = {
    [ROLE_IN_LEAGUE.REGULAR_MEMBER]: 'Regular member',
    [ROLE_IN_LEAGUE.LEAGUE_OWNER]: 'League Owner',
    [ROLE_IN_LEAGUE.LEAGUE_ADMIN]: 'League Admin',
}


