import {ReportedUsersList} from "./components/ReportedUserList";
import {ReportedPostsList} from "./components/ReportedPostList";
import {REPORT_LIST_LINKS} from "../../config";
import {ReportedCommentsList} from "./components/ReportedCommentList";

export const teamsListSchema = (userReportsParams, postReportsParams, commentReportsParams) => [
    {
        link: REPORT_LIST_LINKS.USER,
        label: 'Reported users',
        render: ReportedUsersList,
        props: {userReportsParams}
    },
    {
        link: REPORT_LIST_LINKS.POST,
        label: 'Reported posts',
        render: ReportedPostsList,
        props: {postReportsParams}
    },
    {
        link: REPORT_LIST_LINKS.COMMENT,
        label: 'Reported comments',
        render: ReportedCommentsList,
        props: {commentReportsParams}
    },
]