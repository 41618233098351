import React, {useState} from "react";
import {FILE_TYPE} from "../../../../../../base/constants/reports";
import {ImageItem} from "./components/ImageItem";
import {VideoItem} from "./components/VideoItem";
import {useModal} from "../../../../../../base/hooks/useModal";
import Carousel from "../../../../../../base/components/Carousel";
import {ModalGallery} from "../ModalGallery";

export const FilesList = ({filesList = []}) => {
    const {open, close, getModalProps} = useModal();
    const [activeIndex, setActiveIndex] = useState(0);

    const images = filesList.filter(fileInfo => fileInfo.type === FILE_TYPE.IMAGE);
    let counter = 0;

    const handleOpenGallery = (index) => {
        setActiveIndex(index)
        open()
    }

    return <div className="d-flex file-list">
        {
            filesList.map(fileInfo => fileInfo.type === FILE_TYPE.IMAGE
                ? <ImageItem image={fileInfo} onClick={handleOpenGallery} index={counter++}/>
                : <VideoItem video={fileInfo} />
            )
        }

        <ModalGallery {...getModalProps} close={close} >
            <Carousel items={images} containerClassName="carousel-gallery" itemClassName="carousel-gallery__item" defaultIndex={activeIndex}/>
        </ModalGallery>
    </div>
};
