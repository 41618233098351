import * as yup from 'yup';

yup.setLocale({
    mixed: {
        required: "Is required",
    },
    string: {
        email: "Email is invalid",
        trim: "Can’t contain spaces only",
        required: "Is required",
    },
});

export default yup;