import React from 'react';
import InfoItem from "../../../../../teams/components/MainInfo/components/InfoItem";
import {FilesList} from "../FilesList";
import {LinksList} from "../LinksList";

const CurrentPost = ({post}) => {
    return (
        <div className="user-info__info">
            <InfoItem title="Post info" value={<FilesList filesList={post?.files}/>}/>
            <InfoItem title="Post description" value={post?.description}/>
            <InfoItem title="Post links" value={<LinksList links={post?.links}/>}/>
        </div>
    );
};

export default CurrentPost;