import React from "react";
import NavTabs from "../../../../base/components/NavTabs/NavTabs";
import {teamsListSchema} from "./config";
import PageMeta from "../../../../base/components/MetaTags";
import {EVENTS_GROUP_LINKS} from "../../config";

const TeamsList = ({eventsParams, eventTemplateParams}) => {
    return (
        <section className="page-content">
            <PageMeta title={'Events'} />
            <div className="content-header">
                <h2 className="content-header__title">Events</h2>
            </div>
            <NavTabs
                rootLink={EVENTS_GROUP_LINKS.BASE}
                schema={teamsListSchema(eventsParams, eventTemplateParams)}
            />
        </section>

    );
};

export default TeamsList;