import React from "react";

const ToasterMessage = ({ icon, message }) => {
  return <div className="toaster-message">{icon}{message}</div>;
};

ToasterMessage.defaultProps = {
  icon: "",
};

export default ToasterMessage;
