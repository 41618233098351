import React from 'react'
import Button from "../../../../../base/components/Button/index";
import {handleWithoutPropagation} from "../../../../../base/helpers/handleWithoutPropagation";
import Popup from "../../../../../base/components/Popups/Popup";
import {ACTION_MEMBER_TYPE, popupSchema} from "../config";
import {ROLE_IN_LEAGUE, ROLE_IN_LEAGUE_LABEL} from "../../../../../base/constants/users";

export const LeaguesTableStructure = (deleteMember, assignLeagueOwner, redirectToUserDetails, isDelete, data) => (location, decorateText) => {
    const firstNameColumn = {
        dataField: "firstName",
        sort: false,
        headerFormatter: () => (<>Member first name</>),
        formatter: (cellContent, league) => {
            return (
                <section className="d-flex flex-row align-items-center">
                    <span className="tutee-list--list__name" {...decorateText(league.user.firstName)} />
                </section>
            )
        }
    };

    const lastNameColumn = {
        dataField: "lastName",
        sort: false,
        headerFormatter: () => (<>Member last name</>),
        formatter: (cellContent, league) => {
            return (
                <section className="d-flex flex-row align-items-center">
                    <span className="tutee-list--list__name"  {...decorateText(league.user.lastName)} />
                </section>
            )
        }
    };

    const roleColumn = {
        dataField: "role",
        sort: false,
        headerFormatter: () => (<>Member role</>),
        formatter: (cellContent) => {
            return <span>{ROLE_IN_LEAGUE_LABEL[cellContent]}</span>
        }
    };

    const actionColumn = {
        sort: false,
        headerClasses: 'action-cell',
        headerFormatter: () => (<>Manage user</>),
        formatter: (cellContent, league) => {
            const isLeagueOwner = league.role === ROLE_IN_LEAGUE.LEAGUE_OWNER
            if (data.length > 1) {
                return <div className="d-flex" onClick={handleWithoutPropagation()}>
                    <Popup
                        {...popupSchema[isLeagueOwner ? ACTION_MEMBER_TYPE.DELETE_OWNER : ACTION_MEMBER_TYPE.DELETE_MEMBER]}
                        action={() => {
                            if (isLeagueOwner) {
                                redirectToUserDetails(league.user.id)
                            } else {
                                deleteMember(league.id)
                            }
                        }}
                    >
                        <Button
                            color="danger"
                            className="py-1 me-3"
                            outline>
                            Delete
                        </Button>
                    </Popup>
                    {!isLeagueOwner && <Popup
                        {...popupSchema[ACTION_MEMBER_TYPE.ASSIGN_LEAGUE_OWNER]}
                        action={() => {
                            assignLeagueOwner(league.id)
                        }}
                    >
                        <Button
                            color="primary"
                            className="py-1">
                            Assign a league owner
                        </Button>
                    </Popup>}
                </div>
            }
        }
    };

    return isDelete
        ? [
            firstNameColumn,
            lastNameColumn,
            roleColumn,
        ]
        : [
            firstNameColumn,
            lastNameColumn,
            roleColumn,
            actionColumn,
        ]

}