import React from "react";
import PropTypes from "prop-types";
import {START_PROGRESS_VALUE, FINISH_PROGRESS_VALUE, STEP_PROGRESS} from "../constansts"

const Controls = ({
    togglePlay,
    playing,
    played,
    toggleMute,
    muted,
    onChangeProgress,
    onSeekStart,
    onSeekFinish,
    onStartRewind,
    onFinishRewind,
    onStartFastForward,
    onFinishFastForward
}) => {
    const handleChangeProgress = ({target: {value}}) => {
        onChangeProgress(parseFloat(value))
    }

    return <div className="custom-controls">

        <div className="custom-controls__menu">
            <button
                className="custom-controls__button rewind"
                onMouseDown={onStartRewind}
                onMouseUp={onFinishRewind}
            />

            <button
                className={`custom-controls__button ${playing ? 'pause' : 'play'}`}
                onClick={togglePlay}
            />

            <button
                className="custom-controls__button fast-forward"
                onMouseDown={onStartFastForward}
                onMouseUp={onFinishFastForward}
            />
        </div>

        <div className="custom-controls__progress">
            <input
                type="range"
                className="w-100"
                min={START_PROGRESS_VALUE}
                max={FINISH_PROGRESS_VALUE}
                step={STEP_PROGRESS}
                value={played}
                onChange={handleChangeProgress}
                onMouseDown={onSeekStart}
                onMouseUp={onSeekFinish}
            />
        </div>

        <div className="custom-controls__value">
            <button
                className={`custom-controls__button mute ${muted ? 'active' : ''}`}
                onClick={toggleMute}
            />
        </div>
    </div>
};

Controls.propTypes = {
    togglePlay: PropTypes.func,
    playing: PropTypes.bool,
    mute: PropTypes.bool,
    toggleMute: PropTypes.func,
    muted: PropTypes.bool,
    onChangeProgress: PropTypes.func,
    played: PropTypes.number,
    onSeekStart: PropTypes.func,
    onSeekFinish: PropTypes.func,
    onStartRewind: PropTypes.func,
    onFinishRewind: PropTypes.func,
    onStartFastForward: PropTypes.func,
    onFinishFastForward: PropTypes.func

};

export default Controls;
