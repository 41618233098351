import React, {useCallback} from "react";
import {Switch, Route} from "react-router-dom";

import { UsersList } from "./pages/UsersList/";
import UserInfo from "./pages/UserInfo";
import {USERS_GROUP_LINKS} from "./config";
import {SORT_KEY, SORT_TYPE} from "../../base/constants/query";
import {useStateParams} from "../../base/hooks/useStateParams";
import JoinedTeams from "./pages/UserInfo/components/JoinedTeams";
import JoinedLeagues from "./pages/UserInfo/components/JoinedLeagues";
const defaultParams = {[SORT_KEY]: 'registeredAt', [SORT_TYPE]: 'desc'}

const Users = () => {
  const [userListParams] = useStateParams(USERS_GROUP_LINKS.BASE, defaultParams);
  const UserTable = useCallback(() => <UsersList defaultParams={userListParams}/>, [userListParams])

    return (
        <Switch>
            <Route
                path={USERS_GROUP_LINKS.BASE}
                render={UserTable}
                exact
            />
            <Route
                path={USERS_GROUP_LINKS.LINK_USER_DETAILS}
                component={UserInfo}
            />
        </Switch>
  );
};

export default Users;
