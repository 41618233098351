import React, {useState, useMemo, useEffect} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'query-string';
import { parseQuery } from '../../../../base/hooks/useQueryString';
import PropTypes from 'prop-types';
import {USERS_ROLE_LABEL, USERS_STATUS_LABEL} from "../../../../base/constants/users";
import SearchBar from "../../../../base/components/Table/tableSearch";
import Filter from "../../../../base/components/Filters";
import {getFilterOptionFromQuery} from "../../../../base/helpers/getFiltersFromLocationSerch";

const FILTER_BY_STATUS = 'UserStatusFilter';
const FILTER_BY_ROLE = 'UserRoleFilter';

const DEFAULT_FILTER = {
    [FILTER_BY_STATUS]: undefined,
    [FILTER_BY_ROLE]: undefined
}

const filterSchema = [
    {
        name: FILTER_BY_STATUS,
        title: 'By Status',
        items: Object.keys(USERS_STATUS_LABEL).map(key => ({value: key, text: USERS_STATUS_LABEL[key]})),
    },
    {
        name: FILTER_BY_ROLE,
        title: 'By Role',
        items: Object.keys(USERS_ROLE_LABEL).map(key => ({value: key, text: USERS_ROLE_LABEL[key]})),
    }
]

export default function UsersTableHeader({ toolkitProps }) {

    const history = useHistory();
    const location = useLocation();

    const query = useMemo(() => parseQuery(location.search), [location]);

    const [filterOption, setFilterOption] = useState(query);

    const handleChangeFilters = (value) => {
        setFilterOption(value)
    }

    const handleSubmitFilters = () => {
        history.push({
            search: qs.stringify({
                ...query,
                offset: 0,
                UserStatusFilter: filterOption[FILTER_BY_STATUS],
                UserRoleFilter: filterOption[FILTER_BY_ROLE],
            })
        })
    }
    
    const resetFilters = () => {
        setFilterOption(DEFAULT_FILTER)
        history.push({
            search: qs.stringify({
                ...query,
                ...DEFAULT_FILTER
            })
        })
    }

    useEffect(() => {
        setFilterOption(getFilterOptionFromQuery(Object.keys(DEFAULT_FILTER)))
    }, [query])

    return (
        <section className="d-flex flex-row py-3 m-0 justify-content-between users-table--filters">
            <section className="d-flex align-items-center justify-content-between w-100 flex-row">
                <div className="search-box me-4 d-inline-block">
                    <div className="position-relative">
                        <SearchBar
                            {...toolkitProps.searchProps}
                        />
                        <i className="bx bx-search-alt search-icon" />
                    </div>
                </div>
                <Filter
                    defaultValue={DEFAULT_FILTER}
                    onChange={handleChangeFilters}
                    onReset={resetFilters}
                    onSubmit={handleSubmitFilters}
                    filterState={filterOption}
                    schema={filterSchema}
                />
            </section>
        </section>
    );
}

UsersTableHeader.propTypes = {
    toolkitProps: PropTypes.object,
}