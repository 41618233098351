export const REPORTS_STATUS = {
    VIEWED: 'Viewed',
    NEW_REPORT: 'New'
}

export const REPORTS_STATUS_LABEL = {
    [REPORTS_STATUS.VIEWED]: 'Viewed',
    [REPORTS_STATUS.NEW_REPORT]: 'New report',
}

export const FILE_TYPE = {
    IMAGE: 'image',
    VIDEO: 'video'
}

export const POST_TYPE = {
    TEAM: 'team',
    PERSONAL: 'personal'
}

export const POST_TYPE_LABEL = {
    [POST_TYPE.TEAM]: 'Team',
    [POST_TYPE.PERSONAL]: 'Personal',
}

export const TEAM_POST_TYPE = {
    PUBLIC: 'public',
    PRIVATE: 'private'
}

export const ITEM_POST_TYPE_LABEL = {
    [TEAM_POST_TYPE.PUBLIC]: 'Public',
    [TEAM_POST_TYPE.PRIVATE]: 'Private'
}