import React, {useEffect} from "react";
import Table from "../../../../../../base/components/Table";
import UsersService from "../../../../../../services/UsersService";
import { useService } from "../../../../../../base/hooks/useService";
import { useLocationQuery } from "../../../../../../base/hooks/useQueryString";
import { useTable } from '../../../../../../base/hooks/useTable';
import tableStructure from "./tableStructureTeams";
import { Container } from "reactstrap";
import { SEARCH_QUERY, SORT_KEY, SORT_TYPE } from "../../../../../../base/constants/query";
import {TEAMS_GROUP_LINKS} from "../../../../../teams/config";

const USERS_LIMIT = 15;

const JoinedTeams = ({id, status}) => {
    /**
     * @type {UsersService}
     */
    const usersService = useService(UsersService);
    const { params } = useLocationQuery();

    const getUserTeams = ((id) => {
        return (params) => {
            return usersService.getUsersTeams.call(usersService, id, params)
        }
    })(id)

    const [loading, pagination, data, handleTableChange, downloadNewData] = useTable({
        searchQueryParam: SEARCH_QUERY,
        sortTypeQueryParam: SORT_TYPE,
        sortByQueryParam: SORT_KEY,
        getNewDataService: getUserTeams,
        limit: USERS_LIMIT,
        offset: params.offset || 0
    })

    const textPlaceholder = !params[SEARCH_QUERY] ? 'There are no Teams' : 'No search results found';

    useEffect(() => {
        downloadNewData();
    }, [status])

    return (
        <Container fluid className="w-100 p-0">
            <Table
                data={data}
                dataStructure={tableStructure}
                loading={loading}
                limit={USERS_LIMIT}
                offset={params.offset || 0}
                totalCount={pagination.totalCount}
                handleTableChange={handleTableChange}
                searchField={SEARCH_QUERY}
                textPlaceholder={textPlaceholder}
                isRowClick={true}
                rowClickPath={TEAMS_GROUP_LINKS.ACCEPTED_TEAMS}
                withLocation={false}
                locationIndex={"teamId"}
            />
        </Container>
    );
};

export default JoinedTeams;