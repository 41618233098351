import React from 'react'
import { parseQuery } from "../../../../../../base/hooks/useQueryString";
import Icon from '../../../../../../base/components/Icon';
import {SORT_KEY, SORT_TYPE} from "../../../../../../base/constants/query";
import {ROLE_IN_TEAM_LABEL} from "../../../../../../base/constants/users";

const DESC_SORT = 'desc';
const DESC_SORT_ICON = 'sortDesc';
const ASC_SORT_ICON = 'sortAsc';
const SORT_DEFAULT_ICON = 'sortDefault';

export default function TeamsTableStructure(location, decorateText) {
    const infoQuery = parseQuery(location.search);
    function getSortIcon(cellName) {
        if (infoQuery[SORT_KEY] === cellName) {
            return infoQuery[SORT_TYPE] === DESC_SORT ? ASC_SORT_ICON : DESC_SORT_ICON;
        }
        return SORT_DEFAULT_ICON;
    }

    return [
        {
            dataField: "teamName",
            sort: true,
            headerFormatter: () => (<>Team name<Icon icon={getSortIcon('teamName')} /></>),
            formatter: (cellContent, teamInfo) => {
                return (
                    <section className="d-flex flex-row align-items-center">
                        <span className="tutee-list--list__name teams-table__name" {...decorateText(teamInfo.teamName)} />
                    </section>
                )
            }
        },
        {
            dataField: "teamType",
            sort: true,
            headerFormatter: () => (<>Team type<Icon icon={getSortIcon('teamType')} /></>),
            formatter: (cellContent, teamInfo) => {
                return (
                    <section className="d-flex flex-row align-items-center">
                        <span className="tutee-list--list__name"  {...decorateText(teamInfo.teamType)} />
                    </section>
                )
            }
        },
        {
            dataField: "roleInTeam",
            sort: true,
            headerFormatter: () => (<>Role in the Team <Icon icon={getSortIcon('roleInTeam')} /></>),
            formatter: (cellContent, teamInfo) => {
                return <span {...decorateText(ROLE_IN_TEAM_LABEL[teamInfo.roleInTeam])} />
            }
        }
    ]
}