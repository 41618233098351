import {parseQuery} from "../hooks/useQueryString";

export const getFilterOptionFromQuery = (filterNames = []) => {
    const filterOptions = {}
    const query = parseQuery(location.search);
    filterNames.forEach((filterName) => {
        filterOptions[filterName] = query[filterName]
    })

    return {...filterOptions};
}