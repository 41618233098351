import yup from "../../../../validation/yup";
import {
    EVENT_NAME,
    DESCRIPTION,
    END_DATE,
    START_DATE,
    IMAGE,
    LINK,
    LOCATION,
    TYPE,
    SAVE_AS_TEMPLATE,
    TIME_ZONE,
    FORMAT,
    PLATFORM
} from "../../../../validation/event";

export const validationSchema = yup.object().shape({
    name: EVENT_NAME.required(),
    description: DESCRIPTION.required(),
    endDate: END_DATE.required(),
    startDate: START_DATE.required(),
    image: IMAGE,
    link: LINK,
    location: LOCATION,
    type: TYPE.required(),
    saveAsTemplete: SAVE_AS_TEMPLATE,
    timezone: TIME_ZONE.required(),
    format: FORMAT.required(),
    platform: PLATFORM.when('format', {
        is: 'Virtual',
        then: yup.string().required().nullable()
    })
    .when('format', {
        is: 'Live',
        then: yup.string().nullable()
    })
});

export const initialValues = {
    name: '',
    description: '',
    endDate: '',
    startDate: '',
    image: null,
    link: '',
    location: null,
    type: undefined,
    saveAsTemplete: false,
    saveAsDraft: false,
    timezone: undefined,
    format: undefined,
    platform: undefined
};
