import React from "react";
import PropTypes from "prop-types";
import {schemaTabs} from "./types";
import {NavLink, Switch, Route, Redirect, useRouteMatch, useLocation} from "react-router-dom";
import {Nav, NavItem, TabContent, Card} from "reactstrap";

const NavTabs = ({rootLink = '', rootLocation = '', className, schema = [], children}) => {
    const locationMatch = useRouteMatch();
    const location = useLocation();

    return <>
        <Card className={`nav-tabs-container ${className}`}>
            <Nav className="nav-tabs border-0">
                {schema.map((item) => {
                    const itemLink = (rootLocation || rootLink).concat(item.link)
                    const isActive = location.pathname === itemLink;

                    return (
                        <NavItem key={item.link} className="nav-tabs__nav-item">
                            <NavLink
                                to={itemLink}
                                className={`nav-tabs__link ${isActive ? "nav-tabs__link_active" : ""}`}>
                                {item.label}
                            </NavLink>
                        </NavItem>
                    )
                })}
            </Nav>
            {children}
        </Card>
        <TabContent>
            <Switch>
                {
                    schema.map((item) => {
                        return (
                            <Route
                                key={item.path}
                                path={rootLink.concat(item.link)}
                                render={() => <item.render {...item.props} />}
                                exact
                            />)
                    })
                }
                <Redirect to={locationMatch.url.concat(schema[0].link)}/>
            </Switch>
        </TabContent>
    </>
};

NavTabs.defaultProps = {
    className: "",
};

NavTabs.propTypes = {
    className: PropTypes.string.isRequired,
    schema: schemaTabs,
    rootLink: PropTypes.string.isRequired,
    rootLocation: PropTypes.string.isRequired,
};

export default NavTabs;
