import yup from "../../../../validation/yup";
import {USER_PASSWORD} from "../../../../validation/user";

export const validationSchema = yup.object().shape({
  password: USER_PASSWORD.required(),
  confirmPassword: USER_PASSWORD.required()
    .test("passwords-match", "Passwords don’t match", function (value) {
      return this.parent.password === value;
    }),
});

export const initialValues = {
  password: "",
  confirmPassword: "",
};
