import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Row } from "reactstrap";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { useHistory, useLocation } from "react-router-dom";
import Placeholder from "./placeholder";
import overlayFactory from "react-bootstrap-table2-overlay";
import {useLocationQuery} from "../../hooks/useQueryString";
import tableStructure from "./tableStructureEXAMPLE";
import {useHighlight} from "../../hooks/useHighlight";
import {decodeQuery} from "../../helpers/decodeQuery";

const spinnerStyles = {
  overlay: (base) => ({ ...base, background: "rgba(255, 255, 255, 1)" }),
  spinner: (base) => ({
    ...base,
    width: "75px",
    "& svg circle": {
      stroke: "#556EE6",
    },
  }),
};

function calculateCurrentPage(offset, limit) {
  return parseInt((parseInt(offset) + parseInt(limit)) / parseInt(limit));
}

export default function Table({
  dataStructure = tableStructure,
  data = [],
  HeaderComponent,
  handleTableChange = () => { },
  totalCount = 0,
  limit = 15,
  offset = 15,
  isRowClick = true,
  loading = false,
  rowClickPath,
  searchField = "",
  withLocation = true,
  textPlaceholder = "NO DATA",
  locationIndex = 'id',
  bodyPaddings = true,
  subIndex,
  rowClasses
}) {
  const location = useLocation();
  const history = useHistory();
  const isShowPagination = limit < totalCount;

  const { params: query } = useLocationQuery();

  const decodeSearch = decodeQuery(query[searchField]);

  const search = useMemo(() => (
      {
        defaultSearch: decodeSearch,
        searchFormatted: true
      }
  ), [query, location])

  const pagination = useMemo(
    () =>
      paginationFactory({
        alwaysShowAllBtns: true,
        sizePerPage: limit,
        totalSize: totalCount,
        custom: true,
        page: calculateCurrentPage(offset, limit),
        prePageText: <div className="px-1 w-auto">Previous</div>,
        nextPageText: <div className="px-1 w-auto">Next</div>,
        withFirstAndLast: false
      }),
    [limit, totalCount, offset]
  );

  const { decorateText } = useHighlight(decodeSearch);

  const rowEvents = {
    onClick: (event, row) => {
      const path =
        (withLocation ? location.pathname : "") +
        (rowClickPath
            ? rowClickPath + `/${subIndex ? row[locationIndex][subIndex] : row[locationIndex]}`
            : `/${subIndex ? row[locationIndex][subIndex] : row[locationIndex]}`);
      history.push({
        pathname: path,
        state: {
          from: location.pathname
        }
      });
    },
  };
  return (
    <Row className="m-0 p-0">
      <Col lg="12" className="p-0">
        <Card className="p-0 m-0">
          <CardBody className={`py-0 ${!bodyPaddings && 'px-0'}`}>
            <PaginationProvider pagination={pagination}>
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="id"
                  data={data || []}
                  columns={dataStructure(location, decorateText)}
                  bootstrap4
                  search={search}
                >
                  {(toolkitProps) => {
                    return (
                        <React.Fragment>
                          {HeaderComponent && <HeaderComponent toolkitProps={toolkitProps}/>}
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                    responsive
                                    remote
                                    rowEvents={isRowClick && rowEvents}
                                    bordered={false}
                                    striped={false}
                                    loading={loading}
                                    overlay={overlayFactory({
                                      spinner: true,
                                      styles: spinnerStyles,
                                    })}
                                    noDataIndication={() => <Placeholder text={textPlaceholder} />}
                                    classes={
                                      "table align-middle table-nowrap table-hover"
                                    }
                                    rowClasses={rowClasses}
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                />
                              </div>
                            </Col>
                          </Row>
                          {isShowPagination && <Row className="align-items-md-center mt-4">
                            <Col className="pagination pagination-rounded justify-content-end mb-0 inner-custom-pagination">
                              <PaginationListStandalone
                                  {...paginationProps}
                              />
                            </Col>
                          </Row>}
                        </React.Fragment>
                    )
                  }}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

Table.propTypes = {
  data: PropTypes.array,
  dataStructure: PropTypes.any,
  HeaderComponent: PropTypes.any,
  handleTableChange: PropTypes.func,
  totalCount: PropTypes.number,
  limit: PropTypes.number,
  offset: PropTypes.number,
  isRowClick: PropTypes.bool,
  loading: PropTypes.bool,
  rowClickPath: PropTypes.string,
  searchField: PropTypes.string,
  withLocation: PropTypes.bool,
  textPlaceholder: PropTypes.string,
  locationIndex: PropTypes.string,
};
