import React from "react";
import {ModalGallery} from "../../ModalGallery";
import {useModal} from "../../../../../../../base/hooks/useModal";
import Player from "../../../../../../../base/components/Player/index";

export const VideoItem = ({video}) => {
    const {open, close, getModalProps} = useModal();

    return <>
        <div className="file-list__item video" onClick={open}/>
        <ModalGallery {...getModalProps} close={close}>
            <Player
                url={video.originalPath}
            />
        </ModalGallery>
    </>
};
