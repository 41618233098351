import yup from "../../../../../../validation/yup";
import {USER_PASSWORD} from "../../../../../../validation/user";
import {USER_EMAIL} from "../../../../../../validation/user";

export const validationSchema = yup.object().shape({
    currentEmail: USER_EMAIL.required(),
    currentPassword: USER_PASSWORD.required(),
    newEmail: USER_EMAIL.required(),
});

export const initialValues = {
    currentEmail: "",
    currentPassword: "",
    newEmail: ""
};
