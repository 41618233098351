import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import CreatePassword from "./pages/CreatePassword/index";
import ForgetPassword from "./pages/ForgetPassword";
import ResetPassword from "./pages/ResetPassword";
import ResultSend from "./pages/ResultSend";

import {AUTH_GROUP_LINKS} from "./config";

export default function AuthRoutes() {
    return (
    <Switch>

      <Route
        path={AUTH_GROUP_LINKS.LINK_LOGIN}
        component={Login}
        exact
      />

      <Route
        path={AUTH_GROUP_LINKS.LINK_RESET_PASSWORD}
        component={ResetPassword}
        exact
      />

      <Route
        path={AUTH_GROUP_LINKS.LINK_FORGOT_PASSWORD}
        component={ForgetPassword}
        exact
      />

      <Route
        path={AUTH_GROUP_LINKS.LINK_LOG_OUT}
        component={Logout}
        exact
      />

      <Route
          path={AUTH_GROUP_LINKS.CREATE_PASSWORD}
          component={CreatePassword}
          exact
      />

      <Route
          path={AUTH_GROUP_LINKS.RESULT_SEND}
          component={ResultSend}
          exact
      />

      <Redirect to={AUTH_GROUP_LINKS.LINK_LOGIN} />
    </Switch>
  );
}
