import Http from "./Http";
import {getErrors} from "../base/helpers/getErrors";

class AuthService extends Http {
    static $displayName = "AuthService";

    async login(values) {
        const {data} = await this.post("/admin-sessions", values);
        if (data.user.isBlocked) {
            throw getErrors('Account is blocked')
        }
        return data
    }

    async createPassword(values) {
        return await this.put("/admin-verifications/create-password", values);
    }

    async logout() {
        return await this.delete("/admin-sessions");
    }

    async sendRestorePasswordEmail({email}) {
        return await this.post("/admin-verifications/password", {email});
    }

    async resetPassword(values) {
        return await this.put("/admin-verifications/password", values);
    }

    async checkLinkRelevance(values) {
        const {data} = await this.post("/admin-verifications/token", values);
        if (!data.isValid) {
            throw getErrors('Token is not valid')
        }
    }

    async checkValidToken(values) {
        return await this.post("/verifications/token/email", values);
    }
}

export default AuthService;
