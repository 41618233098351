import React from 'react'
import { parseQuery } from "../../../../../../base/hooks/useQueryString";
import Icon from '../../../../../../base/components/Icon';
import {SORT_KEY, SORT_TYPE} from "../../../../../../base/constants/query";
import {formatDatetime} from "../../../../../../base/helpers/formatDatetime";
import {REPORTS_STATUS_LABEL} from "../../../../../../base/constants/reports";
import TableDecorateText from "../../../../../../base/components/Table/DecorateText";

const DESC_SORT = 'desc';
const DESC_SORT_ICON = 'sortDesc';
const ASC_SORT_ICON = 'sortAsc';
const SORT_DEFAULT_ICON = 'sortDefault';

const DATETIME_FORMAT_PATTERN = "DD MMM yyyy hh:mm A"

export default function TableStructure(location, decorateText) {
    const infoQuery = parseQuery(location.search);
    function getSortIcon(cellName) {
        if (infoQuery[SORT_KEY] === cellName) {
            return infoQuery[SORT_TYPE] === DESC_SORT ? ASC_SORT_ICON : DESC_SORT_ICON;
        }
        return SORT_DEFAULT_ICON;
    }

    return [
        {
            dataField: "creatorFirstName",
            sort: false,
            headerFormatter: () => 'First name of post creator',
            formatter: (cellContent) => {
                return <TableDecorateText {...decorateText(cellContent)}/>
            }
        },
        {
            dataField: "creatorLastName",
            sort: false,
            headerFormatter: () => 'Last name of post creator',
            formatter: (cellContent) => {
                return <TableDecorateText {...decorateText(cellContent)}/>
            }
        },
        {
            dataField: "totalReportsCount",
            sort: true,
            headerFormatter: () => <>Total number of reports <Icon icon={getSortIcon('totalReportsCount')} /></>,
        },
        {
            headerFormatter: () => <>Date and time of last report <Icon icon={getSortIcon('lastReportDateTime')} /></>,
            dataField: "lastReportDateTime",
            sort: true,
            formatter: (cellContent) => formatDatetime(cellContent, DATETIME_FORMAT_PATTERN)
        },
        {
            dataField: "status",
            headerFormatter: () => <>Status <Icon icon={getSortIcon('status')} /></>,
            sort: true,
            formatter: (cellContent, report) => <span className={report.status}>{REPORTS_STATUS_LABEL[report.status]}</span>
        }
    ]
}