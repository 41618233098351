import { useMemo, useCallback } from "react";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";

export const useGoogleMap = ({ google }) => {
  const geo = useMemo(() => new google.maps.Geocoder(), [google]);

  const setAddressByLocation = useCallback(
    (location, setAddress) =>
      geo.geocode({ location }, (results, status) => {
        if (status !== "OK" || !results[0]) return;
        setAddress({
          name: results[0].formatted_address,
          value: location,
          placeId: results[0].place_id,
        });
      }),
    [geo]
  );

  const setLocationByAddress = useCallback(
    async (location, setLocation, setCenter) => {
      await geocodeByAddress(location)
        .then(async (results) => {
          const latLng = await getLatLng(results[0])
          setLocation({
              name: results[0].formatted_address,
              placeId: results[0].place_id,
              value: latLng
          });
          setCenter(latLng);
        })
          // eslint-disable-next-line no-console
        .catch(() => {
            throw new Error('Location not found')
        });
    },
    []
  );

  return { setAddressByLocation, setLocationByAddress };
};
